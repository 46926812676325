/* _______________________________________________
  |                                               |
  |             Group Request Form                |
  |   Group will send request quote to site       |
  |                                               |
  |_______________________________________________|
                                                   */
// Initial Lib
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import {reactLocalStorage} from 'reactjs-localstorage';

// Include Component
import InnerHeader from './layout/InnerHeader';
import InnerFooter from './layout/InnerFooter';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Tab, Tabs, Button, InputGroup, FormControl, Form, Modal, Popover, Tooltip} from "react-bootstrap";
import WOW from "wowjs";

// Required component
import {PageLoader,SmallPageLoader} from './loaders/PageLoader'; // Page Loader
import {Link} from "react-router-dom";

// Service lib
import {postApiData,getApiData} from '../services/Api';

// default Base Urls
import config from 'react-global-configuration';

const history = createBrowserHistory();


class GroupBilldesk extends Component {
  
  constructor(props){

    super(props);

    this.state = {
      verifyGroupId: this.props.match.params.id,
      validated: false,
      isPayment:false,
      isPaymentError:false,
      payment_for: "",
      planAmount: "",
      subscription_id: "",
      group_id: "",
      number_of_students:'',
      // local Storage Var
      isLogin: localStorage.getItem('userId'),
      isPaid: localStorage.getItem('paid'),
      redirectUri: localStorage.getItem('redirectUri'),
      // redirect Auth
      authRedirect : this.props.match.path,
      submitText : 'Pay',

    }

    // Bind functions
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 
  /**
   * On Click validate login.
   *
   * @return status
  */
  handleSubmit(event) {

    event.preventDefault();

    // Validation check
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    document.getElementById('submit').disabled = true;
    this.setState({ submitText: 'Processing...' });

    // Set Var
    var self = this;
    this.setState({ validated: true });
    const data = new FormData(event.target); // Form Data
    data.append('subscription_table_id',this.state.subscription_id);
    data.append('group_id',this.state.group_id);
    data.append('number_of_students',this.state.number_of_students);
    
    // Set post data
    let where =[{url:"/addmoney/group-activation-payment"}]; // Service param

    // Post parameters on service URL...
    postApiData(where,data,true).then(res => {
      if(res.data.status == '200'){
        //self.setState({ redirect: true }); // Set redirect status
        self.setState({ isPayment : true });
        setTimeout(
        function(){
              // Redirect URI
              if(self.state.redirectUri){
                reactLocalStorage.set('redirectUri', "");
                window.location.href = self.state.redirectUri;
              }else{
                window.location.href = "/scholarships";
              }
        },
        5000);
      }else{
        if(res.data.result == "Plan already exists."){
          self.setState({ isPaymentError : "You are already subscribed."});
        }else{
          self.setState({ isPaymentError : res.data.result});
        }
      }

      document.getElementById('submit').disabled = false;
      this.setState({ submitText: 'Pay' });

    })
    .catch(function (error) {
      console.log('dddd->>',error);
      //document.getElementById('submit').disabled = false;
      //this.setState({ submitText: 'Pay' });
    });
  }

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount(){
    
    new WOW.WOW().init();
    window.scrollTo(0, 0);

    // If opened as Verfy Link
    if(this.state.verifyGroupId){
      let self = this;
      // Set post data
      let where =[{url:"/group-payment-request/"+this.state.verifyGroupId}]; // Get payment Request
      // Post parameters on service URL...
      getApiData(where).then(response => {

        if(response.data.status == 201){
          self.setState({ payment_for: " for "+response.data.result.group_name });
          self.setState({ planAmount: response.data.result.subscription_amount });
          self.setState({ group_id: response.data.result.group_id });
          self.setState({ subscription_id: response.data.result.subscription_id });
          self.setState({number_of_students: response.data.result.number_of_students});
        }else{
          self.setState({ error: response.data.message });
        }

      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }


  async openNav() {
  document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
  document.getElementById("myNav").style.width = "0%";
  }

   /**
   * Display login UI.
   *
   * @return Response
  */
 render() {

    
  /* Auth redirection
  if(!this.state.isLogin){
    return(<Redirect to='/login'  />); 
  }*/

  const handleHide = () => this.setState({ show: false });
  const handleShow = () => this.setState({ show: true });
  const { validated } = this.state;
  return (
 
    <div style={{background:'url(/login-bg.png) no-repeat', backgroundSize:'cover', paddingBottom:'1px'}}>
      <InnerHeader/>
        <div  style={{minHeight:'500px'}}>
            <div className="login-section" >
            {(!this.state.isPayment)?
             <Tabs>
               <span></span>
                <Tab eventKey="login" title="Subscribe">
                  <Form className="form-horizontal" id="payment-form" method="post" validated={validated} onSubmit={(event) => this.handleSubmit(event)}>
                    <Form.Group controlId="card" className="card">
                      <Form.Label>Card Number<sup>*</sup></Form.Label>       
                      <Form.Control type="text" placeholder="Card number" id="card_no" name="card_no" autocomplete="off" maxlength="16" required onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="cvv" className="cvv">
                      <Form.Label>CVV<sup>*</sup></Form.Label>       
                      <Form.Control type="text" placeholder='ex. 311' id="cvvNumber" name="cvvNumber" autocomplete="off" maxlength="4" required onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="common" className="expiration">
                      <Form.Label>Expiration<sup>*</sup></Form.Label> 
                      <div className="row clearfix">
                      <div className="col-sm-6">      
                        <Form.Control type="text" placeholder='MM' id="ccExpiryMonth" name="ccExpiryMonth" autocomplete="off" maxlength="2" required onChange={this.handleChange} />
                      </div>
                      <div className="col-sm-6"> 
                        <Form.Control type="text" placeholder='YYYY' id="ccExpiryYear" name="ccExpiryYear" autocomplete="off" maxlength="4" required onChange={this.handleChange} />
                      </div>
                      </div>
                    </Form.Group>
                    <Form.Group controlId="cvv" className="cvv">
                      <Form.Label>Amount ($)</Form.Label>       
                      <Form.Control type="text" id="amount" name="amount" autocomplete="off" value={this.state.planAmount} readonly required onChange={this.handleChange} />
                    </Form.Group>
                    
                    <div className='form-row'>
                      <div className='col-md-12 form-group'>
                      <button className='form-control btn btn-primary submit-button' type='submit' id="submit">{this.state.submitText} »</button>{this.state.loginloaderDiv?(<div style={{textAlign:"center",'float':'left'}} ref="loaderdiv">
                        <SmallPageLoader/>  
                        </div>):""}
                      </div>
                    </div>
                    <div className='form-row'>
                      {this.state.isPaymentError?(<div className="alert alert-danger"><strong>{this.state.isPaymentError}</strong></div>):(null)}
                      
                    </div>
                    <div >
                      {this.state.error?(<div className="alert alert-warning"><strong>{this.state.error}</strong></div>):(null)}
                      
                    </div>
                  </Form>
                </Tab>
              </Tabs>:<div style={{'margin': '0 auto','fontVariant': 'revert','color': 'green','margin':'44px 10px','fontSize':'15px'}}>Successful, You will be redirect in 5 seconds or <Link to='/scholarships'>
              Click Here</Link> to go to dashboard.</div>}
            </div>
        </div>
      <InnerFooter/>
    </div>

  );
}
}

const style = {
 margin: 15,
};

export default GroupBilldesk;