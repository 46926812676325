/*
  _____________________
 |                     |
 |     Scholership     |
 |_____________________|
                        */

// Basic Lib
import React, { Component } from 'react';
import { Redirect, Link} from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Required component
import {PageLoader,SmallPageLoader} from './loaders/PageLoader'; // Page Loader
import ShowMoreText from 'react-show-more-text'; // Show more text
import Autocomplete from 'react-autocomplete'; // Auto-complete

// Include Component
import ScholarshipHeader from './layout/scholarshipHeader';
import ScholarshipPromo from './layout/scholarshipPromo';
import InnerFooter from './layout/InnerFooter';

// Slider Lib
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import '../internship.css';
// Tab Lib
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Card} from "react-bootstrap";
import WOW from "wowjs";

// Date Range picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

// Services
import {postApiData,getApiData} from '../services/Api';

const history = createBrowserHistory();

class Interships extends Component {
    
  constructor(props){

    super(props);
    this.state={
      // Listing Var
      scholership: [],
      award:"",
      // Design Var
      horizontal: 10,
      // Redirect
      redirect: false,
      // Auto complete
      loading: false,
      value:"",
      scholarshipNames:[],
      // Date Range
      dateRange:"",
      startRange: new Date(),
      endRange: new Date(),
      //Loader
      loaderDiv : true,
      // Local Storage
      isPaid: localStorage.getItem('paid'),
      loggedin:reactLocalStorage.get('loggedin'),
      user_id: localStorage.getItem('userId'),
      group_id: (localStorage.getItem('group_id') && localStorage.getItem('group_id') !== 'null')?localStorage.getItem('group_id'):'',
      group_name : '',
      tabIndex :0,
	  msg : '',
	  search_keyword:'',
	  affiliated : 0,
	  checked : false,
	  groupWise:false,
	  state_keyword:'',
	  stateList : [],
	  state_id:'',
	  major_keyword:'',
	  majorList : [],
	  major_id:'',
	  for_imagin:false,
    }

    // Bind functions
    this.requestTimer = null;
    this.startApply = this.startApply.bind(this);
    this.saveScholarship = this.saveScholarship.bind(this);
    this.handleDateRangeEvent = this.handleDateRangeEvent.bind(this);
    this.handleDateRangeBlank = this.handleDateRangeBlank.bind(this);
    this.redirectPayment = this.redirectPayment.bind(this);
	this.dislikeScholarship = this.dislikeScholarship.bind(this);    
  }

  /**
   * Slider Change Event.
   *
   * @Set values
  */
  handleChangeHorizontal = value => {

      this.setState({ horizontal: value });
      this.setState({award:value});
     
  };
handleChangeComplete = () => {
	      const {getScholerships = () => {}} = this.props;
      const {value, delay} = this.state;
	 
         this.getScholerships(this.state.horizontal);
   
  };
  /**
   * Get Scholership listing.
   *
   * @return error/success
  */
  getScholerships(award="")
  {
    // Set var
    var self = this;
    this.setState({loaderDiv: true});
	
    // Set post data
    let where =[{url:"/get-internship-scholership"}]; // Service param
	var form = new FormData();
	form.append('search_keyword', this.state.search_keyword);
  form.append('award',award?award:this.state.award);
  form.append('deadline',this.state.dateRange);	
  form.append('internship',1);	
	form.append('paid', true);
	if(this.state.checked){
		form.append('groupWise', true);
		form.append('group_id', this.state.group_id);
		form.append('for_imagin', this.state.for_imagin);
	}
	 form.append('state_id',this.state.state_id);	
	 form.append('major_id',this.state.major_id);	

	
    // Post parameters on service URL
    postApiData(where,form).then(response => {
      if(response.status==200 && response.data.success==true)
	  {
        this.setState({ scholership: response.data.scholarships });
      }
	  else
	  {
        this.setState({ scholership: 0 });
      }
      this.setState({loaderDiv: false});
    })
    .catch(function (error) {
      console.log("In catch : "+error);
    });
  }

  /**
   * Start Apply for scholarship.
   *
   * Log and redirect
  */
  startApply(link,id){    
    // Set var
    var self = this;
    let divId = "loaderdiv_"+id;
    var applyElm = document.getElementById(divId);
    applyElm.innerHTML = "Applying...";

    // Set post data
    let where =[{url:"/save-apply-hit"}]; // Service param
    var form = new FormData();
    form.append('scholarship_id',id);
    form.append('hit_url',link);
    form.append('hit_from','scholarships');

    // Post parameters on service URL
    postApiData(where,form).then(response => {
		if(response.status==200 && response.data.success==true)
		{
			applyElm.innerHTML = "Apply";
			window.open(link, '_new');
			self.setState({msg : response.data.msg});
			setTimeout(function() { self.setState({msg: '' }); }.bind(this),	3000);
		}
		else
		{
			self.setState({msg : response.data.msg});
		}	
    })
    .catch(function (error) {
      applyElm.innerHTML = "Apply";
      console.log("In catch : "+error);
    });
  }

  /**
   * Save scholarship for User.
   *
  */
  saveScholarship(id){
    // Set var
    var self = this;
    let divId = "ssloaderdiv_"+id;
    let contantId = "save_"+id;
    var saveElm = document.getElementById(divId);
    saveElm.innerHTML = "Processing...";
    // Set post data
    let where =[{url:"/save-scholarship"}]; // Service param
    var form = new FormData();
    form.append('scholarship_id', id);

    // Post parameters on service URL
    postApiData(where,form).then(response => {	
	
		if(response.status==200 && response.data.success==true)
		{
			saveElm.innerHTML = "Saved";
			saveElm.disabled = true;
			self.setState({msg: response.data.msg});

			// Hide success/error message without refresh after 3 seconds 
			setTimeout(function() { self.setState({msg: '' }); }.bind(this),	3000);
			self.getScholerships();
		}
		else
		{
			self.setState({msg : response.data.msg});
		}			
    })
    .catch(function (error) {
      saveElm.innerHTML = "Save";
      console.log("In catch : "+error);
    });
  }

  /**
   * Dislike scholarship from saved list.
   *
  */
  dislikeScholarship(id)
  {
    // Set var
    var self = this;
    let divId = "rmloaderdiv_"+id;
    var dislikeElm = document.getElementById(divId);
    dislikeElm.innerHTML = "Processing...";
    // Set post data
    let where =[{url:"/dislike-scholarship"}]; // Service param
    var form = new FormData();
    form.append('scholarship_id', id);

    // Post parameters on service URL
    postApiData(where,form).then(response => {	
	
		if(response.status==200 && response.data.success==true)
		{
			dislikeElm.style.display = "none";
			self.setState({msg: response.data.msg});

			// Hide success/error message without refresh after 3 seconds 
			setTimeout(function() { self.setState({msg: '' }); }.bind(this), 3000);
			self.getScholerships();
		}
		else
		{
			self.setState({msg : response.data.msg});
		}			
    })
    .catch(function (error) {
	  dislikeElm.style.display = "none";
      console.log("In catch : "+error);
    });
  }
  
  /**
   * Rediret Payment.
   *
  */
  redirectPayment(){
	localStorage.removeItem('plan');
    this.setState({ redirect: true }); // Set redirect status
  }

  /**
   * On Load action.
   *
   * Set Default
  */
  componentDidMount(){
    this.getScholerships();
    //this.checkAffiliated(this.state.group_id);
    new WOW.WOW().init();
    window.scrollTo(0, 0); // Page Set to form start
  }
  
  async openNav() {
      document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
      document.getElementById("myNav").style.width = "0%";
  }

  /* Show More/Less Event */
  executeOnClick(isExpanded) {
    console.log(isExpanded);
  }

  /* Date Range Event */
  handleDateRangeEvent(event, picker) {
      this.setState({ dateRange: moment(picker.startDate).format("MM/DD/YY")+" - "+moment(picker.endDate).format("MM/DD/YY") });
      this.getScholerships();
  }

  /* Date Range Event */
  handleDateRangeBlank(event, picker){
    this.setState({ dateRange:""});
    this.getScholerships();
  }

  /* handle for scholarship_name */
//   handleSearch  = e => {
//     const {value} = e.target
// 	this.setState({
// 		search_keyword:e.target.value
// 	},() => {
//     if(value.length > 3){
//       this.getScholerships();
//     }
// 	});
//   };


handleChange  = e => {
	const {id, value} = e.target
	this.setState({
		search_keyword:value,
	})
}

/* handle for scholarship_name */
handleSearch  = () => {
	this.setState({
                currentPage : 1
            }, () => {
                this.getScholerships();
            })
};

onKeyEnter = (e) => {
	if(e.keyCode === 13) {
		this.setState({
                currentPage : 1
            }, () => {
                this.getScholerships();
            })
	}
}

//   checkAffiliated = (group_id) => {
//     var self = this;
//     let where =[{url:"/check-affiliate"}];
//     var obj = {
//     	group_id : group_id
//     };
//     postApiData(where,obj).then(response => {	
// 		if(response.status==200 && response.data.status==true){
// 			self.setState({
// 				affiliated: response.data.affliate_status,
// 				group_name :response.data.group_name, 
// 			});
// 		}		
//     })
//     .catch(function (error) {
//       console.log("In catch : "+error);
//     });
//   }

  groupWiseScholarship = () => {
  	this.setState({
  		checked: !this.state.checked,
  		groupWise:!this.state.checked
  	},()=>{
  		this.getScholerships();
  	});
  }

  imagineWiseScholarship = () => {
  	this.setState({
  		checked: !this.state.checked,
  		groupWise:!this.state.checked,
  		for_imagin : !this.state.checked
  	},()=>{
  		this.getScholerships();
  	});
  }


  handleSearchStateChange = (event) => {
  	const {id, value} = event.target;
  	this.setState({
  		state_keyword: value
  	},() => {
  		this.getStateList()
  	})
  }

   /**
   * Get getStateList listing.
   *
   * @return error/success
  */
  getStateList = () => {
    // Set var
    var self = this;
    this.setState({stateList:[]});
    // Set post data
    let where =[{url:"/get-state"}]; // Service param
    let obj = {
    	state_keyword : this.state.state_keyword
    }		
    // Post parameters on service URL
    postApiData(where,obj).then(response => {
      if(response.status==200 && response.data.success==true)
	  {
        this.setState({ stateList: response.data.state });
      }
	  else
	  {
       this.setState({ stateList: 0 });
      }
      
    })
    .catch(function (error) {
      console.log("In catch : "+error);
    });
  }

  getScholershipsByState = (stateId=null, stateName="") =>{
  	console.log('stateId->', stateId);
  	console.log('stateName->', stateName);
  	this.setState({
  		state_keyword:stateName,
  		state_id:stateId,
  		stateList : []
  	},() => {
  		this.getScholerships();
  	})
  }

  handleSearchMajorChange = (event) => {
  	const {id, value} = event.target;
  	this.setState({
  		major_keyword: value
  	},() => {
  		this.getMajorList()
  	})
  }

   /**
   * Get getStateList listing.
   *
   * @return error/success
  */
  getMajorList = () => {
    // Set var
    var self = this;
    this.setState({majorList:[]});
    // Set post data
    let where =[{url:"/get-major"}]; // Service param
    let obj = {
    	major_keyword : this.state.major_keyword
    }		
    // Post parameters on service URL
    postApiData(where,obj).then(response => {
      if(response.status==200 && response.data.success==true)
	  {
        this.setState({ majorList: response.data.major });
      }
	  else
	  {
        this.setState({ majorList: 0 });
      }
    })
    .catch(function (error) {
      console.log("In catch : "+error);
    });
  }

  getScholershipsByMajor = (majorId=null, majorName="") =>{
  	console.log('stateId->', majorId);
  	console.log('stateName->', majorName);
  	this.setState({
  		major_keyword:majorName,
  		major_id:majorId,
  		majorList : []
  	},() => {
  		this.getScholerships();
  	})
  }

  render() {
    
	// if profile not completed then redirect schooling standard
	if(reactLocalStorage.get('profile_status')!=1)
	{
		return <Redirect to='/scholarships'/>;
	}
	
    // Redirection
    if(this.state.redirect) 
    {
      return <Redirect to='/payment'/>;
    }

    var scholershipSet 		= "No matched scholarship found.";
	var scholershipSetList 	= "No matched scholarship found.";
	let stateList = '';
	let majorList = '';
	if(this.state.stateList.length > 0) {
		stateList = this.state.stateList.map((item,i) => {
			return (<li key={i} className="list-group-item active" onClick={()=>this.getScholershipsByState(item.id, item.state_name)} >{item.state_name}</li>)
		})	
	}


	if(this.state.majorList.length > 0) {
		majorList = this.state.majorList.map((item,i) => {
			return (<li key={i} className="list-group-item active" onClick={()=>this.getScholershipsByMajor(item.id, item.major_name)} >{item.major_name}</li>)
		})	
	}

	if(this.state.scholership.length >0)
	{
		/* Prepare content of grid view */
		scholershipSet = [this.state.scholership.map((item,i) => {
			return  <Col lg="6">
						<Card className="scholarship-card">
							<Card.Body>
								<div className="removeIcon">
									<a href="javascript:void(0);"  aria-label="Close" onClick={ () => this.dislikeScholarship(item.id)} id={"rmloaderdiv_"+item.id}>X</a></div>
								<Card.Link href={item.weblink} target="_blank">
									<Card.Title id="cardTitle"><u>{item.scholarship_name}</u></Card.Title>
								</Card.Link>
								<Card.Text>
								  <ShowMoreText
								  lines={3}
								  more='more'
								  less='less'
								  anchorClass=''
								  onClick={this.executeOnClick}
								  >
								  {item.description}
								  </ShowMoreText>
								</Card.Text>
								<div className="price-value clearfix">
									<div className="price">{ (item.award > 0 ? '$' + item.award : item.award)}</div>
									<div className="time">
									  Deadline: {item.deadline}<br/>
									  One Time Award
									</div>
								</div>
							</Card.Body>
							<Card.Footer className="text-muted"> 
								<div style={{"float" : "left"}}>
								  <div style={{textAlign:"center","display":"none","float": "right","marginRight": "10px"}}  ref="loaderdiv">
								  <SmallPageLoader/>  
								  </div>
									<Card.Link class="btn btn-success" id="applybtn" href="javascript:void(0)" onClick={ () => this.startApply(item.weblink,item.id)}><div className="applyCls" id={"loaderdiv_"+item.id}>Apply</div></Card.Link>
								</div>
								<div style={{"float": "right"}} id={"save_"+item.id}>
								  <div style={{textAlign:"center","display":"none","float": "left","marginRight": "10px"}}  ref="loaderdiv">
								  <SmallPageLoader/>  
								  </div>
								  <Button variant="success float-right" className="custom-btn" onClick={ () => this.saveScholarship(item.id)} id={"ssloaderdiv_"+item.id} >Save</Button>
								</div>
							</Card.Footer>
						</Card>
					</Col>
		})];
		
		/* Prepare content of list view */
		scholershipSetList = [this.state.scholership.map((item,i) => {
		return <Row>
				  <Col lg={6} className="schGridCol"><u><a href={item.weblink} target="_blank" style={{color: '#010167'}}>{item.scholarship_name}</a></u></Col>
				  <Col className="schGridCol">{ (item.award > 0 ? '$' + item.award : item.award)}</Col>
				  <Col className="schGridCol">{item.deadline}</Col>
				  <Col className="schGridCol">                    
					<div>
					  <div style={{textAlign:"center","display":"none","float": "right","marginRight": "10px"}}  ref="loaderdiv">
					  <SmallPageLoader/>  
					  </div>
						<Card.Link href="javascript:void(0)" className="custom-btn" onClick={ () => this.startApply(item.weblink,item.id)} id={"loaderdiv_"+item.id} >Apply</Card.Link>
					</div>
				  </Col>
				  <Col className="schGridCol">
					<div  id={"save_"+item.id} style={{marginTop:'-5px'}}>
					  <div style={{textAlign:"center","display":"none","float": "left","marginRight": "10px"}}  ref="loaderdiv">
					  <SmallPageLoader/>  
					  </div>
						<Button variant="success" className="custom-btn" style={{'marginTop': '0px','padding': '4px 20px'}} onClick={ () => this.saveScholarship(item.id)} id={"ssloaderdiv_"+item.id}>Save</Button>						
					</div>
					<div className="removeIcon"><a href="javascript:void(0);"  onClick={ () => this.dislikeScholarship(item.id)} id={"rmloaderdiv_"+item.id}>X</a></div>
				  </Col>
			  </Row>          
		})];
	}
	
    const handleHide = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    const { horizontal} = this.state
    const horizontalLabels = {
      0: 'Low',
      50: 'Medium',
      100: 'High'
    }
    const formatkg = value => '$ '+value
    return (
			  <div>
				  <ScholarshipHeader/>
				  <main role="main">
					<Container fluid className="schloarships-section" id="info-section">
						<Row>
						
							<Col lg={3} >
								<div className="filter-sidebar">
									<Form>
										<h5><label id="searchText" for="Search Scholarships">Search Keyword</label></h5>
										<Form.Group style={{display: 'inline-block'}}>
										<InputGroup className="mb-2 mr-sm-2" style={{width:'265px'}}>
												<FormControl type="text" aria-labelledby="searchText" id="search_keyword" onChange={this.handleChange} onKeyDown={this.onKeyEnter} value={this.state.search_keyword}  />
												<InputGroup.Append>
												<InputGroup.Text><img src="/search.png"   onClick={this.handleSearch} width="29" alt="search by keyward"></img></InputGroup.Text>
												</InputGroup.Append>
												e.g. International, Military, Fine Arts, Disabilities etc.
											</InputGroup>
										</Form.Group>
										
										<h5><label id="deadline" for="Search deadline">Scholarship Deadline</label></h5>
										<Form.Group controlId="exampleForm.ControlSelect1"> 
											<DateRangePicker  startDate={this.state.startRange} minDate={moment()} endDate={this.state.endRange} onApply={this.handleDateRangeEvent}
											onCancel={this.handleDateRangeBlank} >
											<Form.Control type="text" aria-labelledby="deadline" id="date_range" value={this.state.dateRange} /> 
											</DateRangePicker>
										</Form.Group> 
										{/*
										<h5>Minimum Award Amount</h5>
										<Form.Group>
											<div className='slider custom-labels'>
												<Slider
												min={0}
												max={50000}
												value={horizontal}
												labels={true}
												format={formatkg}
												handleLabel={false}
												onChange={this.handleChangeHorizontal}
												onChangeComplete={this.handleChangeComplete}
												/>
												<div className='value'>{formatkg(horizontal)}</div>
											</div>
										</Form.Group>
										*/}

										{ this.state.group_id ?
										<Form.Group style={{display: 'inline-block'}}>
										<Form.Check type="checkbox" id="currentGroup"  label={this.state.group_name+' Specific Scholarships'}  value={this.state.user_id} defaultChecked={this.state.checked} onChange={this.groupWiseScholarship} />
										</Form.Group>
										:
										<Form.Group style={{display: 'inline-block'}}>
										<Form.Check type="checkbox" id="currentGroup"  label="Imagine Specific Scholarships"  value={this.state.user_id} defaultChecked={this.state.checked} onChange={this.imagineWiseScholarship} />
										</Form.Group>
										}
										
										{/*
										<h5>State</h5>
										<Form.Group style={{display: 'inline-block'}}>
										<Form.Control type="text" id="state_keyword" value={this.state.state_keyword} onChange={this.handleSearchStateChange} autoComplete="off"/>
										{stateList &&
										<ul className="list-group">
										{stateList}
										</ul>
										}
										
										</Form.Group>



										<h5>Major</h5>
										<Form.Group style={{display: 'inline-block'}}>
										<Form.Control type="text" id="major_keyword" value={this.state.major_keyword} onChange={this.handleSearchMajorChange} autoComplete="off"/>
										{majorList &&
										<ul className="list-group">
										{majorList}
										</ul>
										}
										
										</Form.Group>
										*/}


									</Form>					
								</div>
								
							</Col>
							
							
							<Col lg={9}>
								<div className="schloarship-list">
								<h1 style={{'color':'#212529'}}>Internship</h1>
                  <hr/>
									<Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })} >
										<TabList>
											<Tab>Grid View</Tab>
											<Tab>List View</Tab>
										</TabList>
										{this.state.msg!==''?(<div className="alert alert-success"><strong>{this.state.msg}</strong></div>):(null)}
										<TabPanel> 
											<Row>
											{this.state.loaderDiv ?
											(<div style={{textAlign:"center"}} ref="loaderdiv"><PageLoader/></div>)
											:
											scholershipSet
											}
											
											{(this.state.isPaid != 'Y' || this.state.affiliated == 1) &&
											<Container>
												<div className="create-account-section text-center">
													<p style={{marginBottom:'20px', fontSize: '18px'}}>There are thousands of scholarships waiting just for you. Upgrade here to start finding money today!</p>
													<Button variant="primary" onClick={this.redirectPayment}>Upgrade</Button>
												</div>
											</Container>
											}
											</Row>
										</TabPanel>
										<TabPanel> 
											<Row>
												<Container>
													{ this.state.scholership.length >0 &&
													<Row>
														<Col lg={6} className="schGridHead" >Scholarship name</Col>
														<Col className="schGridHead">Award</Col>
														<Col className="schGridHead">Deadline</Col>
														<Col className="schGridHead">Apply</Col>
														<Col className="schGridHead">Save</Col>
													</Row>
													}
													
													{scholershipSetList}
												</Container>
												{(this.state.isPaid != 'Y' || this.state.affiliated == 1) &&
												<Container>
													<div className="create-account-section text-center">
														<p style={{marginBottom:'20px', fontSize: '18px'}}>There are thousands of scholarships waiting just for you. Upgrade here to start finding money today!</p>
														<Button variant="default" onClick={this.redirectPayment}>Upgrade</Button>
													</div>
												</Container>
												}
											</Row>
										</TabPanel>
									</Tabs>
								</div>
							</Col>
						</Row>
					</Container>
					</main>
				<InnerFooter/>
			</div>
		);
	}
}
export default Interships;