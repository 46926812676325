/* _________________________________
  |                                 |
  |        User Dashboard           |
  |   user Clicks and saved data    |
  |                                 |
  |_________________________________|
                                       */
// Initial Lib
import React, { Component } from 'react';

import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Include Component
import DashHeader from './DashHeader';
import InnerFooter from '../layout/InnerFooter';
import DashSideNav from './DashSideNav';

// Design Lib
import {PageLoader,SmallPageLoader} from '../loaders/PageLoader'; // Page Loader
import ShowMoreText from 'react-show-more-text'; // Show more text
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Card} from "react-bootstrap";
import { Redirect, browserHistory,router, Link } from 'react-router-dom';
import WOW from "wowjs";


// Service lib
import {postApiData,getApiData} from '../../services/Api';

// default Base Urls
import config from 'react-global-configuration';

const history = createBrowserHistory();

class UserDashboard extends Component {
    
  constructor(props){
    super(props);
    this.state={
      currentPath : this.props.match.path,
      savedScholarships: [],
      // Local Storage
      loggedin:reactLocalStorage.get('loggedin'),
      user_id : localStorage.getItem('userId'),
      isPaid: localStorage.getItem('paid'),
    }

    // Bind functions
    this.startApply = this.startApply.bind(this);

  }

  /**
   * Set Saved Scholarship Var values.
   *
   * @Set values
  */
  savedScholarships() {

    // Set post data
    let where =[{url:"/saved-scholarships"}]; // Service param
    var form = new FormData();
    form.append('user_id',this.state.user_id);
    var self = this;

    // Post parameters on service URL...
    postApiData(where,form).then(res => {
      if(res.data.result){
        self.setState({ savedScholarships: res.data.result}); // Set data
      }else{
        self.setState({ savedScholarships: 0}); // Set blank
      }
    })
    .catch(function (error) {
    });
  }

  /**
   * Start Apply for scholarship.
   *
   * Log and redirect
  */
  startApply(link,id){
    
    // Set var
    var self = this;
    let divId = "loaderdiv_"+id;
    var applyElm = document.getElementById(divId);
    //document.getElementById(divId).style.display = "block";
    applyElm.innerHTML = "Applying...";

    // Set post data
    let where =[{url:"/save-apply-hit"}]; // Service param
    var form = new FormData();
    form.append('user_id',this.state.user_id);
    form.append('scholarship_id',id);
    form.append('hit_url',link);
    form.append('hit_from','dashboard');

    // Post parameters on service URL
    postApiData(where,form).then(res => {
      applyElm.innerHTML = "Apply";
      //document.getElementById(divId).style.display = "none";
      window.open(link, '_blank');
    })
    .catch(function (error) {
      applyElm.innerHTML = "Apply";
      console.log("In catch : "+error);
    });

  }

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount(){
    new WOW.WOW().init();
    this.savedScholarships();
  }
  
  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {

    // Auth Redirection
    if(this.state.user_id){
      if(this.state.isPaid == 'N'){
        reactLocalStorage.set('redirectUri', '/dashboard');
        //return <Redirect to='/payment'/>;
		return <Redirect to='/scholarships'/>;
      }
    }else{
      reactLocalStorage.set('redirectUri', '/dashboard');
      return <Redirect to='/login'/>;
    }

    var scholershipSet = "";

    // Create Scholarship List Data
    if(this.state.savedScholarships == 0){ // If no Scholarship Found
        scholershipSet = "No scholarship saved yet.";
    }else{
      scholershipSet = [this.state.savedScholarships.map((item,i) => {
        return <Col lg="6">
        <Card className="scholarship-card">
          <Card.Body>
                <Card.Title>{item.scholarship_name}</Card.Title>
                <Card.Text>
                  <ShowMoreText
                  lines={3}
                  more='Show more'
                  less='Show less'
                  anchorClass=''
                  onClick={this.executeOnClick}
                  >
                  {item.description}
                  </ShowMoreText>
                </Card.Text>
                <div className="price-value clearfix">
                  <div className="price">${item.award}</div>
                  <div className="time">
                  Deadline: {item.deadline}<br/>
                  One Time Award
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className="text-muted"> 
                <div style={{"float" : "left"}}>
                  <div style={{textAlign:"center","display":"none","float": "right","marginRight": "10px"}}  ref="loaderdiv">
                  <SmallPageLoader/>  
                  </div>
                  <Card.Link class="btn btn-success" href="javascript:void(0)" 
                      onClick={ () => this.startApply(item.weblink,item.id)}><div className="applyCls" id={"loaderdiv_"+item.id}>Apply</div></Card.Link>
                </div>
                
              </Card.Footer>
            </Card>
        </Col>

      })];
    }

    return (
      <div>
       <DashHeader/>
  
        <Container fluid className="schloarships-section admin-dashboard">
        <Row>
          <Col lg={3} >
          <DashSideNav path={this.state.currentPath} />
          </Col>
      <Col lg={9}>
          <div className="schloarship-list">
              <h3 style={{'color':'#212529'}}>Saved Scholarships</h3>
              <hr/>
              <Row>
                 {scholershipSet}
              </Row>
          </div>
      </Col>

      </Row>


  

      </Container>

       <InnerFooter/>

      </div>
    );
  }
}
export default UserDashboard;