import React, { Component } from 'react';
import {Nav, Navbar,  Container, Row, Col} from "react-bootstrap";
import {reactLocalStorage} from 'reactjs-localstorage';

class InnerFooter extends Component {
    constructor(props){
      super(props);
      this.state={
      	user_id:reactLocalStorage.get('userId'),
      	password:'',
      	loggedin:reactLocalStorage.get('loggedin'),
      	first_name:reactLocalStorage.get('firstName'),
        tabIndex:0,
      } 
    }
   render() {

    return (
      
     <div>

<footer>
	<div className="main-footer separator py-5">
          <Container  selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
			    <Row>
                     <Col md={3}>
                       <a href="/"><img src="/assets/img/logo.svg" alt="Baylor University Logo" className="mb-3" /></a>
                        <p className="mb-2">Baylor University is a private Christian university and a nationally ranked research institution.</p>
                        <ul class="list-inline">
                            <li className="list-inline-item"><a  href="https://www.facebook.com/BaylorUniversity1845" target="_blank"><img title="facebook" alt="facebook" src="/assets/img/facebook.svg" /></a></li>
                            <li className="list-inline-item"><a  href="https://www.twitter.com/baylor/"  target="_blank"><img alt="twitter" title="twitter" src="/assets/img/twitter.svg" /></a></li>
                            <li className="list-inline-item"><a  href="https://www.youtube.com/baylor/"  target="_blank"><img alt="youtube" title="youtube" src="/assets/img/youtube.svg" /></a></li>
	   {/* <li className="list-inline-item"><a href="#"><img src="../assets/img/dribble.svg" /></a></li> */}
                        </ul> 
                   </Col>  
                   <Col md={3}>
                    <h5>Quicklinks</h5>
                        <ul className="inline">
                            <li><a href="/" >Home</a></li>
                            <li><a href="https://www.imaginecollegecoaching.com/frequently-asked-questions"  target="_blank">FAQs</a></li>
                            <li><a href="/scholarships" >Get Started</a></li>
                        </ul>
                   </Col>  
                   <Col md={3}>
                    <h5>Quicklinks</h5>
                        <ul className="inline">
                        <li><a  href="https://www.imaginecollegecoaching.com/baylor-contact-page" target="_blank">Contact Us</a></li>
	<li><a href="/terms-and-conditions" >Terms and Conditions</a></li>
                        </ul>
                   </Col> 
                    <Col md={3}>
                    <img src="/logo.png" alt="Imagine Scholarships Logo" height="100px"/><p style={{paddingLeft:'5px'}}> Powered By Imagine Scholarships</p>
                   </Col> 
                 
				
                </Row>
          </Container>
	</div>
    <div className="bootom-footer py-3">
         <Container>
			<Row>
            <Col md={3}>
            {/* All Right Reserved | BAYLOR 2020 */}
            Copyright © 2021 Imagine Scholarships.
            </Col> 
            <Col md={3}>
            <ul className="list-inline text-right mb-0">
						<li className="list-inline-item"><a target="_blank" href="https://www.imaginecollegecoaching.com/privacypolicy" >Privacy Policy</a></li>
						{/* <li className="list-inline-item">Site <a href="#" className="site-map">Map</a></li> */}
					</ul>
            </Col>      
			
            </Row>
        </Container>
	</div>
</footer>

    </div>

    
    );
  }
}
export default InnerFooter;