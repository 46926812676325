import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col,Button,Nav} from "react-bootstrap";
import {reactLocalStorage} from 'reactjs-localstorage';
import config from 'react-global-configuration';
import '../home.css';
import InnerFooter from "../components/layout/InnerFooter";
import NewHeader from "../components/layout/NewHeader";

import {Link, Redirect} from "react-router-dom";
class Main extends Component {
    constructor(props){
      super(props);
      this.state={
        thumb1: false,
        thumb2: false,
        thumb3: false,
        tabIndex:0,
        total_amount: 0,
        loggedin:reactLocalStorage.get('loggedin'),
        user_id: localStorage.getItem('userId'),
        profileStatus: localStorage.getItem('profile_status'),
        open: false,
        validated: false,
        email: '',
        Msg: '',
        administratorLoginUrl: config.get('imgUrl'),
        groupLoginUrl: config.get('imgUrl')+'group',
      }
    }
    componentDidMount(){
        window.addEventListener('scroll', () => {
           let activeClass = 'normal';
           if(window.scrollY === 0){
               activeClass = 'top';
           }
           this.setState({ activeClass,  });
        });
	
    }
   render() {
   /* if(this.state.loggedin) {
      console.log('sdfsf',this.state.loggedin);
      return (<Redirect to="/matched-scholarships" />)
    }*/
    return (
      
     <div>
 {/* Start: Header*/}  
    <NewHeader /> 
{/* End: Header*/}  
  
{/*--Start Hero Banner--*/}    
<main role="main">
<section className="banner-section " style={{background:'url(../assets/img/banner-img.png) no-repeat center center', backgroundSize:'cover'}}>
       <div className="caption">
           <Container  id="main" selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })} >
                <h1 style={{color:'#fff',backgroundColor:'#164734',textAlign:'center'}}>What is Baylor Scholarships? </h1>
	   {/* <h4>Baylor Scholarships is an extensive collection of scholarship opportunities that are researched and updated daily, to give you maximum access to all available education dollars. </h4>*/}
<h2 style={{paddingTop:'10px',color:'#fff',backgroundColor:'#164734',textAlign:'center',fontSize:'22px',fontWeight:'300',textTransform:'none'}}>Baylor Scholarships is brought to you by Imagine Scholarships. Our database is an extensive collection of internal and external scholarship opportunities that are researched and updated daily. In addition we have internships and a library of videos to help you be successful. We will be cheering you on each step of the way!</h2>

	{ this.state.user_id ? 
                             this.state.profileStatus==1 ?
					<Button className="btn btn-warning" id="b1"     href="/matched-scholarships">Get Started</Button>
                    // <Nav.Link href="/matched-scholarships" >      <Button variant="warning"  className="b1"  >Get Started</Button></Nav.Link>
				:
					<Button className="btn btn-warning" id="b1"    href="/scholarships">Get Started</Button>
                    // <Nav.Link href="/scholarships" >      <Button variant="warning" className="b1"  >Get Started</Button></Nav.Link>
                    
			  : 
				<Button className="btn btn-warning"  id="b1"    href="/scholarships">Get Started</Button>
                // <Nav.Link href="/scholarships" >    <Button variant="warning" className="b1"  > Get Started</Button></Nav.Link>
				
                // <Button variant="success">Get Started</Button>
                            }
	   {/*  <a href="/scholarships" className="btn btn-warning">Get Started</a> */}
				
	             
            </Container>
        </div> 
</section>
{/*--End Hero Banner--*/}    

{/*--Start Undergraduate--*/}
<section className="sepration bg-green-pea undergraduate-section">
    <Container fluid>
        <Row>
            <Col md={6}>
                <div className="infoBlock">
                    <h3 className="text-white">How does it work?</h3>
                    <p className="text-white">Our process is easy and valuable, as it caters to the unique needs of each student. We save you valuable time and money by selecting scholarships that are unique to your gifts, talents, and specific criteria. We organize the ones that are a good match and the ones you’ve applied for so that you can keep track of your progress. To be most successful, we recommend you apply to 3-5 scholarships per week.</p><br />
                    <p className="text-white">We want to cheer you on each step of the way, so we’ve added video resources with videos and updates to keep you moving in the process. You can find those under the video resources link after you log in.</p>
                </div>
                   
            </Col>
            <Col md={6} className="imgblock" style={{background:'url(../assets/img/undergraduate-img.png) no-repeat', backgroundSize:'cover'}}></Col>
        </Row>
    </Container>    
</section>
{/*--End Undergraduate--*/}

{/*--Start Scholarship--*/}
<section className="scholarship-section sepration text-center">
	<div className="scholarship-section-wrap bg-green-pea">
        <Container>
			<h2>What’s next?</h2>
			<p className="text-white">Log in with the unique code your school sent to you, fill out your profile, and then, you’re all set! The only thing left to do is fill out scholarship applications. It couldn’t be easier!</p>
<br />
<p className="text-white">After clicking on ‘GET STARTED’, you will be directed to fill out a profile, and then we will get busy finding you scholarship matches!</p>
  <br />
  <p className="text-white">You can then see your “MATCHED SCHOLARSHIPS.” When you first sign up, there will be quite a few options. Look through them carefully. Save the ones that you qualify for and delete the ones that aren’t a good fit for you.  Go to “SAVED SCHOLARSHIPS” and start applying. You can keep track of deadlines and dollar amounts under “APPLIED SCHOLARSHIPS.” Questions? Contact us anytime on the contact page. Be sure to check out the video resources.</p>
        </Container>
	</div>
	<div className="scholarship-section-img"><img src="../assets/img/scholarship.png" alt="Scholarships" width="100%"/></div>
</section>
{/*--End Scholarship--*/}

{/* --Start About--
<section className="about-section separator text-center sepration">
	<div className="about-section-wrap bg-green-pea py-5">
         <Container>
			<h2 className="text-white">About Baylor University</h2>
			<p className="text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
         </Container>
		<a href="#" class="btn btn-default">Learn More About Baylor</a>
	</div>
</section>
*/}
</main>
{/*--End About--*/}


 {/* Start: Footer*/}  
 <InnerFooter /> 
{/* End: Footer*/}  


    </div>

    
    );
  }
}
export default Main;