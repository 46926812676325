/* _____________________________________________________________
  |                                                             |
  |                   Payment Activity                          |
  |   Stripe paymet gateway related input and it's execution.   |
  |                                                             |
  |_____________________________________________________________|
                                                                */
// Basic
import React, { Component } from 'react';
import { Redirect, browserHistory,router } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';
import {PageLoader,SmallPageLoader} from './loaders/PageLoader'; // Page Loader

// Include Component
import InnerHeader from './layout/InnerHeader';
import InnerFooter from './layout/InnerFooter';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Tab, Tabs, Button, InputGroup, FormControl, Form, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import WOW from "wowjs";

// Service lib
import {postApiData,getApiData} from '../services/Api';

const history = createBrowserHistory();

class Payment extends Component {
  
  constructor(props){

    super(props);
    console.log(this.props)
    this.state={
      validated: false,
      isPayment:false,
      isPaymentError:false,
      membershipPlans: [],
      plan: localStorage.getItem('plan'),
      plan_id: "",
      redirect : 0,
      // local Storage Var
      isLogin: localStorage.getItem('userId'),
      isPaid: localStorage.getItem('paid'),
      redirectUri: localStorage.getItem('redirectUri'),
      // redirect Auth
      authRedirect : this.props.match.path
    }
    
  }
 

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount(){
    new WOW.WOW().init();
    window.scrollTo(0, 0);

    // Set Var
    var self = this;
    // Set post data
    let where =[{url:"/student-membership-plans"}]; // Service param

    // Post parameters on service URL...
    getApiData(where).then(response => {
		if(response.status==200 && response.data.success==true)
		{
			self.setState({ membershipPlans : response.data.plans});
		}
    })
    .catch(function (error) 
	{
    });
  }


  /* Handle Blank Spaces */
  handlePlanChange = event => {
    let eleVal = event.target.id.trim();
    reactLocalStorage.set('plan', eleVal);
    this.setState({'redirect': 1 });
    this.setState({'plan': eleVal});
  }

  /* Reset Plan */
  resetPlan = event =>{
    reactLocalStorage.set('plan', '');
    this.setState({'plan': ''});
    window.scrollTo(0, 0);
  }
  
  async openNav() {
  document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
  document.getElementById("myNav").style.width = "0%";
  }
  
  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {

    // Redirection
    if (this.state.redirect) {
      return <Redirect to='/login'/>;
    }
      
    // Auth redirection
    if(this.state.isLogin){
      return(<Redirect to='/login'  />); 
    }

    var term = "";
    // Set Number of Students Drop Down
    let membershipPlansSet = this.state.membershipPlans.map((item,i) => {
      term = (item.term=='m')?' / month ':' / year ';
      return <Col lg={(this.state.membershipPlans.length<3)?'6':'4'}>
        <Card>
        <Card.Body>
          <Card.Title className="text-muted text-uppercase text-center">{item.membership_name}</Card.Title>
          <h6 className="card-price text-center">${item.membership_cost.toFixed(2)}<span class="period">{term}</span></h6>
          <hr/>
          {(item.membership_desc)?
          <ul className="fa-ul">
            <li>{item.membership_desc}</li>
          </ul>:""}
          <a href="javascript:void(0)" className="btn btn-block btn-primary text-uppercase" id={item.id + '~=>' + item.membership_cost.toFixed(2) + term}
          onClick={this.handlePlanChange} >Continue</a>
        </Card.Body>
        </Card>
      </Col>;
    });

    const handleHide = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    const { validated } = this.state;
    return (
   
      <div style={{background:'url(/login-bg.png) no-repeat', backgroundSize:'cover', paddingBottom:'1px'}}>
        <InnerHeader/>
            <section class="pricing py-5">
            <h2 style={{'color': '#fff','textAlign': 'center','padding': '12px'}} >Select Membership Plan</h2>
              <Container>
                <Row>
                  {membershipPlansSet}
                </Row>
              </Container>
            </section>
        <InnerFooter/>
      </div>
  
    );
  }
}

const style = {
 margin: 15,
};

export default Payment;
