/* _________________________________________________
  |                                                 |
  |             Success Message                     |
  |                                                 |
  |_________________________________________________|
                                                                */

import React, { Component } from 'react';
import InnerHeader from './layout/InnerHeader';
import InnerFooter from './layout/InnerFooter';
import { Redirect, browserHistory,router } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Tab, Tabs, Button, InputGroup, FormControl, Form, Modal, Popover, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";
import WOW from "wowjs";
import {postApiData,getApiData} from '../services/Api';

const history = createBrowserHistory();

class Success extends Component {
  
  constructor(props){

    super(props);
    
    this.state={
      redirect: false,
      loggedin:false,
      isLogin: localStorage.getItem('userId'),
      isLead: localStorage.getItem('lead_id'),
      isLeadEmail: localStorage.getItem('lead_email'),
      name: this.props.match.params.name,
      type: this.props.match.params.type,

    }
    
  }
 
  async openNav() {
  document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
  document.getElementById("myNav").style.width = "0%";
  }

  componentDidMount(){
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }

  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {

    const handleHide = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    return (
      <div style={{background:'url(/login-bg.png) no-repeat', backgroundSize:'cover', paddingBottom:'1px'}}>
      <InnerHeader/>
      <div  style={{minHeight:'500px'}}>
        <div className="login-section">
          <div style={{'margin': '0 auto','fontVariant': 'revert','color': 'green','margin':'44px 10px','fontSize':'15px'}}>
          Dear {unescape(this.state.name)},<br/><br/> Please check your inbox to verify your email address. <a href="/">Click here</a> to go on home page. </div>
        </div>
      </div>
      <InnerFooter/>
      </div>
    );
  }
}

const style = {
 margin: 15,
};

export default Success;