// Initial Lib
import React, { Component } from 'react';
import createBrowserHistory from 'history/createBrowserHistory';
import {reactLocalStorage} from 'reactjs-localstorage';

// Include Component
import ScholarshipHeader from '../layout/scholarshipHeader';
import InnerFooter from '../layout/InnerFooter';
import DashSideNav from './DashSideNav';
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Card, Nav, Button, InputGroup, FormControl, Form, Image, OverlayTrigger, Tooltip, Alert, Modal} from "react-bootstrap";
import { Redirect, browserHistory,router, Link } from 'react-router-dom';
import WOW from "wowjs";

// Service lib
import {postApiData,getApiData} from '../../services/Api';

// default Base Urls
import config from 'react-global-configuration';

const history = createBrowserHistory();

class Membership extends Component {
    
	constructor(props){
		super(props);
		this.state={
			currentPath : this.props.match.path,
			currentPlan : [],
			paidStatus : null,
			membershipType: null,
			confModel: false,
			errorMsg: '',
			successMsg: '',
			cancelBtnText : 'Cancel',
		}
	}
	
	
	// Upgrade subscription
	handleUpgradeSubscription = (event) => {
		reactLocalStorage.set('plan', '');
		reactLocalStorage.set('paid', 'N');
		window.location = '/payment';
		
	}
	
	// Confirmation on subscription cancellation
	handleConfirmCancel = (event) => {
		this.setState({confModel: true});
	}
	
	// Cancel if User opt to cancel
	handleCancelPlan = (event) => {
		// Set var
		var self = this;
		// Set post data
		let where =[{url:"/cancel-student-plan"}]; // Service param
		var form = new FormData();
		
		var btnCancel =  document.getElementById('btnCancel');
		this.setState({ cancelBtnText: "Processing...", confModel: false });
		btnCancel.style.pointerEvents = "none";	
		// Post parameters on service URL
		
		postApiData(where,form).then(response => {	
		
			self.setState({ cancelBtnText: "Cancel" });
			btnCancel.style.pointerEvents = "auto";	
				
			if(response.status==200 && response.data.success==true)
			{
				self.setState({confModel: false, successMsg : response.data.msg, errorMsg: ''});
				
				// Load  student current plan
				this.loadStudentCurrentPlan();
			}
			else
			{
				self.setState({errorMsg : response.data.msg, successMsg: ''});
			}	
			reactLocalStorage.set('plan', '');
			this.setState({confModel: false});
		})
		.catch(function (error) {
		  console.log("In catch : "+error);
		});		
	}
	
	// Close popup if user don't want to see the popup
	handleClosePopup = (event) => {
		this.setState({confModel: false});
	}
	
	// Load the current plan of student
	loadStudentCurrentPlan() {		
		// Set Var
		var self = this;
		// Set post data
		let where =[{url:"/get-student-current-plan"}]; // Service param

		// Post parameters on service URL...
		getApiData(where).then(response => {
			if(response.status==200 && response.data.success==true)
			{
				self.setState({ currentPlan : response.data.currentPlan, paidStatus: response.data.paidStatus, membershipType: response.data.membershipType });
				reactLocalStorage.set('paid', response.data.paidStatus);
			}
		})
		.catch(function (error) {
		  console.log(error);
		});		
	}
	
	/**
	* On Load Initialize.
	**/
	componentDidMount()
	{
		new WOW.WOW().init();
		window.scrollTo(0, 0);
		
		// Load  student current plan
		this.loadStudentCurrentPlan();
	}
  
	render() {	
		console.log(this.state.currentPlan);
		return (
			<div>
			  	<ScholarshipHeader/>
			  	<Container fluid className="schloarships-section admin-dashboard">
					<Row>
						<Col lg={3} >
							<DashSideNav path={this.state.currentPath} />
						</Col>
				  		<Col lg={9}>
							<div className="schloarship-list pricing dashboard-pricing"> 
								<h3 style={{'color':'#212529'}}>Current Membership Plan</h3>
								<hr/>
								{this.state.successMsg!=='' && (<Alert variant="success">{this.state.successMsg}</Alert>) }
								{this.state.errorMsg!=='' && (<Alert variant="danger">{this.state.errorMsg}</Alert>) }
								<Row>
									<Col  lg={{ span: 4, offset: 4 }}>
										{ /* #Check for free membership */ }
										{ (this.state.membershipType==1 && this.state.paidStatus=='Y') ?
											(<Card>
												<Card.Body>
													<Card.Title className="text-uppercase text-center">{this.state.currentPlan.membership_name}</Card.Title>
													<h6 className="card-price text-center">
														${this.state.currentPlan.membership_cost.toFixed(2)}
														<span class="period">/ { this.state.currentPlan.term }</span><
													/h6>
													<hr/>										
													<ul className="fa-ul">
														<li><strong>Next Billing Date:</strong> {this.state.currentPlan.current_period_end}</li>
														<li>{this.state.currentPlan.membership_desc}</li>
													</ul>										
													<a href="javascript:void(0);" className="btn btn-block btn-primary text-uppercase" id="btnCancel" onClick={this.handleConfirmCancel}>{this.state.cancelBtnText}</a>
												</Card.Body>
											</Card>)
											:
												(this.state.membershipType==2 && this.state.paidStatus=='Y') ?
												(<Card>
													<Card.Body>
														<Card.Title className="text-uppercase text-center">{this.state.currentPlan.membership_name}</Card.Title>
														<h6 className="card-price text-center">
															${this.state.currentPlan.membership_cost.toFixed(2)}
														</h6>
														<hr/>										
														<ul className="fa-ul">
															<li><strong>Next Billing Date:</strong> {this.state.currentPlan.current_period_end}</li>
															<li>{this.state.currentPlan.membership_desc}</li>
															<li>{this.state.currentPlan.membership_code}</li>
														</ul>										
														<a href="javascript:void(0);" className="btn btn-block btn-primary text-uppercase" id="btnCancel" onClick={this.handleUpgradeSubscription}>Upgrade</a>
													</Card.Body>
												</Card>)
												
												:
												
												(<Card>
													<Card.Body className="active">
														<Card.Title className="text-uppercase text-center">{this.state.currentPlan.membership_name}</Card.Title>
														<h6 className="card-price text-center">{this.state.currentPlan.membership_cost}</h6>
														<hr/>										
														<ul className="fa-ul"><li>{this.state.currentPlan.membership_desc}</li></ul>										
														<a href="javascript:void(0);" className="btn btn-block btn-primary text-uppercase" onClick={this.handleUpgradeSubscription}>Upgrade</a>
													</Card.Body>
												</Card>)										
										}
									</Col>
								</Row>
							</div>
				  		</Col>
					</Row>
			  	</Container>
				<Modal show={this.state.confModel} onHide={this.handleClosePopup} className="video-modal subscribe-model">
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<h3>Cancel Your Current Plan</h3>
						<p>Are you sure you want to cancel?</p>
						<div>
							<a href="javascript:void(0);" className="btn_cancel_plan" onClick={this.handleCancelPlan}>Yes</a> &nbsp; 
							<a href="javascript:void(0);" className="btn_cancel_plan" onClick={this.handleClosePopup}>No</a>
						</div>
					</Modal.Body>
				</Modal>
			  	<InnerFooter/>
			</div>
		);
	}
}
export default Membership;