/* _________________________________
  |                                 |
  |     Dashboard Head Element      |
  |_________________________________|
                                       */
// Initial Lib
import React, { Component } from 'react';

import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Card} from "react-bootstrap";
import WOW from "wowjs";

const history = createBrowserHistory();

class DashHeader extends Component {
    
  constructor(props){
    super(props);
    this.state={
      savedScholarships: [],
      // Local Storage
      loggedin:reactLocalStorage.get('loggedin'),
      user_id : localStorage.getItem('userId'),
      firstName : localStorage.getItem('firstName'),
      lastName : localStorage.getItem('lastName'),
      isPaid: localStorage.getItem('paid'),
    }


  }

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount(){
    new WOW.WOW().init();
  }
  
  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {
    return (
      <div>
      <header className="wow slideInLeft header dashHeader">
        <Container>
        <Navbar expand="lg">
        <Navbar.Brand href="/" className="logo">imagine College Coaching</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav3" />
          <Navbar.Collapse id="basic-navbar-nav3">
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/Scholarships">SCHOLARSHIPS</Nav.Link>
              <Nav.Link href="http://www.imaginecollegecoaching.com/new-page-3" target="_blank" >TESTIMONIALS</Nav.Link>
              <Nav.Link href="http://www.imaginecollegecoaching.com/new-page-3" target="_blank">ABOUT</Nav.Link>
			  <Nav.Link href="http://www.imaginecollegecoaching.com/imagine-blog" target="_blank">BLOG</Nav.Link>
			  <Nav.Link href="/group-registration" target="_blank">GROUP REGISTRATION</Nav.Link>
            </Nav>
            <Nav defaultActiveKey="/" className="d-block d-lg-none">
                <Nav.Link href="/dashboard">Saved Scholarships</Nav.Link>
                <Nav.Link href="">Applied Scholarships</Nav.Link>                
              </Nav>
            <Form inline className="right-nav" >
              <div style={{'color':'white'}} >{"Welcome, "+this.state.firstName+" "+this.state.lastName}</div>
              <Nav.Link href="/logout" className="btn btn-primary" style={{'boxShadow':'0 0 0 0.2rem rgba(38, 143, 255, 0.5)'}}>Logout</Nav.Link>
            </Form>
          </Navbar.Collapse>
        </Navbar>
        </Container>
        </header>
      </div>
    );
  }
}

export default DashHeader;