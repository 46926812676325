/*
  __________________________________
 |                                  |
 |      Group Registration Page     |
 |__________________________________|
                                      */
// Initial Lib
import React, { Component } from 'react';
import { Redirect, Link} from 'react-router-dom';

// Include Component
import ScholarshipHeader from './layout/scholarshipHeader';
import InnerFooter from './layout/InnerFooter';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Pagination, Tab} from "react-bootstrap";

// Service lib
import {postApiData,getApiData} from '../services/Api';
import { white } from 'material-ui/styles/colors';

class groupRegistration extends Component {
    
  constructor(props){
    super(props);
    this.state={
     }  

   
  }
  
 
  async openNav() {
    document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }
  
  render() {  

   
  
    return (
        <div>
       <ScholarshipHeader/>
       <main role="main">
          <div className="bannerSecton main-banner">
       
        <Container >
         <Row>
          <Col lg={12} className="wow slideInLeft">
        <div className="info-widget" style={{marginTop:'7%', textAlign:'left'}}>
        <h1 style={{color:'#fff',fontSize:'40px',fontWeight:'700',fontFamily:'sans-serif',lineHeight:'1.2'}} className="text-center mt-4" >SITE PRIVACY POLICY.</h1><br />        
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">This Privacy Policy applies to <u><b><a style={{color:white,}} href="https://imaginescholarships.com/">www.Imagine Scholarships</a></b></u>. This Privacy Policy describes how Imagine Scholarships collects and uses the personal information you provide. It also describes the choices available to you regarding the use of, your access to, and how to update and correct your personal information.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships understands that your privacy is important. To demonstrate our strong commitment to your privacy and to explain the types of information we obtain about visitors to our website, how that information is obtained and used, and how visitors can restrict its use or disclosure, we have created the Privacy Policy below. The following discusses our information gathering and dissemination practices at Imagine Scholarships.</p>
<p className='margin-bottom: 0.49cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-top: 0.49cm;'>BY USING THIS SITE (THIS <strong>&quot;</strong><u><strong>SITE</strong></u><strong>&quot;</strong>) IMAGINE SCHOLARSHIPS, A TEXAS COMPANY (<strong>&ldquo;IMAGINE&rdquo;</strong>, <strong>&ldquo;</strong><u><strong>US</strong></u><strong>&rdquo;</strong>, <strong>&ldquo;</strong><u><strong>WE</strong></u><strong>&rdquo;</strong>, OR <strong>&ldquo;</strong><u><strong>OUR</strong></u><strong>&rdquo;</strong>) WILL COLLECT AND LEARN CERTAIN INFORMATION ABOUT THE INDIVIDUAL USER VISITING THE SITE (<strong>&ldquo;</strong><u><strong>YOU</strong></u><strong>&rdquo;</strong> OR <strong>&ldquo;</strong><u><strong>YOUR</strong></u><strong>&rdquo;</strong>) DURING YOUR VISIT AND YOUR MINOR AGED STUDENT (<strong>&ldquo;</strong><u><strong>YOUR STUDENT</strong></u><strong>&rdquo;</strong>). BY USING THIS SITE YOU REPRESENT AND WARRANT TO BE THE PARENT OR LEGAL GUARDIAN OF YOUR STUDENT AND GIVE YOUR CONSENT TO THE COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION RELATING TO YOUR STUDENT. HOW WE WILL HANDLE INFORMATION WE LEARN ABOUT YOU AND/OR YOUR STUDENT DEPENDS UPON WHAT YOU AND/OR YOUR STUDENT DO WHEN VISITING THIS SITE.</p>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;'>If you and/or your student visit our site to read or download information on our pages, we collect and store certain information about you including but not limited to:</p>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;'><br /></p>
<ul>
    <li>
        <p className="margin-bottom: 0cm;line-height: 90%;text-align: justify;">The name of the domain from which you access the Internet;</p>
    </li>
</ul>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-left: 1.27cm;text-indent: -1.27cm;'><br /></p>
<ul>
    <li>
        <p className="margin-bottom: 0cm;line-height: 90%;text-align: justify;">The date and time you access our site;</p>
    </li>
</ul>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-left: 1.27cm;text-indent: -1.27cm;'><br /></p>
<ul>
    <li>
        <p className="margin-bottom: 0cm;line-height: 90%;text-align: justify;">The Internet address of the website you used to link directly to our site;</p>
    </li>
</ul>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-left: 1.27cm;text-indent: -1.27cm;'><br /></p>
<ul>
    <li>
        <p className="margin-bottom: 0cm;line-height: 90%;text-align: justify;">The geographic region, including but not limited to, the zip code from which you have accessed this Site; and</p>
    </li>
</ul>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-left: 1.27cm;text-indent: -1.27cm;'><br /></p>
<ul>
    <li>
        <p className="margin-bottom: 0cm;line-height: 90%;text-align: justify;">The unique device identifier for the device on which you have accessed this Site, including the information which may be derived from such identifier.</p>
    </li>
</ul>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;'><br /></p>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;'>If you or your student identify yourself to IMAGINE, including sending us an e-mail, or if you or your student otherwise transmit information to us containing personal information, we may use such personal information to send important notices, such as communications about purchases and changes to our terms, conditions, and policies. Because this information is important to your interaction with us, you may not opt out of receiving these communications. We may also use personal information for internal purposes such as auditing, data analysis, and research to improve our products, services, advertising, and customer communications. If you or your student enter into a sweepstake, contest, or similar promotion we may use the information provided to administer those programs.</p>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;'><br /></p>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;background: #ffffff;'>This Site, online services, interactive applications, email messages, and advertisements may use&nbsp;&ldquo;cookies&rdquo; and other technologies such as pixel tags and web beacons. We treat information collected by cookies and other technologies as non-personal information.</p>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;background: #ffffff;'><br /></p>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;background: #ffffff;'>At times we may make certain personal information available to strategic partners that work with us in order to provide products and services, or that help us market its services to customers. Personal information will only be shared by us to provide or improve our products, services and advertising; it will not be shared with third parties for such third parties&rsquo; marketing purposes. We may also share personal information with companies who provide services such as information processing, delivering products to you, managing and enhancing customer data, providing customer service, assessing your interest or your student&rsquo;s interest in our services, and conducting customer research or satisfaction surveys. These companies are obligated to protect your information and may be located wherever we operate. It may be necessary &minus; by law, legal process, litigation, and/or requests from public and governmental authorities within or outside your country of residence &minus; for us to disclose personal information. We may also disclose personal information if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate. We may also disclose personal information if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users. Additionally, in the event of a reorganization, merger, or sale we may transfer any and all personal information we collect to the relevant third party.</p>
<p className='margin-bottom: 0.49cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-top: 0.49cm;background: #ffffff;'>This Site and products and services offered by or through us may contain links to third-party websites, products, and services. Our products and services may also use or offer products or services from third parties. Information collected by third parties, which may include such things as location data or contact details, is governed by their privacy practices. We encourage you and your student to learn about the privacy practices of those third parties.</p>
<p className='margin-bottom: 0.49cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-top: 0.49cm;background: #ffffff;'>If you have any questions or concerns about this Privacy Policy or data processing, please contact us.</p>
<p className='margin-bottom: 0.49cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-top: 0.49cm;background: #ffffff;'>We may update this Privacy Policy from time to time. You are responsible for reviewing this policy from time to time to familiarize yourself with any updates, revisions, or modifications to this Privacy Policy.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Information We Receive and How It Is Used</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">All personal information obtained by Imagine Scholarships about individual visitors to our website is information voluntarily provided by that individual visitor. During the registration process, users are required to provide certain categories of information, such as name, address, email address, phone number, a password, date of birth, gender, and country of citizenship. In addition, users may choose to provide other categories of information, such as career objectives, health or disability information, hobbies, and other interests. Imagine Scholarships encourages users to provide all fields of information to maximize the effectiveness of the scholarship and college search. We ask for permission when sharing information with marketing partners, which are reputable companies and organizations that provide products and services of interest to students and parents. Users may choose whether to opt-in or opt-out of sharing information with our marketing partners.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">When Imagine Scholarships shares personal information with a third party, the information will become permanently subject to the information use and sharing practices of the third party. The third party will not be restricted by our Privacy Policy, with respect to its use and further sharing of your personal information. If you agree to receive communications from our marketing partners and later change your mind, you will need to contact those companies directly. Any information updates you wish to make must be forwarded to the company at that point in time.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Upon request Imagine Scholarships will provide you with information about whether we hold any of your personal information. Users may change personal profile information at Imagine Scholarships at any time by logging in to the website and entering the email address and password associated with their profile. After users have logged in, they will be directed to their Scholarship Matches. In the tabbed navigation at the top of the page, users will find a link to &quot;Profile&quot;. By clicking on that tab, users will be directed to a summary of their information, divided into sections. An &quot;Edit This Section&quot; option in the upper-right corner of each section allows users to modify and update their information. Users can also <u><b><a  style={{color:white,}} href="https://imaginescholarships.com/">contact us here</a></b></u> in order to request access to submitted information. To protect the security and integrity of our users&rsquo; information, we will take necessary steps in verifying all new profile identities before providing access. It is the responsibility of the user to make all necessary changes in their profile and make sure their profiles are up-to-date.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Users of Imagine Scholarships who wish to terminate their membership, or otherwise request to cease usage of their information at Imagine Scholarships may do so at any time by contacting us via our contact form by <u><b><a  style={{color:white,}} aria-label="Redirect to login page" href="https://imaginescholarships.com/login/" target="Website Content">clicking here for Login</a></b></u>. Please be sure to type &quot;Remove&quot; in the subject field and submit the email account associated with the membership you would like terminated. We will respond to your request within a reasonable timeframe. Information will be retained for as long as the account is active or requests our services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships is committed to safeguarding users&apos; privacy on our website. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once it is received. We require our employees to protect our users and visitors by keeping their profile information private, and expect our marketing partners to follow suit. Rest assured that your user profile will be protected when you visit Imagine Scholarships. There are security measures to protect against the loss, misuse and alteration of all user information under our control.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Regardless of whether you wish to share your personal information, we reserve the right to share aggregated demographic data and related information with our business partners. Such aggregate information will not contain any personal information that can identify any individual user.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Information Sharing &amp; Email Preferences</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships understands the importance of protecting personally-identifiable information. We will not share, sell, rent or otherwise transfer any information without the user&apos;s permission, and then, only in accordance with the terms set forth in this Privacy Policy. Our website provides users the opportunity to unsubscribe/opt-out from receiving email messages from Imagine Scholarships and its marketing partners, or from having their personal information shared with our marketing partners. Users may change their email preferences by following the unsubscribe instructions contained in each of the email messages they receive or by <u><b><a  style={{color:white,}} href="https://imaginescholarships.com/" aria-label="Redirect to home page" target="Unsubscribe">clicking here</a></b></u>. Please allow up to seven business days for these changes to take effect. If you no longer wish to be a registered user of Imagine Scholarships, you may have your profile removed by <u><b><a  style={{color:white,}} href="https://imaginescholarships.com/" aria-label="Redirect to Home page" target="Email Unsubscribe">clicking here</a></b></u>.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships may periodically use contact information in order to send emails regarding updates at the Imagine Scholarships site, such as scholarship opportunities and additional listings. The frequency of these messages will vary depending on the user&apos;s preference, including the types of scholarships and other activities in which a user has indicated an interest. If you specifically provide Imagine Scholarships with permission at the time of registration, you may also receive commercial emails. Please note that email messages from third parties are not governed by the opt-out provisions of this Privacy Policy.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">In certain situations, Imagine Scholarships may be required to disclose personal data in response to lawful requests by public authorities. We may also disclose your personal information as required by law, such as to comply with a subpoena, or similar legal process. When we believe that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">We may provide your personal information to company provider services that help us with business-related activity; such as shipping your order or offering customer service. These companies are authorized to use your personal information only on a service-needs basis.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>California Consumer Privacy Act (CCPA)</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships is working in full cooperation with the California Consumer Privacy Act (CCPA). If you&rsquo;ve reviewed Imagine Scholarships&rsquo; Privacy Policy, you know you have certain rights regarding your personal information. Imagine Scholarships collects and shares data with our marketing partners in an effort to maintain our free site and services. The recently enacted California Consumer Privacy Act (CCPA) offers California residents the right to opt-out of the sale of their personal information and you can do that by, <u><b><a  style={{color:white,}} aria-label="Redirect to home page" href="https://imaginescholarships.com/">clicking here</a></b></u>.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships shares your information with a variety of third-party entities. Among the myriad benefits of using Imagine Scholarships is your ability to interact with scholarship providers and also to be recruited by colleges and universities and others related to the college admissions and financial aid process. When you opt to interact with these third-parties, our website passes your information along to them so that they are able to provide more information to you about their scholarship, financial aid, institutional and other programs and offerings.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">On occasion, Imagine Scholarships may also provide your personal information to advertisers and other marketing partners who are interested in marketing to your specific demographic in exchange for fees or other consideration. If you do not want us to sell your information as defined by CCPA, we encourage you to opt-out of such sales of your information by completing the form below. Also, if you are a California resident under 16 years of age, we will not sell your information as defined by CCPA unless you opt-in at the time you create a Imagine Scholarships account or at another time that you choose to allow us to sell your information.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Collection of Passive Information</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">As is true of most websites, we gather certain information automatically. This information may include Internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">We, Imagine Scholarships, and our partners use cookies or similar technologies to analyze trends, administer the website, track users&rsquo; movements around the website, and to gather demographic information about our user base as a whole. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or service.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Some of our business partners, such as advertisers, use cookies on our site, but we have neither access to, nor control over, these cookies. This Privacy Policy covers the use of cookies by Imagine Scholarships only and does not cover the use of cookies by our business partners</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">We partner with a third party ad network to display advertising on our web site. Our ad network partner uses cookies and web beacons to collect non-personally identifiable information about your activities on this and other web sites to provide you targeted advertising based upon your interests. If you wish to not have this information used for targeting ads, you may opt-out by <u><b><a  style={{color:white,}} href="http://preferences-mgr.truste.com/" aria-label="Redirect To preferences-mgr.truste.com Page" target="_blank">clicking here</a></b></u>. Please note this does not opt you out of being served advertising. You will continue to receive generic ads.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">We use Google AdSense to publish ads on this site. When you view or click on an ad a cookie will be set to help better provide advertisements that may be of interest to you on this and other web sites. You may opt-out of the use of this cookie by visiting Google&rsquo;s Advertising and Privacy page: <u><b><a  style={{color:white,}} href="http://www.google.com/privacy_ads.html" target="_blank">http://www.google.com/privacy_ads.html</a></b></u>.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">We may share statistical and demographic information about our website&apos;s visitors with our marketing partners. This information is only provided in the aggregate; meaning that the information is about our website&apos;s visitors as a group and not about individually-identifiable visitors.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Public Forums</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Our Web site offers publicly accessible blogs or community forums. Please be aware that any information you provide in these areas may be read, collected, and used by others. To request removal of your personal information from our blog or community forum, you may contact us by <u><b><a  style={{color:white,}} href="https://imaginescholarships.com/" aria-labelledby="redirectToHome4" target="Account Updates & Questions"><u><b><label id="redirectToHome4" for="Redirect To Home Page">clicking here</label></b></u></a></b></u>. In some cases, we may not be able to remove your personal information. In these situations, we will provide you with an explanation.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Social Media Features</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Our Web site includes Social Media Features, such as the Facebook Like button. These Features may collect your IP address, page visitation on the site, and may set a cookie to enable the Feature to function properly. Social Media Features are either hosted by a third party or hosted directly on our site. Your interactions with these Features are governed by the privacy policy of the company providing them.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Links to Other Websites</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships contains text and banner links to other sites. Imagine Scholarships does not exercise control over the information, products, services or policies of third party companies accessible through our site. This Privacy Policy applies solely to the information collected by this site</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Collection of Information from Children</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships abides by federal law and the guidelines set up by the Federal Trade Commission regarding youth privacy. As such, Imagine Scholarships will not knowingly collect any personally-identifiable information from children under the age of 13, nor will children under the age of 13 be allowed to register with Imagine Scholarships.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Notification of Changes</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">If we are going to use your personally identifiable information in a manner different from that stated at the time of collection, we will notify you via email prior to the effective change. You may decide whether or not we can use your information in this manner. In addition, if we make any material changes to our Privacy Policy that do not affect user information already stored in our database, we will post a prominent notice on our website notifying users of the change. In cases where we post notice of changes, we may also email users who have opted to receive communications from us, notifying them of the changes to our privacy practices.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">In the event that Imagine Scholarships sells, signs or transfers some or all of its business to a successor or acquirer, Imagine Scholarships may sell, assign or transfer all of your information, regardless of your opt status, to such successor or acquirer.</p>
<h2 className='margin-top: 0.42cm;margin-bottom: 0.11cm;line-height: 100%;text-align: justify;font-family: "Times New Roman", serif;font-size:19px;font-className: italic;background: #ffffff;'>Testimonials</h2>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">We post user testimonials on our website, all of which are volunteered and none of which contain individually-identifiable information beyond the user&apos;s first name, last initial, and photograph. Each user is made aware of the purpose of their supplied testimonial that it will be published on Imagine Scholarships. Users are under no obligation to participate, and we obtain the user&apos;s consent prior to posting this information. If you have any questions about a testimonial you can contact us by <u><b><a  style={{color:white,}} href="https://imaginescholarships.com/" aria-label="Redirect to Home page" target="Website Content & Membership">clicking here</a></b></u>.</p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">Imagine Scholarships has adopted privacy principles that state our commitment and define policy on safeguarding visitor and user privacy when conducting business. Questions regarding this Privacy Policy statement, the practices of this website, or dealings with Imagine Scholarships should be directed to Imagine Scholarships&apos;&nbsp;at:</p>
<p className="margin-bottom: 0cm;line-height: 100%;text-align: justify;background: #ffffff;"><br /></p>
<p className="margin-bottom: 0cm;line-height: 100%;text-align: justify;background: #ffffff;"><em>Privacy at Imagine Scholarships</em></p>
<p className="margin-bottom: 0cm;line-height: 100%;text-align: justify;background: #ffffff;"><em>c/o Gerald Winters<br />&nbsp;1000 Heritage Center Circle</em></p>
<p className="margin-bottom: 0cm;line-height: 100%;text-align: justify;background: #ffffff;"><em>Round Rock, TX 78664</em></p>
<p className="margin-bottom: 0.49cm;line-height: 100%;text-align: justify;margin-top: 0.49cm;background: #ffffff;">If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge) at<br /><u><b><a  style={{color:white,}} href="https://feedback-form.truste.com/watchdog/request">https://feedback-form.truste.com/watchdog/request</a></b></u></p>
<p className='margin-bottom: 0.49cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-top: 0.49cm;background: #ffffff;'><br /><br /></p>
<p className='margin-bottom: 0.49cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-top: 0.49cm;'><br /><br /></p>
<p className='margin-bottom: 0.49cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;margin-top: 0.49cm;background: #ffffff;'><br /><br /></p>
<p className='margin-bottom: 0cm;line-height: 90%;text-align: justify;font-family: "Times New Roman", serif;font-size:16px;'><br /></p>
        </div>
          </Col> 
       
        </Row>
        </Container>
      </div> 
 </main>
          <InnerFooter/>
        </div>
      );
    }
}
export default groupRegistration;