/* _________________________________________________
  |                                                 |
  |             Account Recovery                    |
  |_________________________________________________|
                                                                */

import React, { Component } from 'react';
import InnerHeader from './layout/InnerHeader';
import InnerFooter from './layout/InnerFooter';
import { Redirect, browserHistory,router } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';
import 'bootstrap/dist/css/bootstrap.css';
import {PageLoader,SmallPageLoader} from './loaders/PageLoader'; // Page Loader
import {Container, Row, Col, Navbar, Nav, Tab, Tabs, Button, InputGroup, FormControl, Form, Modal, Popover, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";
import WOW from "wowjs";
import {postApiData,getApiData} from '../services/Api';
import ReactPasswordStrength from 'react-password-strength';

// default Base Urls
import config from 'react-global-configuration';

const history = createBrowserHistory();

class AccountRecovery extends Component {
  
  constructor(props){

    super(props);
    
    this.state={
      redirect: false,
      loggedin:false,
      error :"",
      success : "",
      post_str: this.props.match.params.id,

    }
    
  }

  /**
   * On Click validate login.
   *
   * @return status
  */
  handleClick(event,username){

    event.preventDefault();

    // Validation check
    const form = event.currentTarget;
    if (form.checkValidity() === false) {event.stopPropagation();}
    
    // Confirm password validation check
    if(this.state.password != this.state.cfrm_pass){

      event.preventDefault();
      event.stopPropagation();
      this.setState({ error: "Passwords do not match! Please try again." }, function () {
      console.log(this.state.registerError);
      });
      return false;
       
    }else{
      
      if(this.state.password.length < 6){
        event.preventDefault();
        event.stopPropagation();
        this.setState({ error: "Passwords length must be at least 6." }, function () {
        console.log(this.state.registerError);});
        return false;
      }else{
        this.setState({error:""});
        //this.setState({ rValidated: true });
      }
    }

    // Set Var
    var self = this;
    let currentComponent = this;
    let where =[{url:"/reset-password"}]; // Service param
    var formData = new FormData(); // Set post data
    formData.append('post_str',this.state.post_str);
    formData.append('password',this.state.password);

    currentComponent.setState({ loginloaderDiv: true });
    // Post parameters on service URL...
    postApiData(where,formData,true).then(response => {
      if(response.status === 200 && response.data.success==true)
	  {
        self.setState({ success: response.data.msg });
        self.setState({ loginloaderDiv: false });
      }else{
        self.setState({ success: response.data.msg });
        self.setState({ loginloaderDiv: false });
      }
    })
    .catch(function (error) {
      self.setState({ error: "Please enter your password again." }); // Set redirect status
      self.setState({ loginloaderDiv: false });
    });

  }
 
  async openNav() {
  document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
  document.getElementById("myNav").style.width = "0%";
  }
  
  /* Handle Blank Spaces */
  handleChange = event => {
    let eleVal = event.target.value.trim();
    if(eleVal==""){document.getElementById(event.target.id).value = eleVal;}
    this.setState({[event.target.id]: eleVal});
  }

  /* Set password state */
  changeCallback = state => {
    this.setState({'password': state.password});
    this.setState({ passLength: state.password.length })
  }

  componentDidMount(){
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }

  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {

    const handleHide = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    const { validated } = this.state;

    return (
      <div style={{background:'url(/login-bg.jpg) no-repeat', backgroundSize:'cover', paddingBottom:'1px'}}>
      <InnerHeader/>
      <div  style={{minHeight:'500px',paddingTop:'100px'}}>
        <div className="login-section">
          <Tabs>
            <Tab eventKey="login" title="Reset account password">
            {this.state.success!==''?(<div className="alert alert-success"><strong>{this.state.success}</strong> <a href="/login">Click here</a> to login.</div>):
            <Form method="post" id="login" name="login" validated={validated} onSubmit={(event) => this.handleClick(event,this.state.username)}>
              {this.state.error!==''?(<div className="alert alert-danger"><strong>{this.state.error}</strong></div>):(null)}
                <Form.Group controlId="password">
                  <Form.Label>Password<sup>*</sup></Form.Label>       
                    <ReactPasswordStrength
                    className=""
                    minLength={5}
                    minScore={2}
                    scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                    changeCallback={this.changeCallback}
                    required
                    inputProps={{placeholder :"Enter Your new password", name: "Password",id: "password", autoComplete: "off", className: "form-control", required : "required" }}
                    />
                  {/*<Form.Control type="password" placeholder="Password" id="password" required onChange={this.handleChange} />*/}
                </Form.Group>
                <Form.Group controlId="username">  
                <Form.Label>Re-type Password<sup>*</sup></Form.Label>              
                  <Form.Control type="password" placeholder="Re-Enter Your new password" id="cfrm_pass" autocomplete="off" required onChange={this.handleChange} />                
                </Form.Group>
                <div style={{'padding-bottom':'9%'}}>
                <div style={{'float':'left'}}>
                <Button variant="primary" type="submit">
                  Continue
                </Button>
                </div>
                <div>
                {this.state.loginloaderDiv?(<div style={{textAlign:"center",'float':'left'}} ref="loaderdiv">
                <SmallPageLoader/>  
                </div>):""}
                </div>
                </div>
            </Form>
            }
            </Tab>
           </Tabs>
        </div>
      </div>
      <InnerFooter/>
      </div>
    );
  }
}

const style = {
 margin: 15,
};

export default AccountRecovery;