import React, { Component } from 'react';
import { Slot } from 'react-page-layout';
import {Link} from "react-router-dom";
import {reactLocalStorage} from 'reactjs-localstorage';
import {Container, Row, Col, Navbar, Nav, NavDropdown, Button, InputGroup, FormControl, Form} from "react-bootstrap";
import WOW from "wowjs";
import config from 'react-global-configuration';
import '../../nav.css';

class InnerHeader extends Component {

  constructor(props){
    super(props);
    this.state={
      user_id:reactLocalStorage.get('userId'),
      password:'',
      loggedin:reactLocalStorage.get('loggedin'),
      first_name:reactLocalStorage.get('firstName'),
      administratorLoginUrl: config.get('imgUrl'),
      groupLoginUrl: config.get('imgUrl')+'group',
      
    }
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
      document.getElementById("myNav").style.width = "0%";
    }
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }



  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
   new WOW.WOW().init();
   window.addEventListener('scroll', () => {
       let activeClass = 'normal';
       if(window.scrollY === 0){
           activeClass = 'top';
       }
       this.setState({ activeClass });
    });
  }
    
  myFunction() {
    var y = document.getElementById("searchFilter");
    if (y.style.display === "block") {
      y.style.display = "none";
    } else {
      y.style.display = "block";
    }
  }
      
  async openNav() {
    document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }


  render() {

    if(this.state.loggedin==='true')
    {
    return (
      <div>
          <header className={`top-bar ${this.state.activeClass}`}>
            <Navbar expand="xl" >
            <Navbar.Brand href="/" ><img  src="/logo.png" alt="imagine Scholarships Logo" height="100px"/>{/*<img src="../assets/img/logo.svg"/>*/}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" >
              <Nav >
                <Nav.Link href="https://www.baylor.edu/" target="_blank">HOME</Nav.Link> 
                <a href="http://www.imaginecollegecoaching.com/contact-form-1">Contact</a>
                <a href="#">FAQ</a>
                <Nav.Link href="/matched-scholarships">MATCHED SCHOLARSHIPS</Nav.Link> 
                <Nav.Link href="/saved-scholarships">SAVED SCHOLARSHIPS</Nav.Link>
                <Nav.Link href="/applied-scholarships">APPLIED SCHOLARSHIPS</Nav.Link>
                <Nav.Link href="/interships">INTERNSHIPS</Nav.Link>
                {/* <Nav.Link href="/membership">MANAGE SUBSCRIPTION</Nav.Link> */}
                <Nav.Link href="/profile">PROFILE</Nav.Link>
                <Nav.Link href="/logout">LOGOUT</Nav.Link>         
              </Nav>      
            </Navbar.Collapse>
            <div className="ml-auto search-section">
            <Nav  >
          
                <Nav.Link  tabIndex="-1" onFocus={this.openNav} onClick={this.openNav}><img  alt="Menu" src="/assets/img/right-nav.svg"/></Nav.Link>
            </Nav>    
            </div>
            </Navbar>     
          </header>

          <div id="searchFilter" style={{display:'none'}}>
            <Form>
            <label id="searchLabel" for="Search"> Search</label>
              <FormControl type="text" placeholder="Search"  aria-labelledby="searchLabel" />          
            </Form>
          </div>

          <div id="myNav" class="topnav" > 
            <a href="javascript:void(0)" class="closebtn" onClick={this.closeNav}>&times;</a>
            <div class="nav-content">
            <a href="/" target="_blank">Home</a>
            <a href="https://www.imaginecollegecoaching.com/baylor-contact-page" target="_blank">Contact</a>
            <a href="https://www.imaginecollegecoaching.com/frequently-asked-questions"  target="_blank">FAQ</a>
            <a href="/matched-scholarships">MATCHED SCHOLARSHIPS</a>
            <a href="/saved-scholarships">SAVED SCHOLARSHIPS</a>
            <a href="/applied-scholarships">APPLIED SCHOLARSHIPS</a>
            <a href="/interships">INTERNSHIPS</a> 
            <a href="/profile">PROFILE</a>
            <a  onBlur={this.closeNav} href="/logout">LOGOUT</a>
            </div>
          </div>
    </div>

      );
    }
    else
    {
      return (

        <div>
          <header className={`top-bar ${this.state.activeClass}`}>
            <Navbar expand="xl"   tabIndex="-1" >
            <Navbar.Brand href="/"><img src="/logo.png" alt="Imagine Shcolarship Logo" height="100px"/>{/*<img src="../assets/img/logo.svg"/>*/}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav1" />
            <Navbar.Collapse id="basic-navbar-nav1" >
              <Nav >
                <Nav.Link href="/" >HOME</Nav.Link>
                <Nav.Link href="/scholarships">SCHOLARSHIPS</Nav.Link> 
                <Nav.Link href="/login">LOGIN</Nav.Link>               
              </Nav>      
            </Navbar.Collapse>
            <div className="ml-auto search-section">
            <Nav >
                <Nav.Link onClick={this.myFunction}><img alt="search" src="/assets/img/search.svg"/></Nav.Link>
                <Nav.Link onFocus={this.openNav}  onClick={this.openNav}><img alt="Menu" src="/assets/img/right-nav.svg"/></Nav.Link>
            </Nav>    
            </div>
            </Navbar>     
          </header>

          {/* <div id="searchFilter" style={{display:'none'}}>
            <Form>
            <label id="searchLabel" for="Search"> Search</label>  <FormControl aria-labelledby="searchLabel" type="text" placeholder="Search" />          
            </Form>
          </div> */}

          <div id="myNav" class="topnav"  tabIndex="-1"> 
            <a href="javascript:void(0)" class="closebtn" onClick={this.closeNav}>&times;</a>
            <div class="nav-content">
            <a href="/" target="_blank">Home</a>
            <a href="https://www.imaginecollegecoaching.com/baylor-contact-page">Contact</a>
            <a href="https://www.imaginecollegecoaching.com/frequently-asked-questions" >FAQ</a>
            <a href="/scholarships">GET STARTED</a>
          
            <a href="/login">LOGIN / SIGNUP</a>
				 <a  onBlur={this.closeNav} href="https://api.imaginescholarships.com/imagine-scholarships/group" target="_blank">Group Administrator</a>
            </div>
          </div>
    </div>
      );
    }
  }
}

export default InnerHeader;