/*
  _____________________
 |                     |
 |      Lead Form      |
 |_____________________|
                        */
// Initial Lib
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Include Component
import ScholarshipHeader from './layout/scholarshipHeader';
import ScholarshipPromo from './layout/scholarshipPromo';
import InnerFooter from './layout/InnerFooter';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Pagination, Tab, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import Modal from 'react-responsive-modal';
import WOW from "wowjs";
import {PageLoader, SmallPageLoader} from './loaders/PageLoader'; // Page Loader

// Service lib
import {postApiData,getApiData} from '../services/Api';

class PersonalInfo extends Component {
    
  constructor(props){
    super(props);
    this.state={
      states: [],
	  clubs : [],
	  citizenships : [],
	  religions : [],
	  grades : [],
	  majors : [],
	  user_majors : [],
      races : [],
      user_races : [],
	  interestList: [],
	  errorMsg : '',  
      validatedOne: false,
      redirect: false, // Redirect var
      email: localStorage.getItem('email'),
      firstName: localStorage.getItem('firstName'),
      lastName: localStorage.getItem('lastName'),  
      userId: localStorage.getItem('userId'),    
      schoolingStandard : localStorage.getItem('schooling_standard'),
      submitBtnText: 'Find My Matches',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.phoneNumber = this.phoneNumber.bind(this);
    this.floatNumber = this.floatNumber.bind(this);
    
  }

  /**
   * Set FORM DROP DOWN values.
   *
   * @Set values
  */
  setFormElements() {
    // Set post data
    let where =[{url:"/lead-form-options"}]; // Service param
    var self = this;

    // Post parameters on service URL
    getApiData(where).then(response => {
      self.setState({ states: response.data.states,
                      races: response.data.races,
					  clubs: response.data.clubs,
					  citizenships: response.data.citizenships,
					  religions: response.data.religions,
					  majors: response.data.majors,
					  interestList: response.data.interests
      }); // Set redirect status
	  
	  if(self.state.schoolingStandard==1)
	  {
		self.setState({ grades: response.data.grades.high_school }); 
	  }
	  else
	  {
		self.setState({ grades: response.data.grades.graduate }); 
	  }
    })
    .catch(function (error) {
    });

  }

  /**
   * On Submit Form.
   *
   * @return status
  */
  handleSubmit(event) {
	  
    // Stop default action
    event.preventDefault();
	
    var submitElm = document.getElementById('submit');
    submitElm.disabled = true;
    this.setState({ submitBtnText: "Processing..." });	
	
    // Validation check
    const form = event.currentTarget;

    if (form.checkValidity() === false) {event.stopPropagation();}
    this.setState({ validatedOne: true }); // Set validated

    // Set Var
    var self = this;
    const data = new FormData(event.target); // Form Data
	
    let where =[{url:"/add-personalinfo"}]; // Set post data

    // Post parameters on service URL
    postApiData(where,data,true).then(response => {
        if(response.status == 200 && response.data.success==true)
	    {	
		    localStorage.setItem('profile_status', 1);
		    self.setState({ redirect: true });
			if(this.detectmob()){
			window.scrollTo(1300, 1300); // Page Set to form start On Mobile
			}else{
			window.scrollTo(850, 850); // Page Set to form start
			}        
        }
		else
		{
		  self.setState({ errorMsg: response.data.msg });
		}
	    submitElm.disabled = false;
    })
    .catch(function (error) {
		submitElm.disabled = false;
    });
  }

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount(){
    new WOW.WOW().init();
    this.setFormElements();
    window.scrollTo(0, 0); // Page Set to form start
    
  }

  /**
   * Display login UI.
   *
   * @return Response
  */
 openRaceModal = () => {
  this.setState({ racePopup: true });
  };

  closeRaceModal = () => {
    this.setState({ racePopup: false });
  }
  
  openMajorModal = () => {
    this.setState({ majorPopup: true });
  };

  closeMajorModal = () => {
    this.setState({ majorPopup: false });
  }

  /**
   * Check If opened from Mobile
   * 
  */
  detectmob() { 
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
       return true;
     }
    else {
       return false;
     }
   }

  /*Float number validation*/
  floatNumber = (e) => {
    
    if(isNaN(e.target.value)){
      e.preventDefault();
      document.getElementById(e.target.id).value = "";
      return false;
    }else{
      document.getElementById(e.target.id).value = e.target.value;
      this.setState({[e.target.id]: e.target.value});
    }
  }

  /*Float number validation*/
  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value});
  }

  /*Phone number validation*/
  phoneNumber = (e) => {
    
    var phone = e.target.value; // get values
    var key = e.charCode || e.keyCode || 0; // get Key code

    if (key !== 8 && key !== 9) { 
        if (phone.length === 3) {
          phone = phone + '-';
        }
        if (phone.length === 7) {
          phone = phone + '-';
        }
    }

    if((key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57))){ // If valid keys
    document.getElementById(e.target.id).value = phone;
    this.setState({[e.target.id]: phone});
    console.log(e.keyCode);
    }else{
    e.preventDefault();
    }

  }

  async openNav() {
    document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }

  handleAddRaceElm = param => event => {
    const elmId = event.target.value;
    const found = this.state.user_races.some(elm => elm.id == elmId);
    const index = parseInt(this.state.user_races.length) + 1;
    
    if(found==false)
    {
      this.state.user_races.push({id: elmId, race_name: param[0] });       
    } 
    else
    {
      this.state.user_races.splice(this.state.user_races.findIndex(item => item.id == elmId), 1);
    }

    /* sort races */
    var new_races = this.state.user_races.sort(function(a, b) { 
      var x = a.race_name; var y = b.race_name;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    this.setState({user_races: new_races});
  }
  
  handleAddMajorElm = param => event => {
    const elmId = event.target.value;
    const found = this.state.user_majors.some(elm => elm.id == elmId);
    const index = this.state.user_majors.length + 1;
    if(!found)
    {
      this.state.user_majors.push({id: elmId, major_name: param[0] });       
    } 
    else
    {
      this.state.user_majors.splice( this.state.user_majors.findIndex(item => item.id == elmId), 1);
    }

    /* sort majors */
    var new_majors = this.state.user_majors.sort(function(a, b) { 
      var x = a.major_name; var y = b.major_name;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });

    this.setState({user_majors: new_majors});
  }

  openRacePopup = (event) => {    
    event.preventDefault();    
    this.openRaceModal();
  }
  
  openMajorPopup = (event) => {    
    event.preventDefault();    
    this.openMajorModal();
  }
  
  render() {
    const { validatedOne } = this.state;
    
    const { racePopup } = this.state;
	
	const { majorPopup } = this.state;
	
    // if profile not completed then redirect schooling standard
    if(reactLocalStorage.get('schooling_standard') <=0)
    {
      return <Redirect to='/scholarships'/>;
    }
	
    // Redirection
    if (!this.state.userId) {
      return <Redirect to='/login'/>;
    }
	
	  // Redirection
    if (this.state.redirect) {
      return <Redirect to='/matched-scholarships'/>;
    }

    // Set State options
    let stateOptions = [this.state.states.map((item,i) => {
    return <option value={item.id}>{item.state_name}</option>;
    })];
	
	// Set Club options
    let clubOptions = [this.state.clubs.map((item,i) => {
    return <option value={item.id}>{item.club_name}</option>;
    })];
	
	// Set Citizenship options
    let citizenshipOptions = [this.state.citizenships.map((item,i) => {
    return <option value={item}>{item}</option>;
    })];
	
	// Set Religion options
	let religionOptions = [this.state.religions.map((item,i) => {
    return <option value={item}>{item}</option>;
    })];
	
	// Set Grade / Education Level options (high school and graduate level)
	let gradeOptions = [this.state.grades.map((item,i) => {
	return <option value={item}>{item}</option>;
	})];
	
	// Set Interests options 
	let interestOptions = [this.state.interestList.map((item,i) => {
	return <option value={item}>{item}</option>;
	})];

    // Create Check boxes for Race Popup
	const self = this;
	const createPopupRaceList = [this.state.races.map((item,i) => {
	return (
			  ['checkbox'].map(type => (
				  <div key={`race-list-${type}`}>
					<Form.Check 
					  custom
					  type={type}
					  name = "race_list[]"
					  id={`race-list-${type}-${i+1}`}
					  label={item.race_name}
					  checked={this.state.user_races.some(elm => elm.id == item.id)}
					  value={item.id}
					  onClick={self.handleAddRaceElm([item.race_name])}
					/>              
				  </div>
			))
		  )
	})];

	// Create Check boxes for Race Container
	const createRaceElms = [this.state.user_races.map((item,i) => {
	return (
			  ['checkbox'].map(type => (
				  <div key={`race-${type}`} onClick={self.openRacePopup}>
					<Form.Check 
					  custom
					  type={type}
					  name ="race[]"
					  value={item.id}
					  id={`race-${type}-${i+1}`}
					  label={item.race_name}
					  defaultChecked={`true`}
					/>              
				  </div>
			))
		  )
	})];
	
	// Create Check boxes for Major / Field of Study Popup
	const createPopupMajorList = [this.state.majors.map((item,i) => {
	return (
			  ['checkbox'].map(type => (
				  <div key={`major-list-${type}`}>
					<Form.Check 
					  custom
					  type={type}
					  name = "major_list[]"
					  id={`major-list-${type}-${i+1}`}
					  label={item.major_name}
					  checked={this.state.user_majors.some(elm => elm.id == item.id)}
					  value={item.id}
					  onClick={self.handleAddMajorElm([item.major_name])}
					/>              
				  </div>
			))
		  )
	})];
	
	// Create Check boxes for Major / Field Container
	const createMajorElms = [this.state.user_majors.map((item,i) => {
	return (
			  ['checkbox'].map(type => (
				  <div key={`major-${type}`} onClick={self.openMajorPopup}>
					<Form.Check 
					  custom
					  type={type}
					  name ="major[]"
					  value={item.id}
					  id={`major-${type}-${i+1}`}
					  label={item.major_name}
					  defaultChecked={`true`}
					/>              
				  </div>
			))
		  )
	})];

    return (
        <div>
          <ScholarshipHeader/>
          <Container className="personal-info-section" style={{'padding': '27px'}}>
            <Tab.Container id="left-tabs-example" defaultActiveKey='first' activeKey={this.state.activeKey}>				
                <Row>
                  <Col sm={3}>&nbsp;</Col>
                  <Col sm={7} className="info-form">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        {this.state.errorMsg!==''?(<div className="alert alert-danger"><strong>{this.state.errorMsg}</strong></div>):(null)}
                        <h3>Personal Information</h3>
						<h5 style={{ fontSize:'16px' }}>Why do we ask for this information? The more details we know the better the chance of finding great scholarships for you !</h5>
                        <Form action="" validated={validatedOne} method="post" id="frmPersonalInfo" autocomplete="off" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>First Name<sup>*</sup></Form.Label>
                                <Form.Control type="text" name="first_name" id="first_name" defaultValue={this.state.firstName ? this.state.firstName : this.state.first_name} onChange={this.handleChange} required />
                              </Form.Group>
                            </Col>                    
                            <Col md={6}>
                              <Form.Group >
                                <Form.Label>Last Name<sup>*</sup> </Form.Label>
                                <Form.Control type="text" name="last_name" id="last_name" defaultValue={this.state.lastName ? this.state.lastName : this.state.last_name} onChange={this.handleChange} required/>
                              </Form.Group>
                            </Col>
                          </Row>
						  <Row>
                            <Col md={6}>
                              <Form.Group >
                                <Form.Label>Email<sup>*</sup></Form.Label>
                                <Form.Control type="email" name="email" id="email" value={this.state.email ? this.state.email : this.state.email } onChange={this.handleChange} readOnly required/>
                              </Form.Group>
                            </Col> 

                            <Col md={6}>
                            <Form.Group >
                              <Form.Label>Parent Email #1<sup>*</sup></Form.Label>
                              <Form.Control type="email" name="parentEmail1" id="parentEmail1" value={this.state.parentEmail1 ? this.state.parentEmail1 : this.state.parentEmail1 } onChange={this.handleChange}  required/>
                            </Form.Group>
                            </Col>  

                            <Col md={6}>
                            <Form.Group >
                              <Form.Label>Parent Email #2</Form.Label>
                              <Form.Control type="email" name="parentEmail2" id="parentEmail2" value={this.state.parentEmail2 ? this.state.parentEmail2 : this.state.parentEmail2 } onChange={this.handleChange}  />
                            </Form.Group>
                            </Col>  


                            <Col md={6}>
                              <Form.Group>
								<Form.Label>You are a<sup>*</sup></Form.Label>&nbsp;
								<span>
									{['right'].map(placement => (
									<OverlayTrigger
									  key={placement}
									  placement={placement}
									  overlay={
									  <Tooltip id={`tooltip-${placement}`}>
										If you opt 'Student' you have to fill your own information. If you opt 'Parent' then please fill out information based on student.
									  </Tooltip>
									  }
									>
									  <Button variant="secondary" className="info-btn">?</Button>
									</OverlayTrigger>
									))}
								</span>								
								{['radio'].map(type => (
								<div key={`custom-inline-${type}`} className="mb-3">
								  <Form.Check
									custom
									inline     
									value="Student"   
									type={type}
									name="registered_as"
									label={`Student`}
									id={`custom-inline-${type}-1`}
									required
								  />
								  <Form.Check
									custom
									type={type}
									value="Parent"
									name="registered_as"
									label={`Parent`}
									id={`custom-inline-${type}-2`}
									required
								  />
								</div>
								  ))}  
							  </Form.Group>                             
                            </Col>
                          </Row>						  
                          <Row>
                            <Col md={6}>
                              <Form.Group >
                                <Form.Label>City / Town<sup>*</sup> </Form.Label>&nbsp;
                                <span>
                                  {['right'].map(placement => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                      Eligibility for many scholarships in our database is based upon the applicant's city or town. Please supply your city or town for accurate results.
                                    </Tooltip>
                                    }
                                  >
                                    <Button variant="secondary" className="info-btn">?</Button>
                                  </OverlayTrigger>
                                  ))}
                                </span>
                                <Form.Control type="text" name="town" id="town" value={this.state.town} onChange={this.handleChange} required maxLength="255" />
                              </Form.Group>
                            </Col>                            
                            <Col md={6}>
                              <Form.Group >
                                <Form.Label>State<sup>*</sup> </Form.Label>&nbsp;
                                  <span>
                                    {['right'].map(placement => (
                                    <OverlayTrigger
                                      key={placement}
                                      placement={placement}
                                      overlay={
                                      <Tooltip id={`tooltip-${placement}`}>
                                        Eligibility for many scholarships in our database is based upon the applicant's state. Please supply your state for accurate results.
                                      </Tooltip>
                                      }
                                    >
                                      <Button variant="secondary" className="info-btn">?</Button>
                                    </OverlayTrigger>
                                    ))}
                                  </span>								  
                                  <Form.Control as="select" name="state" id="state" onChange={this.handleChange} required>
                                    <option value="">----Select----</option>
                                    {stateOptions}
                                  </Form.Control>
                              </Form.Group>
                            </Col>                            
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Form.Group >
                                <Form.Label>Zip Code<sup>*</sup></Form.Label>
                                <Form.Control type="text" name="zip_code" id="zip_code" maxLength="5" value={this.state.zip_code} onChange={this.handleChange} required />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group >
                                <Form.Label>Country Living in<sup>*</sup></Form.Label>&nbsp;
                                <span>
                                  {['right'].map(placement => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
										Eligibility for many scholarships in our database is based upon the applicant's country. Please supply your country for accurate results.
                                    </Tooltip>
                                    }
                                  >
                                    <Button variant="secondary" className="info-btn">?</Button>
                                  </OverlayTrigger>
                                  ))}
                                </span>
                                  <Form.Control as="select" name="country" id="country" onChange={this.handleChange} required>
                                  <option value="">----Select----</option>
                                  <option value="USA">USA</option>
                                  <option value="Other">Other</option>
                                  </Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>                         
                            <Col md={6}>
                              <Form.Group >
								<Form.Label>Sex</Form.Label>
                                <Form.Control as="select" name="sex" id="sex" onChange={this.handleChange}>
                                  <option value="" >----Select----</option>
                                  <option value="M">Male</option>
                                  <option value="F">Female</option>
                                </Form.Control>
							  </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group >
                                <Form.Label>Citizenship Status<sup>*</sup></Form.Label>&nbsp;
                                <span>
                                  {['right'].map(placement => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                      Eligibility for many scholarships in our database is based upon the applicant's citizenship. Please supply your citizenship status for accurate results.
                                    </Tooltip>
                                    }
                                  >
                                    <Button variant="secondary" className="info-btn">?</Button>
                                  </OverlayTrigger>
                                  ))}
                                </span>
                                  <Form.Control as="select" name="citizenship_status" id="citizenship_status" onChange={this.handleChange} required >
									<option value="">----Select----</option>
									{citizenshipOptions}
                                  </Form.Control>
                              </Form.Group>
                            </Col>                            
                          </Row>
                          <Row>                            
                            <Col md={6}>
                              <Form.Group >
                                <Form.Label>Religion</Form.Label>
                                <Form.Control as="select" name="religion" id="religion" onChange={this.handleChange}>
									<option value="">----Select----</option>
									{religionOptions}
                                </Form.Control>
                              </Form.Group>
                            </Col>
							<Col md={6}>
                              <Form.Group className="race-panel">
                                <Form.Label>Race</Form.Label>&nbsp;
                                <span>
                                  {['right'].map(placement => (
                                  <OverlayTrigger
                                    key={placement}
                                    placement={placement}
                                    overlay={
                                    <Tooltip id={`tooltip-${placement}`}>
                                      Eligibility for many scholarships in our database is based upon the applicant's race.  Please supply your race for accurate results.
                                    </Tooltip>
                                    }
                                  >
                                    <Button variant="secondary" className="info-btn">?</Button>
                                  </OverlayTrigger>
                                  ))}
                                </span>
                                <br />	
                                <div className="checklist">{ this.state.user_races.length > 0 ?
                                  createRaceElms
                                  :
								  <span>
                                  <a className="btn" style={{"borderColor": "#010167"}}  onClick={this.openRaceModal} required>Select</a>
								  <span id="errorRace" style={{color: 'red'}}></span>
								  </span>
                                }	</div>
                              </Form.Group> 
                            </Col>
                          </Row>
						  
                          { /* Check for student's schooling standard of High School Student */
                            this.state.schoolingStandard==1 ?  
                              <div>                       
                                <Row>  
                                  <Col md={6}>
                                    <Form.Group >
                                      <Form.Label>Current Grade<sup>*</sup></Form.Label>&nbsp;
                                      <span>
                                        {['right'].map(placement => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Eligibility for many scholarships in our database is based upon the applicant's current grade.  Please supply your current grade for accurate results.
                                          </Tooltip>
                                          }
                                        >
                                          <Button variant="secondary" className="info-btn">?</Button>
                                        </OverlayTrigger>
                                        ))}
                                      </span>
                                      <Form.Control as="select" name="grade" id="grade" onChange={this.handleChange} required>
                                        <option value="">----Select----</option>
                                        {gradeOptions}
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group >
                                      <Form.Label>What school are you currently attending?</Form.Label>
                                      <Form.Control type="text" name="school_attending" id="school_attending" onChange={this.handleChange} value={this.state.school_attending} />
                                    </Form.Group>
                                  </Col>
                                </Row>
								
								<Row>  
                                  <Col md={6}>
                                    <Form.Group >
                                      <Form.Label>GPA</Form.Label>&nbsp;
                                      <span>
                                        {['right'].map(placement => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Eligibility for many scholarships in our database is based upon the applicant's GPA. Please supply your GPA for accurate results.
                                          </Tooltip>
                                          }
                                        >
                                          <Button variant="secondary" className="info-btn">?</Button>
                                        </OverlayTrigger>
                                        ))}
                                      </span>
                                      <Form.Control type="text" name="gpa" id="gpa" onChange={this.handleChange} value={this.state.gpa} />
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group >
                                      <Form.Label>Total SAT Score</Form.Label>&nbsp;
                                      <span>
                                        {['right'].map(placement => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Eligibility for many scholarships in our database is based upon the applicant's SAT score. Please supply your SAT score for accurate results.
                                          </Tooltip>
                                          }
                                        >
                                          <Button variant="secondary" className="info-btn">?</Button>
                                        </OverlayTrigger>
                                        ))}
                                      </span>
                                      <Form.Control type="text" name="sat_score" id="sat_score" onChange={this.handleChange} value={this.state.sat_score} />
                                    </Form.Group>
                                  </Col>
                                </Row>
								
                                <Row>  
                                  <Col md={6}>
                                    <Form.Group >
                                      <Form.Label>Total ACT Score</Form.Label>&nbsp;
                                      <span>
                                        {['right'].map(placement => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                          <Tooltip id={`tooltip-${placement}`}> 
                                            Eligibility for many scholarships in our database is based upon the applicant's ACT score. Please supply your ACT score for accurate results.
                                          </Tooltip>
                                          }
                                        >
                                          <Button variant="secondary" className="info-btn">?</Button>
                                        </OverlayTrigger>
                                        ))}
                                      </span>
                                      <Form.Control type="text" name="act_score" id="act_score" onChange={this.handleChange} value={this.state.act_score}/>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
									<Form.Label>Are you first gen college student?</Form.Label>&nbsp;
									<span>
										{['right'].map(placement => (
										<OverlayTrigger
										  key={placement}
										  placement={placement}
										  overlay={
										  <Tooltip id={`tooltip-${placement}`}>
											If neither of your parents graduated from college, you may qualify for special programs and scholarships.
										  </Tooltip>
										  }
										>
										  <Button variant="secondary" className="info-btn">?</Button>
										</OverlayTrigger>
										))}
									  </span>
                                    <Form.Control as="select" name="is_first_gen_student" id="is_first_gen_student" onChange={this.handleChange}>
                                      <option value="">----Select----</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </Form.Control>
                                  </Col>
                                </Row>
                                <Row>  
                                  <Col md={6}>
                                    <Form.Label>College Type</Form.Label>&nbsp;
                                    <span>
                                      {['right'].map(placement => (
                                      <OverlayTrigger
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          Select the type of your college.
                                        </Tooltip>
                                        }
                                      >
                                        <Button variant="secondary" className="info-btn">?</Button>
                                      </OverlayTrigger>
                                      ))}
                                    </span>
                                    <Form.Control as="select" name="college_type" id="college_type" onChange={this.handleChange}>
                                      <option value="">----Select----</option>
                                      <option value="Online">Online</option>
                                      <option value="Campus">Campus</option>
                                    </Form.Control>
                                  </Col>
                                </Row>
                                <div className="clear">&nbsp;</div>
								<Row>  
									<Col md={6}>
										<Form.Label>What do you want to study in college?</Form.Label>&nbsp;
										<span>
										  {['right'].map(placement => (
										  <OverlayTrigger
											key={placement}
											placement={placement}
											overlay={
											<Tooltip id={`tooltip-${placement}`}>
											  Enter the subjects you want to study in college. Please enter only one subject in an input box.
											</Tooltip>
											}
										  >
											<Button variant="secondary" className="info-btn">?</Button>
										  </OverlayTrigger>
										  ))}
										</span>
										<Form.Control type="text" name="study_subject1" id="study_subject1" onChange={this.handleChange} value={this.state.study_subject1} />
									</Col> 
									<Col md={6}>
										<Form.Label>&nbsp;</Form.Label>
										<Form.Control type="text" name="study_subject2" id="study_subject2" onChange={this.handleChange} value={this.state.study_subject2} />
									</Col>
                                </Row>
                                <Row> 
                                  <Col md={6}>
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Form.Control type="text" name="study_subject3" id="study_subject3" onChange={this.handleChange} value={this.state.study_subject3} />
                                  </Col>
                                </Row>
                                <div className="clear">&nbsp;</div>
                                <Row>  
                                  <Col md={6}>
                                    <Form.Label>Colleges that interest you?</Form.Label>&nbsp;
                                    <span>
                                      {['right'].map(placement => (
                                      <OverlayTrigger
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                        <Tooltip id={`tooltip-${placement}`}>
                                          Enter the name of the college that interest you. Please enter only one college in an input box.
                                        </Tooltip>
                                        }
                                      >
                                        <Button variant="secondary" className="info-btn">?</Button>
                                      </OverlayTrigger>
                                      ))}
                                    </span>
                                    <Form.Control type="text" name="college_of_interest1" id="college_of_interest1" onChange={this.handleChange} value={this.state.college_of_interest1} />
                                  </Col>
                                  <Col md={6}>
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Form.Control type="text" name="college_of_interest2" id="college_of_interest2" onChange={this.handleChange} value={this.state.college_of_interest2} />
                                  </Col>
                                </Row>
                                <Row>  
                                  <Col md={6}>
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Form.Control type="text" name="college_of_interest3" id="college_of_interest3" onChange={this.handleChange} value={this.state.college_of_interest3} />
                                  </Col>
                                </Row>
                                <div className="clear">&nbsp;</div>
                                <Row>  
                                  <Col md={12}>
                                    <Form.Group>
                                      <Form.Label>Type of Degree</Form.Label>&nbsp;
                                      <span>
                                        {['right'].map(placement => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Select the type of degree you are currently seeking.
                                          </Tooltip>
                                          }
                                        >
                                          <Button variant="secondary" className="info-btn">?</Button>
                                        </OverlayTrigger>
                                        ))}
                                      </span>
                                      {['radio'].map(type => (
                                        <div key={`custom-inline-${type}`} name="degree_type" id="degree_type" 
                                        onClick={this.handleChange}    className="mb-3">
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          value="Associates"
                                          type={type}
                                          name="degree_type"
                                          label={`Associates `}
                                          id={`custom-inline-${type}-10`}
                                          />
                                          <Form.Check
                                          custom
                                          tabIndex="-1"
                                          inline    
                                          value="Bachelors"    
                                          type={type}
                                          name="degree_type"
                                          label={`Bachelors`}
                                          id={`custom-inline-${type}-11`}
                                          />
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          inline   
                                          value="Master"     
                                          type={type}
                                          name="degree_type"
                                          label={`Masters `}
                                          id={`custom-inline-${type}-12`}
                                          />
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          inline        
                                          type={type}
                                          value="Doctorate"
                                          name="degree_type"
                                          label={`Doctorate `}
                                          id={`custom-inline-${type}-13`}
                                          />
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          inline        
                                          type={type}
                                          value="Juris Doctorate"
                                          name="degree_type"
                                          label={`Juris Doctorate`}
                                          id={`custom-inline-${type}-14`}
                                          />
                                        </div>
                                        ))}  
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <Form.Group>
                                        <Form.Label>Are you interested in studying abroad?</Form.Label>&nbsp;
                                        <span>
                                          {['right'].map(placement => (
                                          <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                            <Tooltip id={`tooltip-${placement}`}>
                                              Select 'Yes' if you are interested in studying abroad.
                                            </Tooltip>
                                            }
                                          >
                                            <Button variant="secondary" className="info-btn">?</Button>
                                          </OverlayTrigger>
                                          ))}
                                        </span>
                                        {['radio'].map(type => (
                                        <div key={`custom-inline-${type}`} className="mb-3">
                                        <Form.Check
                                        tabIndex="-1"
                                        custom
                                        value="Yes"
                                        type={type}
                                        name="study_abroad"
                                        label={`Yes `}
                                        id={`custom-inline-${type}-15`}
                                        />
                                        <Form.Check
                                        tabIndex="-1"
                                        custom
                                        inline     
                                        value="No"   
                                        type={type}
                                        name="study_abroad"
                                        label={`No`}
                                        id={`custom-inline-${type}-16`}
                                        />
                                        </div>
                                        ))}  
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>  
                                    <Col md={6}>                                    
                                      <Form.Group >
                                        <Form.Label>Hobbies</Form.Label>
                                        <Form.Control type="text" name="hobbies" id="hobbies" onChange={this.handleChange} value={this.state.hobbies} />
                                      </Form.Group>
                                    </Col>
                                    <Col md={6}>                                    
                                      <Form.Group >
                                        <Form.Label>Interests</Form.Label>
                                        <Form.Control as="select" name="interests" id="interests">
											<option value="" >----Select----</option>
											{interestOptions}
										</Form.Control>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>  
                                    <Col md={6}>                                    
                                      <Form.Group >
                                        <Form.Label>Do you have any unique features or talents?</Form.Label>&nbsp;
										<span>
											{['right'].map(placement => (
											<OverlayTrigger
											  key={placement}
											  placement={placement}
											  overlay={
											  <Tooltip id={`tooltip-${placement}`}>
												Tell us something that interests you and a talent that you have. You never know what kind of scholarships are already available for your unique gifts and talents.
											  </Tooltip>
											  }
											>
											  <Button variant="secondary" className="info-btn">?</Button>
											</OverlayTrigger>
											))}
										</span>	
                                        <Form.Control type="text" name="unique_talents" id="unique_talents" onChange={this.handleChange} value={this.state.unique_talents}/>
                                      </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                      <Form.Group >
                                        <Form.Label>Clubs and Organizations</Form.Label>
                                        <Form.Control as="select" name="club_memberships" id="club_memberships" tabIndex="-1" onChange={this.handleChange}>
                                          <option>----Select----</option>
                                          {clubOptions}
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={6}>
                                      <Form.Group >
                                        <Form.Label>Is anyone in your immediate family part of the military?
                                        &nbsp;
                                          <span>
                                            {['right'].map(placement => (
                                            <OverlayTrigger
                                              key={placement}
                                              placement={placement}
                                              overlay={
                                              <Tooltip id={`tooltip-${placement}`}>
                                                Select 'Yes' if anyone in your immediate family is serving in Military.
                                              </Tooltip>
                                              }
                                            >
                                              <Button variant="secondary" className="info-btn">?</Button>
                                            </OverlayTrigger>
                                            ))}
                                          </span></Form.Label>
                                          {['radio'].map(type => (
                                        <div key={`custom-inline-${type}`} className="mb-3">
                                          <Form.Check
                                          tabIndex="-1"
                                            custom
                                            inline     
                                            value="Yes"   
                                            type={type}
                                            name="military_affiliation"
                                            label={`Yes`}
                                            id={`custom-inline-${type}-17`}
										  />
                                          <Form.Check
                                          tabIndex="-1"
                                              custom
                                              type={type}
                                              value="No"
                                              name="military_affiliation"
                                              label={`No`}
                                              id={`custom-inline-${type}-18`}                       
                                          />
                                        </div>
                                          ))}  
                                      </Form.Group>
									</Col>
									<Col md={6}>
									  <Form.Group >
										<Form.Label>Additional Information</Form.Label>&nbsp;
										<span>
										  {['right'].map(placement => (
										  <OverlayTrigger
											key={placement}
											placement={placement}
											overlay={
											<Tooltip id={`tooltip-${placement}`}>
											  Is there anything else you’d like for us to know as we search for your scholarships?
											</Tooltip>
											}
										  >
											<Button variant="secondary" className="info-btn">?</Button>
										  </OverlayTrigger>
										  ))}
										</span>
										<Form.Control type="text" name="custom_word_search" id="custom_word_search" value={this.state.custom_word_search ? this.state.custom_word_search : this.state.custom_word_search } onChange={this.handleChange}/>
									  </Form.Group>
									</Col>
                                </Row>								
                              </div>    
                            : /* Check for student's schooling standard of Graduate or College Student */
                              <div> 
                                <Row>  
                                  <Col md={6}>
                                    <Form.Group >
                                      <Form.Label>Current Education Level<sup>*</sup></Form.Label>&nbsp;
                                      <span>
                                        {['right'].map(placement => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Eligibility for many scholarships in our database is based upon the applicant's current education level.  Please supply your current education level for accurate results.
                                          </Tooltip>
                                          }
                                        >
                                          <Button variant="secondary" className="info-btn">?</Button>
                                        </OverlayTrigger>
                                        ))}
                                      </span>
                                      <Form.Control as="select" name="grade" id="grade" onChange={this.handleChange} required>
                                        <option value="">----Select----</option>
                                        {gradeOptions}
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>                                    
                                    <Form.Group >
                                      <Form.Label>School you are attending</Form.Label>&nbsp;
                                      <span>
                                        {['right'].map(placement => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Enter the name of the school you are attending.
                                          </Tooltip>
                                          }
                                        >
                                          <Button variant="secondary" className="info-btn">?</Button>
                                        </OverlayTrigger>
                                        ))}
                                      </span>
                                      <Form.Control type="text" name="school_attending" id="school_attending" onChange={this.handleChange} value={this.state.school_attending}/>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>  
                                  <Col md={12}>
                                    <Form.Group>
                                      <Form.Label>Degree Seeking</Form.Label>&nbsp;
                                      <span>
                                        {['right'].map(placement => (
                                        <OverlayTrigger
                                          key={placement}
                                          placement={placement}
                                          overlay={
                                          <Tooltip id={`tooltip-${placement}`}>
                                            Select the type of degree you are currently seeking.
                                          </Tooltip>
                                          }
                                        >
                                          <Button variant="secondary" className="info-btn">?</Button>
                                        </OverlayTrigger>
                                        ))}
                                      </span>
                                      {['radio'].map(type => (
                                        <div key={`custom-inline-${type}`} name="degree_type" id="degree_type" 
                                        onClick={this.handleChange} className="mb-3">
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          value="Associates"
                                          type={type}
                                          name="degree_type"
                                          label={`Associates`}
                                          id={`custom-inline-${type}-10`}
                                          />
                                          <Form.Check
                                          custom
                                          tabIndex="-1"
                                          inline    
                                          value="Bachelors"    
                                          type={type}
                                          name="degree_type"
                                          label={`Bachelors`}
                                          id={`custom-inline-${type}-11`}
                                          />
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          inline   
                                          value="Masters"     
                                          type={type}
                                          name="degree_type"
                                          label={`Masters`}
                                          id={`custom-inline-${type}-12`}
                                          />
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          inline        
                                          type={type}
                                          value="Professional"
                                          name="degree_type"
                                          label={`Professional`}
                                          id={`custom-inline-${type}-13`}
                                          />
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          inline        
                                          type={type}
                                          value="Doctorate"
                                          name="degree_type"
                                          label={`Doctorate`}
                                          id={`custom-inline-${type}-14`}
                                          />
                                          <Form.Check
                                          tabIndex="-1"
                                          custom
                                          inline        
                                          type={type}
                                          value="Professional Certification"
                                          name="degree_type"
                                          label={`Professional Certification`}
                                          id={`custom-inline-${type}-15`}
                                          />
                                        </div>
                                        ))}  
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12}>
                                      <Form.Group>
                                        <Form.Label>Are you interested in studying abroad?</Form.Label>&nbsp;
                                        <span>
                                          {['right'].map(placement => (
                                          <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                            <Tooltip id={`tooltip-${placement}`}>
                                              Select 'Yes' if you are interested in studying abroad.
                                            </Tooltip>
                                            }
                                          >
                                            <Button variant="secondary" className="info-btn">?</Button>
                                          </OverlayTrigger>
                                          ))}
                                        </span>
                                        {['radio'].map(type => (
                                        <div key={`custom-inline-${type}`} className="mb-3">
                                        <Form.Check
                                        tabIndex="-1"
                                        custom
                                        value="Yes"
                                        type={type}
                                        name="study_abroad"
                                        label={`Yes `}
                                        id={`custom-inline-${type}-16`}
                                        />
                                        <Form.Check
                                        tabIndex="-1"
                                        custom
                                        inline     
                                        value="No"   
                                        type={type}
                                        name="study_abroad"
                                        label={`No`}
                                        id={`custom-inline-${type}-17`}
                                        />
                                        </div>
                                        ))}  
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>  
                                    <Col md={6}>                                    
                                      <Form.Group >
                                        <Form.Label>Hobbies</Form.Label>
                                        <Form.Control type="text" name="hobbies" id="hobbies" onChange={this.handleChange} />
                                      </Form.Group>
                                    </Col>
                                    <Col md={6}>                                    
                                      <Form.Group >
                                        <Form.Label>Interests</Form.Label>
                                        <Form.Control as="select" name="interests" id="interests">
											<option value="" >----Select----</option>
											{interestOptions}
										</Form.Control>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>  
                                    <Col md={6}>                                    
                                      <Form.Group >
                                        <Form.Label>Unique features or talents</Form.Label>&nbsp;
										<span>
											{['right'].map(placement => (
											<OverlayTrigger
											  key={placement}
											  placement={placement}
											  overlay={
											  <Tooltip id={`tooltip-${placement}`}>
												Tell us something that interests you and a talent that you have. You never know what kind of scholarships are already available for your unique gifts and talents.
											  </Tooltip>
											  }
											>
											  <Button variant="secondary" className="info-btn">?</Button>
											</OverlayTrigger>
											))}
										</span>
                                        <Form.Control type="text" name="unique_talents" id="unique_talents" onChange={this.handleChange} value={this.state.unique_talents} />
                                      </Form.Group>
                                    </Col>
                                    <Col md={6}>                                    
                                      <Form.Group >
                                        <Form.Label>Military affiliation</Form.Label>&nbsp;
                                        <span>
                                          {['right'].map(placement => (
                                          <OverlayTrigger
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                            <Tooltip id={`tooltip-${placement}`}>
                                              Enter if you are affiliated with military.
                                            </Tooltip>
                                            }
                                          >
                                            <Button variant="secondary" className="info-btn">?</Button>
                                          </OverlayTrigger>
                                          ))}
                                        </span>
                                        <Form.Control type="text" name="military_affiliation" id="military_affiliation" onChange={this.handleChange} value={this.state.military_affiliation} />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>  
                                    <Col md={6}>
										<Form.Group className="race-panel">
										  <Form.Label>Current Major</Form.Label>&nbsp;
										  <span>
											{['right'].map(placement => (
											<OverlayTrigger
											  key={placement}
											  placement={placement}
											  overlay={
											  <Tooltip id={`tooltip-${placement}`}>
												Eligibility for many scholarships in our database is based upon the applicant's major / field of study. Please supply your major / field of study for accurate results.
											  </Tooltip>
											  }
											>
											  <Button variant="secondary" className="info-btn">?</Button>
											</OverlayTrigger>
											))}
										  </span>	
										  <br />	
										  <div className="checklist">
										  { this.state.user_majors.length > 0 ?
											createMajorElms
											:
											<span>
											<a className="btn" style={{"borderColor": "#010167"}}  onClick={this.openMajorModal}>Select</a>
											<span id="errorMajor" style={{color: 'red'}}></span>
											</span>
										  }									  
										  </div>
									    </Form.Group>
									</Col>
                                    <Col md={6}>                                    
                                      <Form.Group >
                                        <Form.Label>Transfer Student?</Form.Label>
                                        <Form.Control as="select" name="transfer_student" id="transfer_student" onChange={this.handleChange}>
                                          <option value="">----Select----</option>
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>
                                  </Row>
								  <Row>
									<Col md={6}>
									  <Form.Group >
										<Form.Label>Additional Information</Form.Label>&nbsp;
										<span>
										  {['right'].map(placement => (
										  <OverlayTrigger
											key={placement}
											placement={placement}
											overlay={
											<Tooltip id={`tooltip-${placement}`}>
											  Is there anything else you’d like for us to know as we search for your scholarships? 
											</Tooltip>
											}
										  >
											<Button variant="secondary" className="info-btn">?</Button>
										  </OverlayTrigger>
										  ))}
										</span>
										<Form.Control type="text" name="custom_word_search" id="custom_word_search" value={this.state.custom_word_search} onChange={this.handleChange} />
									  </Form.Group>
									</Col>									
								  </Row>
                              </div>
                          }						  
                          <div class="form-group" style={{'text-align': 'center'}}>
                            <Button variant="primary" type="submit" id="submit" >{this.state.submitBtnText}</Button>
                          </div>
						  
						  { /* Race Popup */ }
                          <Modal open={racePopup} onClose={this.closeRaceModal} center>
                            <div className="modal-box select-box">
                              <h2>Select all that apply</h2>
                                <div className="popup-body">              
                                  <Form>
                                    {createPopupRaceList}
									<br />
                                    <Button variant="default" type="button" onClick={this.closeRaceModal}>Done</Button>
                                </Form>   
                              </div>
                            </div>
                          </Modal>
						  
						  { /* Major Popup */ }
						  <Modal open={majorPopup} onClose={this.closeMajorModal} center>
							<div className="modal-box select-box">
								<h2>Select all that apply</h2>
								  <div className="popup-body">              
									<Form>
										{createPopupMajorList}
										<br />										  
										<Button variant="default" type="button" onClick={this.closeMajorModal}>Done</Button>
								  </Form>   
								</div>
							</div>
						  </Modal>
                      </Form>
                      </Tab.Pane>                      
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
          </Container>
          <InnerFooter/>
        </div>
      );
    }
}
export default PersonalInfo;