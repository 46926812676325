/* ___________________________________
  |                                   |
  |   View User Applied Scholarships  |
  |     Verify the recieved amount    |
  |                                   |
  |___________________________________|
                                       */
// Initial Lib
import React, { Component } from 'react';

import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Include Component
import ScholarshipHeader from '../layout/scholarshipHeader';
import InnerFooter from '../layout/InnerFooter';
import DashSideNav from './DashSideNav';
import '../../applyScholarship.css';
// Design Lib
import {PageLoader,SmallPageLoader} from '../loaders/PageLoader'; // Page Loader
import ShowMoreText from 'react-show-more-text'; // Show more text
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Card,Modal} from "react-bootstrap";
import { Redirect, browserHistory,router, Link } from 'react-router-dom';
import WOW from "wowjs";


// Service lib
import {postApiData,getApiData} from '../../services/Api';

// default Base Urls
import config from 'react-global-configuration';
import { Checkbox } from 'material-ui';

const history = createBrowserHistory();

class UserAppliedScholarships extends Component {
    
  constructor(props){
    super(props);
    this.checkBoxRef=React.createRef();
    this.state={
      currentPath : this.props.match.path,
      appliedScholarships: [],
      validated: false,
      showPositive: false,
      showNegative: false,
      positiveScholarshipId: "",
      recievedAmt: "",
      maxLimit:10,
      error:"",
      amountField : false,
      haveYouApplied:0,
      // Local Storage
      loggedin:reactLocalStorage.get('loggedin'),
      user_id : localStorage.getItem('userId'),
      isPaid: localStorage.getItem('paid'),
      loaderDiv : false,

 

    }


    // Bind functions
    this.positive = this.positive.bind(this);
    this.negative = this.negative.bind(this);
    this.handlePClose = this.handlePClose.bind(this);
    this.handleNClose = this.handleNClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeScholarship = this.removeScholarship.bind(this);

    
  }

  /**
   * Set Saved Scholarship Var values.
   *
   * @Set values
  */
  savedScholarships() {
    // Set post data
    let where =[{url:"/applied-scholarships"}]; // Service param
    var form = new FormData();
    var self = this;
    self.setState({ appliedScholarships: [],loaderDiv: true})
    // Post parameters on service URL...
    postApiData(where,form).then(response => {
    if(response.status==200 && response.data.success==true)
	  {
      self.setState({ appliedScholarships: response.data.scholarships, loaderDiv: false}); // Set data
      }else{
      self.setState({ appliedScholarships: 0, loaderDiv: false}); // Set data
      }
    })
    .catch(function (error) {
    });
  }

  /**
   * On Click validate login.
   *
   * @return status
  */
  handleClick(event){

  event.preventDefault();

  // Validation check
  const form = event.currentTarget;
  if (form.checkValidity() === false) {event.stopPropagation();}
  this.setState({ validated: true });

  // Set Var
  var self = this;
  var divId = "question_"+form.scholarshipId.value;
  
  var loadivId = "loaderdiv_"+form.scholarshipId.value;
  document.getElementById(loadivId).style.display = "block";

  let where =[{url:"/verify-scholarship"}]; // Service param
  var formData = new FormData(); // Set post data
  formData.append('hit_status',2); // Status changed to amount recieved
  formData.append('amount_recieved',form.recievedAmt? form.recievedAmt.value:'');
  formData.append('scholarship_id',form.scholarshipId.value);
  formData.append('haveYouApplied',form.haveYouApplied.value);
  
  self.setState({ loginloaderDiv: true });
  this.setState({ showPositive: false });
  
  // Post parameters on service URL...
  postApiData(where,formData,true).then(response => {	  
	  if(response.status==200 && response.data.success==true)
	  {
      this.setState({
        recievedAmt : response.data.amount_recieved,
        haveYouApplied: response.data.haveYouApplied
      },()=>{
        this.savedScholarships();
      })
		document.getElementById(loadivId).style.display = "none";
		document.getElementById(divId).innerHTML  = response.data.msg;
	  }
  })
  .catch(function (error) {
  });

  }

  handlePClose() {
    this.setState({ showPositive: false });
  }

  handleNClose() {
    this.setState({ showNegative: false });
  }

  positive=(id,amt,maxLimit, appliedStutus)=> {

    let where =[{url:"/verify-scholarship-detail"}]; // Service param
    let obj = {
      scholarship_id : id 
    }

    this.setState({
        recievedAmt : '',
      }) 

    // Post parameters on service URL...
  postApiData(where,obj,true).then(response => {  
    if(response.status==200 && response.data.success==true)
    {
      this.setState({
        recievedAmt : response.data.amount_recieved,
        haveYouApplied: response.data.haveYouApplied
      })
    }
  })
  .catch(function (error) {
  });


    if(this.state.haveYouApplied == 0 || this.state.positiveScholarshipId !=id) {
        this.setState({ 
        haveYouApplied: appliedStutus,
      });
    }
    // } else {
    //   this.setState({ recievedAmt: newAmt });
    // }
    // this.setState({ positiveScholarshipId: id });

    this.setState((state) => {
      
      return {positiveScholarshipId: id}
    });
  

console.log(this.state.positiveScholarshipId);
    this.setState({ maxLimit: maxLimit });
    this.setState({ showPositive: true });
  }
onBlurEvent=()=>{
  this.setState({ showPositive: false,currentFocus:{[this.state.positiveScholarshipId]:true} });
console.log(this.state.currentFocus);
document.getElementById(this.state.positiveScholarshipId).focus();
  document.getElementById(this.state.positiveScholarshipId).checked = false;
 
  // document.getElementById(this.state.positiveScholarshipId).focus=true;
  // this.checkBoxRef.current.focus();
  // this.checkBoxRef.current.select();
  
  // document.getElementById("checkbox").location.reload=true;
  // window.top.location.reload();
  // location.reload()
}
  negative() {
    this.setState({ showNegative: true });
  }

  /* Handle Blank Spaces */
  handleChange = (e,key) => {
       // alert(this.state.maxLimit)
	   var mlimit=this.state.maxLimit.replace('$','')
		   mlimit=parseFloat(mlimit);
		
    if(isNaN(e.target.value)){
      this.setState({'error':'Only numbers are allowed!'});
      return false;
    }else if(e.target.value == ""){
    }else if(e.target.value < 0){
      this.setState({'error':'Please enter reasonable amount.'});
      return false;
    }else if(e.target.value >mlimit ){
      this.setState({'error':'This scholarship award maximum range is '+this.state.maxLimit});
      return false;
    }else{
      this.setState({'error':''});
    }

    this.setState({[e.target.id]: e.target.value});

  }

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount(){
    new WOW.WOW().init();
    this.savedScholarships();
  }


  handleAmountField = (e) => {
    if(e.target.value == 3){
       this.setState({
        amountField : true,
        haveYouApplied: e.target.value
      })
     } else {
        this.setState({
        amountField : false,
         haveYouApplied: e.target.value
      })
     }
    
  }


  /**
   * Remove scholarship from saved list.
   *
  */
 removeScholarship(id){
  // Set var
  var self = this;
  let divId = "rmloaderdiv_"+id;
  var removeElm = document.getElementById(divId);
  removeElm.innerHTML = "Processing...";
  // Set post data
  let where =[{url:"/remove-applied-scholarship"}]; // Service param
  var form = new FormData();
  form.append('scholarship_id', id);

  // Post parameters on service URL
  postApiData(where,form).then(response => {	

  if(response.status==200 && response.data.success==true)
  {
    removeElm.innerHTML = "Remove";
    self.setState({msg: response.data.msg});
    self.savedScholarships();
  }
  else
  {
    self.setState({msg : response.data.msg});
  }			
  })
  .catch(function (error) {
    removeElm.innerHTML = "Remove";
    console.log("In catch : "+error);
  });
}

  
  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {

    // Auth Redirection
    if(!this.state.user_id){
      return <Redirect to='/login'/>;
    }
	
    const { validated } = this.state;
    
    var scholershipSet = "";

    // Create Scholarship List Data
    if(this.state.appliedScholarships == 0){ // If no Scholarship Found
        scholershipSet = "You have not applied for any scholarship yet.";
    }else{
      let msg;
      scholershipSet = [this.state.appliedScholarships.map((item,i) => {
        if(item.award_applied_status ==1) {
          msg = "You've applied and are awaiting the results";
        }else if(item.award_applied_status ==2) {
          msg = "You applied and didn't receive an award";
        }else if(item.award_applied_status ==3) {
          msg = "You have received $"+item.amount_recieved;
        }

        return <Col lg="6">
        <Card className="scholarship-card">
          <Card.Body>
                <Card.Link href={item.weblink} target="_blank">
					<Card.Title  id="cardTitle">{item.scholarship_name}</Card.Title>
				</Card.Link>
                <Card.Text>
                  <ShowMoreText
                  lines={3}
                  more='more'
                  less='less'
                  anchorClass=''
                  onClick={this.executeOnClick}
                  >
                  {item.description}
                  </ShowMoreText>
                </Card.Text>
                <div className="price-value clearfix">
                  <div className="price">{ (item.award > 0 ? '$' + item.award : item.award)}</div>
                  <div className="time">
                  Deadline: {item.deadline}<br/>
                  One Time Award
                  </div>
                </div>
              </Card.Body>
              {item.hit_status==2? 
              <Card.Footer className="text-muted">
                <div style={{textAlign:"center","display":"none","float": "right","marginRight": "10px"}} id={"loaderdiv_"+item.id} ref="loaderdiv">
                <SmallPageLoader/>  
                </div>

                <div style={{"float" : "left"}} id={"question_"+item.id}> {msg}</div>
                <div style={{"float" : "right","marginTop":'-9px'}}><a href="javascript:void(0)" onClick={(event)=>this.positive(item.id,item.amount_recieved,item.award,item.award_applied_status)}>Edit</a></div>
					<Button variant="success float-right" className="custom-btn" onClick={ () => this.removeScholarship(item.id)} id={"rmloaderdiv_"+item.id} >Remove</Button>
              </Card.Footer>:
              <Card.Footer className="text-muted">
                <div style={{textAlign:"center","display":"none","float": "right","marginRight": "10px"}} id={"loaderdiv_"+item.id} ref="loaderdiv">
                <SmallPageLoader/>  
                </div>
                <div style={{"float" : "left"}} id={"question_"+item.id}>
                  Have you applied?&nbsp;&nbsp;<lable id="yes" for="yes"></lable> <input type="checkbox"   id={item.id}  aria-labelledby="yes" name="verify" onClick={(event)=>this.positive(item.id,item.amount_recieved,item.award, item.award_applied_status)} /> Yes 
                  &nbsp;&nbsp; </div>
                
                  <Button variant="success float-right"  className="custom-btn"onClick={ () => this.removeScholarship(item.id)} id={"rmloaderdiv_"+item.id} >Remove</Button>
              </Card.Footer>}
              
            </Card>
        </Col>
        
      })];
    }

    return (
      <div>
       <ScholarshipHeader/>
        <Container fluid className="schloarships-section admin-dashboard">
        <Row>
          <Col lg={3} >
            <DashSideNav path={this.state.currentPath} />
          </Col>
          <Col lg={9}>
              <div className="schloarship-list">
                  <h1 style={{'color':'#212529'}}>Applied Scholarships</h1>
                  <hr/>
                  <Row>
                  {
                    this.state.loaderDiv ?
														(<div style={{textAlign:"center"}} ref="loaderdiv"><PageLoader/></div>)
                    :
                    scholershipSet
                  }
                  </Row>
              </div>
          </Col>
        </Row>

        {/* Popup HTML -- Start */}
        <Modal show={this.state.showPositive} onHide={this.handlePClose}>
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Form method="post" id="amountForm" name="amountForm" validated={validated} 
              onSubmit={(event) => this.handleClick(event)}>
            <Modal.Body>
                 


              <Col sm={10}>
                <Form.Check 
                  defaultChecked={this.state.haveYouApplied == 1? true:false}
                  type="radio"
                  label="You've applied and are awaiting the results" 
                  name="haveYouApplied"
                  id="haveYouApplied1"
                  value="1"
                  onClick={this.handleAmountField}
                />
                <Form.Check
                  defaultChecked={this.state.haveYouApplied == 2? true:false}
                  type="radio"
                  label="You applied and didn't receive an award"
                  name="haveYouApplied"
                  id="haveYouApplie2"
                  value="2"
                  onClick={this.handleAmountField}
                />
                <Form.Check 
                  defaultChecked={this.state.haveYouApplied == 3? true:false}
                  type="radio"
                  label="You applied and did receive an award "
                  name="haveYouApplied"
                  id="haveYouApplied3"
                  value="3"
                  onClick={this.handleAmountField}
                />
              </Col>

              <input type="hidden" value={this.state.positiveScholarshipId} name="scholarshipId" id="scholarshipId" />

              {(this.state.haveYouApplied == 3) &&

                <Form.Group controlId="username">               
                  <Form.Control type="text" placeholder="Amount" maxLength={this.state.maxLimit} id="recievedAmt" name="recievedAmt" value={this.state.recievedAmt} 
                  onChange={event => this.handleChange(event,event.keyCode)}
                  autocomplete="off" required  />      
                  <span style={{'color': '#CA483E'}}>{this.state.error}</span>          
                </Form.Group>
              }
              
            </Modal.Body>
            <Modal.Footer>
             
              <Button onBlur={this.onBlurEvent} variant="primary" type="submit" >
                Save Changes
              </Button>
              
            </Modal.Footer>
            </Form>
          </Modal>
          {/* Popup HTML -- End */}
          {/* Popup HTML -- Start */}
          <Modal show={this.state.showNegative} onHide={this.handleNClose}>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleNClose}>
                Close
              </Button>
              <Button onBlur={this.onBlurEvent} variant="primary" onClick={this.handleNClose}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Popup HTML -- End */}
  

      </Container>

       <InnerFooter/>

      </div>
    );
  }
}
export default UserAppliedScholarships;