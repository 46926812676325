import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {Nav, Navbar,  Form, FormControl,} from "react-bootstrap";
import {reactLocalStorage} from 'reactjs-localstorage';
import {Link} from "react-router-dom";
import config from 'react-global-configuration';
class Header extends Component {
    constructor(props){
      super(props);
      this.state={
		  user_id:reactLocalStorage.get('userId'),
      password:'',
      loggedin:reactLocalStorage.get('loggedin'),
      first_name:reactLocalStorage.get('firstName'),
      administratorLoginUrl: config.get('imgUrl'),
      groupLoginUrl: config.get('imgUrl')+'group',
			  profileStatus:reactLocalStorage.get('profile_status'),
     
      }    
 
    }
    
    componentDidMount(){
        window.addEventListener('scroll', () => {
           let activeClass = 'normal';
           if(window.scrollY === 0){
               activeClass = 'top';
           }
           this.setState({ activeClass });
        });

    }
    myFunction() {
        var y = document.getElementById("searchFilter");
        if (y.style.display === "block") {
          y.style.display = "none";
        } else {
          y.style.display = "block";
        }
      }
      Sidebar() {
        var y = document.getElementById("SidebarBox");
        if (y.style.display === "block") {
          y.style.display = "none";
        } else {
          y.style.display = "block";
        }
      }
     
    async openNav() {
      document.getElementById("myNav").style.width = "300px";
    }

    async  closeNav() {
      document.getElementById("myNav").style.width = "0%";
    }
	

   render() {
    return (
    <div>
    <header className={`top-bar ${this.state.activeClass}`}>
    <a class="skip-to-content-link" href="#main" >
           Skip to main content
          </a>
            <Navbar expand="xl" >
            <Navbar.Brand href="/"><img src="/logo.png"  alt="Imagine Scholarships" height="100px"/>{/*<img src="../assets/img/logo.svg"/>*/}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav8" />
		 {(this.state.user_id) ?
          this.state.profileStatus==1 ?
			
		 <Navbar.Collapse id="basic-navbar-nav8" >
                <Nav >
                <Nav.Link href="/matched-scholarships"  >Matched Scholarships</Nav.Link>
                <Nav.Link href="/saved-scholarships" >Saved Scholarships</Nav.Link>
                <Nav.Link href="/applied-scholarships" >Applied Scholarships</Nav.Link>
                <Nav.Link href="/interships" >Internship </Nav.Link>
                <Nav.Link href="/logout" >Logout</Nav.Link>
                </Nav>        
            </Navbar.Collapse>:
           <Navbar.Collapse id="basic-navbar-nav81" >
                <Nav >
                <Nav.Link href="/"  >Home</Nav.Link>
                <Nav.Link href="https://www.imaginecollegecoaching.com/baylor-contact-page"  target="_blank">Contact</Nav.Link>
                <Nav.Link href="https://www.imaginecollegecoaching.com/frequently-asked-questions"  target="_blank">FAQs</Nav.Link>
               
                <Nav.Link href="/login">Login</Nav.Link>
                </Nav>        
            </Navbar.Collapse>: <Navbar.Collapse id="basic-navbar-nav" >
                <Nav >
                <Nav.Link href="/" >Home</Nav.Link>
                <Nav.Link href="https://www.imaginecollegecoaching.com/baylor-contact-page"  target="_blank">Contact</Nav.Link>
                <Nav.Link href="https://www.imaginecollegecoaching.com/frequently-asked-questions"   target="_blank">FAQs</Nav.Link>
               
                <Nav.Link href="/login" >Login</Nav.Link>
                </Nav> </Navbar.Collapse>  } 
            <div className="ml-auto search-section">
            <Nav >
                {/* <Nav.Link onClick={this.myFunction}><img alt="search" src="../assets/img/search.svg"/></Nav.Link> */}
                <Nav.Link onFocus={this.openNav}  onClick={this.openNav}><img   alt="Menu" src="../assets/img/right-nav.svg"/></Nav.Link>
            </Nav>    
            </div>
            </Navbar>
    </header>
{/* 
    <div id="searchFilter" style={{display:'none'}}>
            <Form>
            <lable id="searchLabel" for="Search"> Search</lable> <FormControl type="text" aria-labelledby="searchLabel" placeholder="Search" />          
            </Form>
    </div> */}
    <div id="myNav" class="topnav" > 
          <a href="javascript:void(0)" class="closebtn" onClick={this.closeNav}>&times;</a>
          <div class="nav-content">
            <a href="/" target="_blank">Home</a>
            <a href="https://www.imaginecollegecoaching.com/baylor-contact-page" target="_blank">Contact</a>
            <a href="https://www.imaginecollegecoaching.com/frequently-asked-questions"target="_blank">FAQ</a>
            
             {(this.state.user_id) ?
          this.state.profileStatus==1 ?
          <span>
            <a href="/matched-scholarships">MATCHED SCHOLARSHIPS</a>
            <a href="/saved-scholarships">SAVED SCHOLARSHIPS</a>
            <a href="/applied-scholarships">APPLIED SCHOLARSHIPS</a>
            <a href="/interships">INTERNSHIPS</a> 
            <a href="/membership">MANAGE SUBSCRIPTION</a>
            <a href="/profile">Profile</a>
          </span>
          :
          <span>
            <a href="/scholarships">GET STARTED</a>
          </span>
          :
          <span>
            <a href="/scholarships">GET STARTED</a>
          </span>
              }
        
              {(this.state.user_id) ?
          <a href="/logout">LOGOUT</a>
          :
          <span>
           
            <a href="/login">LOGIN / SIGNUP</a>
          </span>
				
				
              }
			   <span>
           
            <a onBlur={this.closeNav} href="https://api.imaginescholarships.com/imagine-scholarships/group" target="_blank">Group Administrator</a>
          </span>
          </div>
      </div>
    </div>

    
    );
  }
}
export default Header;