/*
  _____________________
 |                     |
 |      Lead Form      |
 |_____________________|
                        */
// Initial Lib
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Include Component
import ScholarshipHeader from './layout/scholarshipHeader';
import ScholarshipPromo from './layout/scholarshipPromo';
import InnerFooter from './layout/InnerFooter';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Pagination, Tab, ButtonToolbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import WOW from "wowjs";

// Service lib
import {postApiData,getApiData} from '../services/Api';

class SchoolingStandard extends Component {
    
	constructor(props){
		super(props);
		this.state={
		  // Local Storage Var
		  loggedin:reactLocalStorage.get('loggedin'),
		  isLogin: localStorage.getItem('userId'),
		}
	}
  
	render() 
	{
		return (
			<div>
			  <ScholarshipHeader/>
			  <ScholarshipPromo/>
			  <InnerFooter/>
			</div>
		  );
    }
}
export default SchoolingStandard;