/* ___________________________________________________________
  |                                                           |
  |             Login & Register Activity                     |
  |   Login and register related input and it's execution.    |
  |                                                           |
  |___________________________________________________________|
                                                                */
// Initial Lib
import React, { Component } from 'react';
import { Redirect, browserHistory,router } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Include Component
import InnerHeader from './layout/InnerHeader';
import InnerFooter from './layout/NewFooter';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Tab, Tabs, Button, InputGroup, FormControl, Form, Modal, Popover, OverlayTrigger, Tooltip} from "react-bootstrap";
import WOW from "wowjs";
import '../login.css';
// Required component
import {PageLoader,SmallPageLoader} from './loaders/PageLoader'; // Page Loader
import {Link} from "react-router-dom";
import ReactPasswordStrength from 'react-password-strength';

// Service lib
import {postApiData,getApiData} from '../services/Api';

// default Base Urls
import config from 'react-global-configuration';

// Cookies
import Cookies from 'universal-cookie';
import SimpleCrypto from "simple-crypto-js";
import '../login.css';
import DatePicker from 'react-date-picker';
const history = createBrowserHistory();

// enCrypt/dyCrypt Values with secrat value
var _secretKey = "98765432122052019";
var simpleCrypto = new SimpleCrypto(_secretKey);

class Login extends Component {
  
  constructor(props){

    super(props);
    let username = this.getCookie('username');
    let password = this.getCookie('userpassword');
    this.state={
      // URI var
      verifyUserId: this.props.match.params.id,
      // Login
      username: username,
      loginPassword: password,
      validated: false,
      verifyMessage: "",
      loginSubmitText: "Login",
      // Register
      first_name:'',
      last_name:'',
      email:'',
      password: '',
      confirmPassword: '',
      pass:'',
      isRegister:false,
      rValidated: false,
      regSubmitText : 'Create Your Profile',
      // Success Var
      successRedirect:"",
      name:"",
      type:"",
      // Errors
      registerError:"",
      loginError:"",
      // Loader
      loginloaderDiv: false,
      loaderDiv: false,
      redirect: false,
      // local storage Checks
      loggedin:false,
      isLogin: localStorage.getItem('userId'),
      isLead: localStorage.getItem('lead_id'),
      isLeadEmail: localStorage.getItem('lead_email'),
      redirectUri: localStorage.getItem('redirectUri'),
      tabDefault: 'register',
      plan: localStorage.getItem('plan'),
      //Group Promo Code
      codeAppliedMsg: "",
      codeFailMsg: "",
      codeGroupId: "",
      codeStatus: 0,
      // redirect Path
      authRedirect : this.props.match.path,
	  dob:'',
	  code:'',
	  month:'',
	  day:"",
	  year:""
      
    }
    // Bind functions
    this.handleChange = this.handleChange.bind(this);
  }
 
  /**
   * On Click validate login.
   *
   * @return status
  */
  handleClick(event,username,password,remember){

    event.preventDefault();

    // Validation check
    const form = event.currentTarget;
    if (form.checkValidity() === false) {event.stopPropagation();}
    this.setState({ validated: true });

    // Set Var
    var self = this;
    let currentComponent = this;
    var submitElm = document.getElementById('loginSubmit');
    let where =[{url:"/login"}]; // Service param
    var formData = new FormData(); // Set post data
		formData.append('email',username?username:this.state.isLeadEmail);
		formData.append('password',password);

    //currentComponent.setState({ loginloaderDiv: true });
    currentComponent.setState({ loginSubmitText: "Processing..." });
    submitElm.disabled = true;

    // Post parameters on service URL...
    postApiData(where,formData,true).then(response => {

      currentComponent.setState({ loginSubmitText: "Login" });
      submitElm.disabled = false;
      if(response.status === 200 && response.data.success==true)
	  {      

		// Create Session
		reactLocalStorage.set('token', response.data.token);
		reactLocalStorage.set('loggedin', true);
		reactLocalStorage.set('email', response.data.email);
		reactLocalStorage.set('userId', response.data.id);
		reactLocalStorage.set('firstName', response.data.first_name);
		reactLocalStorage.set('lastName', response.data.last_name);
		reactLocalStorage.set('paid', response.data.paid_status);
		// Set student standard status
		reactLocalStorage.set('schooling_standard', response.data.schooling_standard);
		reactLocalStorage.set('profile_status', response.data.profile_status);
    reactLocalStorage.set('group_id', response.data.group_id);
		currentComponent.setState({loggedin:true});
		currentComponent.setState({ loginloaderDiv: false });
		
		// Remember Me
		if(remember){
		  const cookies = new Cookies();
		  this.setCookie('username',response.data.email);
		  this.setCookie('userpassword',password);
		}

		reactLocalStorage.set('plan', response.data.membership_type+'~=>'+response.data.membership_cost);
		currentComponent.setState({ redirect: true }); // Set redirect status
        
      }
	  else
      {
		  currentComponent.setState({ loginError: response.data.msg, username: '', loginPassword: '', validated: '' }); 
	  }		
    })
    .catch(function (error) {
      currentComponent.setState({ loginSubmitText: "Login" });
      submitElm.disabled = false;
      currentComponent.setState({ loginError: "Your Email & Password combination do not match!" }); // Set redirect status
      currentComponent.setState({ loginloaderDiv: false });
    });

  }

  /**
   * On Click validate and register user.
   *
   * @return error/success
  */
  handleRegister(event){

    event.preventDefault();

    // Validation check
    const form = event.currentTarget;
    if (form.checkValidity() === false) {event.stopPropagation();}
    
    // Confirm password validation check
    if(this.state.password != this.state.cfrm_pass){

      event.preventDefault();
      event.stopPropagation();
      this.setState({ registerError: "Passwords do not match! Please try again." }, function () {
      console.log(this.state.registerError);
      });
      return false;
       
    }else{
      
      if(this.state.password.length < 6){
        event.preventDefault();
        event.stopPropagation();
        this.setState({ registerError: "Passwords length must be at least 6." }, function () {
        console.log(this.state.registerError);});
        return false;
      }else{
        this.setState({registerError:""});
        //this.setState({ rValidated: true });
      }
    }

    if(this.state.codeStatus == 2){
      event.preventDefault();
      event.stopPropagation();
      this.setState({ registerError: "Please enter valid code." }, function () {
        console.log(this.state.registerError);
      });
      return false;
    }
    
    // Set var
    var self = this;
    var submitElm = document.getElementById('regSubmit');
    self.setState({ regSubmitText: "Processing..." });
    submitElm.disabled = true;
    console.log(this.state.codeGroupId);
    let where =[{url:"/registration"}]; // Set post data
    var formData = new FormData();
	formData.append("data[first_name]",this.state.first_name);
    formData.append("data[last_name]",this.state.last_name);
    formData.append("data[email]",this.state.email?this.state.email:this.state.isLeadEmail);
    formData.append("data[code]",this.state.code);
    formData.append("data[password]",this.state.password);
    formData.append("data[codeGroupId]",this.state.codeGroupId);
	formData.append("data[dob]",this.state.dob);
	formData.append("front_uri",config.get('baseFrontUrl')+"login/");
    
    if(localStorage.getItem('plan')){
      formData.append("data[membership_type]",localStorage.getItem('plan').split('~=>')[0]);
      formData.append("data[membership_cost]",localStorage.getItem('plan').split('~=>')[1]);
    }else{
      formData.append("data[membership_type]",'');
      formData.append("data[membership_cost]",'');
    }

    // Post parameters on service URL...
    postApiData(where,formData,true).then(response => {

      if(response.status == 200 && response.data.success==true){ // Created
		
		reactLocalStorage.set('plan', '');
		// Create Session
		reactLocalStorage.set('loggedin', true);
		reactLocalStorage.set('token', response.data.token);
		reactLocalStorage.set('email', response.data.email);
		reactLocalStorage.set('userId', response.data.id);
		reactLocalStorage.set('firstName', response.data.first_name);
		reactLocalStorage.set('lastName', response.data.last_name);
		reactLocalStorage.set('paid', response.data.paid_status);
		reactLocalStorage.set('schooling_standard', response.data.schooling_standard);
		reactLocalStorage.set('profile_status', response.data.profile_status);
    reactLocalStorage.set('group_id', response.data.group_id);
		 
        var name = response.data.first_name+' '+response.data.last_name;
        self.setState({ name: escape(name) });
        self.setState({ type: 'registration' });
        self.setState({ successRedirect: true });
        self.setState({ loaderDiv: false });
        self.setState({loggedin:true});
      }
	  else
	  {
        this.setState({registerError:response.data.msg});
        self.setState({ loaderDiv: false });

      }
      submitElm.disabled = false;

    })
    .catch(function (error) {
      console.log(error);
      self.setState({ loaderDiv: false });
      self.setState({ regSubmitText: "Create Your Profile" });
      submitElm.disabled = false;
    });
  }

  async openNav() {
  document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
  document.getElementById("myNav").style.width = "0%";
  }

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount(){
    new WOW.WOW().init();
    window.scrollTo(0, 0);

    // If opened as Verfy Link
    if(this.state.verifyUserId){
      let self = this;
      // Set post data
      let where =[{url:"/user-verify/"+this.state.verifyUserId}]; // Service param
      // Post parameters on service URL...
      getApiData(where).then(response => {
        console.log(response);
        self.setState({ verifyMessage: response.data.message });
      })
      .catch(function (error) {
        console.log(error);
      });

    }
  }

  /* Handle Blank Spaces */
  handleChange = event => {
    let eleVal = event.target.value.trim();
    if(eleVal==""){document.getElementById(event.target.id).value = eleVal;}
    this.setState({[event.target.id]: eleVal});
  }

  /* Handle Code Changes */
  handleCodeChange = event => {

    let eleVal = event.target.value.trim();
    let self = this;

    if(eleVal){
      // Set post data
      
      let where =[{url:"/verify-group-code"}]; // Service param
      var formData = new FormData();
      formData.append("groupPromoCode",eleVal);

      // Post parameters on service URL...
      postApiData(where,formData,true).then(response => {
        if(response.status == 200 && response.data.success==true)
		{
          self.setState({ codeAppliedMsg: response.data.msg });
          self.setState({ codeFailMsg: "" });
          self.setState({ codeGroupId: response.data.group_id });
          self.setState({ codeStatus: 1 }); // 1 => Pass
        }else{
          self.setState({ codeFailMsg: response.data.msg });
          self.setState({ codeAppliedMsg: "" });
          self.setState({ codeGroupId: "" });
          self.setState({ codeStatus: 2 }); // 2 => fail
        }
      })
      .catch(function (error) {
        self.setState({ codeAppliedMsg: "" });
        self.setState({ codeFailMsg: "" });
        self.setState({ codeGroupId: "" });
        self.setState({ codeStatus: 0 }); // 0 => No code
      });
    }else{
      self.setState({ codeAppliedMsg: "" });
      self.setState({ codeFailMsg: "" });
      self.setState({ codeGroupId: "" });
      self.setState({ codeStatus: 0 }); // 0 => No code
    }

  }

  /* Set password state */
  changeCallback = state => {
    this.setState({'password': state.password});
    this.setState({ passLength: state.password.length })
  }

  /* Set Cokkies */
  setCookie(name,value){
    const cookies = new Cookies();
    let hourToExpire = "2400";
    let d = new Date();
    d.setTime(d.getTime() + (hourToExpire*60*60*1000));
    if(value){
    cookies.set(name, simpleCrypto.encrypt(value), {path: "/", expires: d});
    }
  }

  /* Get Cokkies */
  getCookie(name){
    const cookies = new Cookies();
    if(cookies.get(name)){return simpleCrypto.decrypt(cookies.get(name))}
  }
  
  
  /*datePickerdob = event =>{
      this.setState({dob:event});
   }*/
   
   checkNewCode = event => 
   {
	   let self = this;
	   var day 		= document.getElementById("day").value;
	   var month 	= document.getElementById("month").value;
	   var year 	= document.getElementById("year").value;
	   if(day != '' && month != '' && year != '')
	   {
		   var dobDate = month+'-'+day+'-'+year;
		   this.setState({dob : dobDate},()=>{
		   if(dobDate != '')
			{
					let where =[{url:"/verify-group-code"}]; // Service param
					var formData = new FormData();
					formData.append("userDob",this.state.dob);
					formData.append("userCode",this.state.code);

				  // Post parameters on service URL...
				 // if(this.state.dob != '' && this.state.code != '')
				  //{
					postApiData(where,formData,true).then(response => 
					{
						if(response.status == 200 && response.data.success==true)
						{
						  self.setState({ codeAppliedMsg: response.data.msg });
						  self.setState({ codeFailMsg: "" });
						  self.setState({ codeGroupId: response.data.group_id });
						  self.setState({ codeStatus: 1 }); // 1 => Pass
						}
						else
						{
						  self.setState({ codeFailMsg: response.data.msg });
						  self.setState({ codeAppliedMsg: "" });
						  self.setState({ codeGroupId: "" });
						  self.setState({ codeStatus: 2 }); // 2 => fail
						}
						console.log("Rudrasss->",response);
					})
					.catch(function (error) {
					self.setState({ codeAppliedMsg: "" });
					self.setState({ codeFailMsg: "" });
					self.setState({ codeGroupId: "" });
					self.setState({ codeStatus: 0 }); // 0 => No code
				  });
				/*}else{
				  self.setState({ codeAppliedMsg: "" });
				  self.setState({ codeFailMsg: "" });
				  self.setState({ codeGroupId: "" });
				  self.setState({ codeStatus: 0 }); // 0 => No code
				}*/
			   }
		   });
	   }
	   else
	   {
		   var dobDate = '';
	   }
	
   }

  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {
    const { validated } = this.state;
    const { rValidated } = this.state;

    if (this.state.successRedirect) 
    {
      if(this.state.type=='registration')
      { 
        setTimeout(() => {
          return <Redirect to={"/scholarships"} />;
        }, 3000);
         
      }
      else
      {
        return <Redirect to={"/success/"+this.state.name+"/"+this.state.type} />;
      }
    }

    if(this.state.loggedin || this.state.isLogin)
	{
		if(this.state.redirect!="")
		{
			reactLocalStorage.set('redirectUri', this.state.redirectUri);
			return <Redirect to={this.state.redirectUri}/>;
		}
		else if(localStorage.getItem('profile_status') == 1)
		{
			return <Redirect to='/matched-scholarships'/>;
		}
		else
		{
			return <Redirect to='/scholarships'/>;
		}
    }

    const handleHide = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
	
	//Array for days dropdown
	const days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
	const listday = days.map((day) =>
	<option value={day}>{day}</option>
							);
	//Array for month dropdown						
	const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12];
	const listmonth = months.map((month) =>
	<option value={month}>{month}</option>
							);
	//Array for year dropdown			
	const years = [1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021]
	const listyear = years.map((year) =>
	<option value={year}>{year}</option>
								);
    return (
      <div style={{background:'url(/login-bg.jpg) no-repeat center bottom', backgroundSize:'cover', paddingBottom:'1px'}}>
        <InnerHeader/>
            <div style={{minHeight:'500px', paddingTop:'100px'}}>
                <div className="login-section">
                  <Tabs activeKey={this.state.tabDefault} onSelect={tabDefault => this.setState({ tabDefault })}>                    
                    <Tab eventKey="register" title="Signup">
                    <Form method="post" name="register" id="register" validated={rValidated} onSubmit={(event) => this.handleRegister(event)}>
                      <Form.Group controlId="first_name">      
                        <Form.Label>First Name<sup>*</sup></Form.Label>         
                        <Form.Control type="text" placeholder="Your First Name" id="first_name" name="first_name" required 
                        onChange={this.handleChange} />
                      </Form.Group>
                      <Form.Group controlId="last_name">
                        <Form.Label>Last Name<sup>*</sup></Form.Label>              
                        <Form.Control type="text" placeholder="Your Last Name" id="last_name" required  onChange={this.handleChange} />                
                      </Form.Group>
                      <Form.Group controlId="email">
                        <Form.Label>Email Address<sup>*</sup></Form.Label>          
                        {(this.state.isLeadEmail)?
                        <Form.Control type="email" placeholder="Your Email Address" id="email" value={this.state.isLeadEmail} onChange={this.handleChange} readOnly />
                        :<Form.Control type="email" placeholder="Your Email Address" id="email" autocomplete="false" required email onChange={this.handleChange} />}                 
                      </Form.Group>
                      <Form.Group controlId="password">
                        <Form.Label>Password<sup>*</sup></Form.Label>       
                          <ReactPasswordStrength
                          className=""
                          minLength={5}
                          minScore={2}
                          scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                          changeCallback={this.changeCallback}
                          required
                          inputProps={{ name: "Password",id: "password", autoComplete: "off", className: "form-control", required : "required" }}
                          />
                      </Form.Group>
                      <Form.Group controlId="cfrm_pass">
                        <Form.Label>Confirm Password<sup>*</sup></Form.Label>           
                        <Form.Control type="password" placeholder="Confirm Password" data-match="#password" required  id="cfrm_pass" onChange={this.handleChange} />
                      </Form.Group>
                      <Form.Group controlId="code">
                        <Form.Label>Bear ID</Form.Label>&nbsp;
						<span>
						  {['right'].map(placement => (
						  <OverlayTrigger
							key={placement}
							placement={placement}
							overlay={
							<Tooltip id={`tooltip-${placement}`}>Enter your Bear ID.
							</Tooltip>
							}
						  >
							<Button variant="secondary" className="info-btn">?</Button>
						  </OverlayTrigger>
						  ))}
						</span>
                        {/*<Form.Control type="text" placeholder="If with a group, enter group code." id="code" onChange={this.handleChange} onBlur={this.handleCodeChange} /> */}
						{this.state.codeAppliedMsg?
                        <div className="alert-success" style={{'margin': '9px 0px','padding': '4px 9px'}}>{this.state.codeAppliedMsg}</div>:""}
                        {this.state.codeFailMsg?<div className="alert-danger" style={{'margin': '9px 0px','padding': '4px 9px'}}>{this.state.codeFailMsg}</div>:""}
						<Form.Control type="text" placeholder="Bear ID" id="code" onChange={this.handleChange} required/>
                      </Form.Group>
					  
					  <Form.Group controlId="dob">      
                        <Form.Label>Date of Birth<sup>*</sup></Form.Label>         
						{/* <Form.Control type="text" placeholder="Date of Birth" id="dob" name="dob" required 
                        onChange={this.handleChange} />
						<DatePicker onChange={this.datePickerdob}  value={this.state.dob} className="form-control" required onBlur={this.checkNewCode}/>*/}
						<div className="input-group">
						<select style={{ width : '100px', height: '50px', marginRight: '5px'}} name="month" id="month" required className="ReactPasswordStrength-input form-control" onChange={this.checkNewCode}>
                            <option value="">Month</option>
                            {listmonth}
                          </select>
						<select style={{ width : '100px', height: '50px', marginRight: '5px'}} name="day" id="day" required className="ReactPasswordStrength-input form-control" onChange={this.checkNewCode}>
                            <option value="">Day</option>
						   {listday}		
                          </select>
						<select style={{ width : '100px', height: '50px'}} name="year" id="year" required className="ReactPasswordStrength-input form-control" onChange={this.checkNewCode}>
                            <option value="">Year</option>
                            {listyear}
                          </select>
						  </div>
                      </Form.Group>
                      
                      <Form.Group >
                          {['checkbox'].map(type => (
                          <div key={`custom-${type}`} className="mb-3">
                            <Form.Check 
                              custom
                              type={type}                            
                              id="agree"
                             label={''}
							  required                              
                            className="float-left"/>
                          
                          </div>
                            
                          ))}
                          <div>I agree with  <a href="/terms-and-conditions" target="_new" style={{color:'blue'}}>terms and conditions.</a></div>
                        </Form.Group>
                      <div class="form-group" style={{'text-align': 'center'}}>
                      <Button variant="primary" type="submit" id="regSubmit"  >
                        {this.state.regSubmitText}
                      </Button>
                      </div>
                      {this.state.loaderDiv?(<div style={{textAlign:"center",'float':'right'}} ref="loaderdiv">
                      <PageLoader/>  
                      </div>):""}
                    </Form>
                    <br/>
                    {this.state.registerError!==''?(<div className="alert alert-danger"><strong>{this.state.registerError}</strong></div>):(null)}
                    </Tab>
					<Tab eventKey="login" title="Login" style={{color:'#000'}}>
                    {this.state.verifyMessage!==''?(<div className="alert alert-success"><strong>{this.state.verifyMessage}</strong></div>):(null)}
                    <Form method="post" id="login" name="login" validated={validated} onSubmit={(event) => this.handleClick(event,this.state.username,this.state.loginPassword,this.state.remember)}>
                      {this.state.loginError!==''?(<div className="alert alert-danger"><strong>{this.state.loginError}</strong></div>):(null)}
                        <Form.Group controlId="username"> 
                        <Form.Label id='signUpEmail'>Email<sup>*</sup></Form.Label>               
                          <Form.Control aria-labelledby="signUpEmail" type="email" placeholder="Your Email Address" className={this.state.username?'remClass':''}  id="username" autocomplete="off" value={this.state.username} required onChange={this.handleChange} />                
                        </Form.Group>
                        <Form.Group controlId="login_password"> 
                        <Form.Label id='signUpPassword'>Password<sup>*</sup></Form.Label>            
                          <Form.Control type="password" aria-labelledby="signUpPassword" placeholder="Password" id="loginPassword" required autocomplete="off" value={this.state.loginPassword}  onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId="login_password">
                          {['checkbox'].map(type => (
                          <div key={`custom-${type}`} className="mb-3">
                            <Form.Check 
                              custom
                              type={type}
                              autocomplete="off" onChange={this.handleChange} 
                              id="remember"
                              label={`Remember me`}
                            />
                          </div>
                          ))}
                        </Form.Group>
                        <div className="forgetCls" style={{'paddingBottom':'9%'}}>
                        <div style={{'float':'left','marginRight':'10px'}}>
                        <Button variant="primary" type="submit"  id="loginSubmit" >
                        {this.state.loginSubmitText}
                        </Button>
                        </div>
                        <div style={{'float':'left'}}>
                        {this.state.loginloaderDiv?(<div style={{textAlign:"center",'float':'left'}} ref="loaderdiv">
                        <SmallPageLoader/>  
                        </div>):""}
                        <Link to="/forget-password" className="forgot-password">Forgot password?</Link>
                        </div>
                        </div>
                    </Form>
                    </Tab>
                </Tabs>
                </div>
              </div>
      <InnerFooter/>
      </div>
    );
  }
}

const style = {
 margin: 15,
};

export default Login;