/*
  _____________________
 |                     |
 |     Scholership     |
 |_____________________|
                        */

// Basic Lib
import React, { Component } from 'react';
import { Redirect, Link} from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';
import '../savedScholarship.css';
// Required component
import {PageLoader,SmallPageLoader} from './loaders/PageLoader'; // Page Loader
import ShowMoreText from 'react-show-more-text'; // Show more text
import Autocomplete from 'react-autocomplete'; // Auto-complete

// Include Component
import ScholarshipHeader from './layout/scholarshipHeader';
import ScholarshipPromo from './layout/scholarshipPromo';
import InnerFooter from './layout/InnerFooter';

// Slider Lib
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

// Tab Lib
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Card} from "react-bootstrap";
import WOW from "wowjs";

// Date Range picker
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

// Services
import {postApiData,getApiData} from '../services/Api';

const history = createBrowserHistory();

class SavedScholarships extends Component {
    
  constructor(props){

    super(props);
    this.state={
      // Listing Var
      scholership: [],
      award:"",
      // Design Var
      horizontal: 10,
      // Redirect
      redirect: false,
      // Auto complete
      loading: false,
      value:"",
      // Date Range
      dateRange:"",
      startRange: new Date(),
      endRange: new Date(),
      //Loader
      loaderDiv : true,
      // Local Storage
      isPaid: localStorage.getItem('paid'),
      loggedin:reactLocalStorage.get('loggedin'),
      user_id: localStorage.getItem('userId'),
      tabIndex :0,
	  msg: '',
	  search_keyword:'',
    }

    // Bind functions
    this.requestTimer = null;
    this.startApply = this.startApply.bind(this);
	this.removeScholarship = this.removeScholarship.bind(this);
    this.handleDateRangeEvent = this.handleDateRangeEvent.bind(this);
    this.handleDateRangeBlank = this.handleDateRangeBlank.bind(this);
    this.redirectPayment = this.redirectPayment.bind(this);
    
  }
  
  /* handle for scholarship_name */
//   handleSearch  = e => {
// 	this.setState({
// 		search_keyword:e.target.value
// 	},() => {
// 		this.getScholerships();
// 	});
	
//   };


handleChange  = e => {
	const {id, value} = e.target
	this.setState({
		search_keyword:value,
	})
   }

   /* handle for scholarship_name */
	handleSearch  = () => {
		this.setState({
                currentPage : 1
            }, () => {
                this.getScholerships();
            })
	};

	onKeyEnter = (e) => {
		if(e.keyCode === 13) {
			this.setState({
                currentPage : 1
            }, () => {
                this.getScholerships();
            })
		}
	}


  /**
   * Slider Change Event.
   *
   * @Set values
  */
  handleChangeHorizontal = value => {
		this.setState({ horizontal: value });
		this.setState({award:value});
		this.getScholerships(value);
  };

  /**
   * Get Scholership listing.
   *
   * @return error/success
  */
  getScholerships(award=""){
    // Set var
    var self = this;
    this.setState({loaderDiv: true});
	
    // Set post data
    let where =[{url:"/get-scholership"}]; // Service param
    var form = new FormData();
	form.append('search_keyword', this.state.search_keyword);
    form.append('award',award?award:this.state.award);
    form.append('deadline',this.state.dateRange);	
	form.append('saved', true);
	
    // Post parameters on service URL
    postApiData(where,form).then(response => {
      if(response.status==200 && response.data.success==true)
	  {
        this.setState({ scholership: response.data.scholarships });
      }
	  else
	  {
        this.setState({ scholership: 0 });
      }
      this.setState({loaderDiv: false});
    })
    .catch(function (error) {
      console.log("In catch : "+error);
    });
  }

  /**
   * Start Apply for scholarship.
   *
   * Log and redirect
  */
  startApply(link,id){    
    // Set var
    var self = this;
    let divId = "loaderdiv_"+id;
    var applyElm = document.getElementById(divId);
    applyElm.innerHTML = "Applying...";

    // Set post data
    let where =[{url:"/save-apply-hit"}]; // Service param
    var form = new FormData();
    form.append('scholarship_id',id);
    form.append('hit_url',link);
    form.append('hit_from','scholarships');
var popup_window=window.open(link);            
			try {
				popup_window.focus();   
    // Post parameters on service URL
    postApiData(where,form).then(response => {
		if(response.status==200 && response.data.success==true)
		{
			applyElm.innerHTML = "Apply";
			//window.open(link, '_blank');
			/* var a = document.createElement('a');
		     a.href = link;
		     a.setAttribute('target', '_blank');
		     a.click();	   */
			self.setState({msg : response.data.msg});
			setTimeout(function() { self.setState({msg: '' }); }.bind(this),	3000);
		}
		else
		{
			self.setState({msg : response.data.msg});
		}	
    })
    .catch(function (error) {
      applyElm.innerHTML = "Apply";
      console.log("In catch : "+error);
    });
} catch (e) {
			//	alert("Alert - Pop-up Blocked! Please enable 'Blocked Popups' in your phone. Inorder to do so, go to your phone's Settings>>Block Pop-ups>>Reset it to 'OFF/Disable' & you are done!!");
			}
  }
  
  /**
   * Remove scholarship from saved list.
   *
  */
  removeScholarship(id){
    // Set var
    var self = this;
    let divId = "rmloaderdiv_"+id;
    var removeElm = document.getElementById(divId);
    removeElm.innerHTML = "Processing...";
    // Set post data
    let where =[{url:"/remove-scholarship"}]; // Service param
    var form = new FormData();
    form.append('scholarship_id', id);

    // Post parameters on service URL
    postApiData(where,form).then(response => {	
	
		if(response.status==200 && response.data.success==true)
		{
			removeElm.disabled = true;
			self.setState({msg: response.data.msg});

			// Hide success/error message without refresh after 3 seconds 
			setTimeout(function() { self.setState({msg: '' }); }.bind(this), 3000);
			self.getScholerships();
		}
		else
		{
			self.setState({msg : response.data.msg});
		}			
    })
    .catch(function (error) {
      removeElm.innerHTML = "Remove";
      console.log("In catch : "+error);
    });
  }

  redirectPayment(){
	localStorage.removeItem('plan');
    this.setState({ redirect: true }); // Set redirect status
  }

  /**
   * On Load action.
   *
   * Set Default
  */
  componentDidMount(){
    this.getScholerships();
    new WOW.WOW().init();
    window.scrollTo(0, 0); // Page Set to form start
  }
  
  async openNav() {
      document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
      document.getElementById("myNav").style.width = "0%";
  }

  /* Show More/Less Event */
  executeOnClick(isExpanded) {
    console.log(isExpanded);
  }

  /* Date Range Event */
  handleDateRangeEvent(event, picker) {
      this.setState({ dateRange: moment(picker.startDate).format("MM/DD/YY")+" - "+moment(picker.endDate).format("MM/DD/YY") });
      this.getScholerships();
  }

  /* Date Range Event */
  handleDateRangeBlank(event, picker){
    this.setState({ dateRange:""});
    this.getScholerships();
  }
  
  render() {
    
	// if profile not completed then redirect schooling standard
	if(reactLocalStorage.get('profile_status')!=1)
	{
		return <Redirect to='/scholarships'/>;
	}
	
    // Redirection
    if(this.state.redirect) 
    {
      return <Redirect to='/payment'/>;
    }

    var scholershipSet 		= "No saved scholarship found.";
	var scholershipSetList 	= "No saved scholarship found.";
	
	if(this.state.scholership.length >0)
	{
		/* Prepare content of grid view */
		scholershipSet = [this.state.scholership.map((item,i) => {
			return  <Col lg="6">
						<Card className="scholarship-card">
							<Card.Body>
								<Card.Link href={item.weblink} target="_blank">
									<Card.Title id="cardTitle">{item.scholarship_name}</Card.Title>
								</Card.Link>
								<Card.Text>
								  <ShowMoreText
								  lines={3}
								  more='more'
								  less='less'
								  anchorClass=''
								  onClick={this.executeOnClick}
								  >
								  {item.description}
								  </ShowMoreText>
								</Card.Text>
								<div className="price-value clearfix">
									<div className="price">{ (item.award > 0 ? '$' + item.award : item.award)}</div>
									<div className="time">
									  Deadline: {item.deadline}<br/>
									  One Time Award
									</div>
								</div>
							</Card.Body>
							<Card.Footer className="text-muted"> 
								<div style={{"textAlign" : "center"}}>
								  <div style={{textAlign:"center","display":"none","float": "right","marginRight": "10px"}}  ref="loaderdiv">
								  <SmallPageLoader/>  
								  </div>
								  <Card.Link class="btn btn-success float-left" id="applybtn" href="javascript:void(0)" onClick={ () => this.startApply(item.weblink,item.id)}><div className="applyCls" id={"loaderdiv_"+item.id}>Apply</div></Card.Link>
								  
								<Button variant="success float-right" className="custom-btn" onClick={ () => this.removeScholarship(item.id)} id={"rmloaderdiv_"+item.id} >Remove</Button>
								</div>
							</Card.Footer>
						</Card>
					</Col>
		})];
		
		
		/* Prepare content of list view */		
		scholershipSetList = [this.state.scholership.map((item,i) => {
		return <Row>
				  <Col lg={6} className="schGridCol"><u><a href={item.weblink} target="_blank" style={{color: '#010167'}}>{item.scholarship_name}</a></u></Col>
				  <Col className="schGridCol">{ (item.award > 0 ? '$' + item.award : item.award)}</Col>
				  <Col className="schGridCol">{item.deadline}</Col>
				  <Col className="schGridCol">                    
					<div>
					  <div style={{textAlign:"center","display":"none","float": "right","marginRight": "10px"}}  ref="loaderdiv">
					  <SmallPageLoader/>  
					  </div>
					  <Card.Link href="javascript:void(0)" className="custom-btn" onClick={ () => this.startApply(item.weblink,item.id)} id={"loaderdiv_"+item.id} >Apply</Card.Link>
					</div>
				  </Col>
				  <Col className="schGridCol">
					<div  id={"save_"+item.id} style={{marginTop:'-5px'}}>
					  <div style={{textAlign:"center","display":"none","float": "left","marginRight": "10px"}}  ref="loaderdiv">
					  <SmallPageLoader/>  
					  </div>
						<Button variant="success" className="custom-btn" style={{'marginTop': '0px','padding': '4px 20px'}} onClick={ () => this.removeScholarship(item.id)} id={"rmloaderdiv_"+item.id}>Remove</Button>						
					</div>
				  </Col>
			  </Row>          
		})];
	}

    const handleHide = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    const { horizontal} = this.state
    const horizontalLabels = {
      0: 'Low',
      50: 'Medium',
      100: 'High'
    }
    const formatkg = value => '$ '+value
    return (
			  <div>
				  <ScholarshipHeader/><main role="main">
					<Container fluid className="schloarships-section" id="info-section">
						<Row>
							<Col lg={3} >
								<div className="filter-sidebar">
									<Form>
										<h5><label id="searchText" for="Search Scholarships">Search Keyword</label></h5>
										<Form.Group style={{display: 'inline-block'}}>
										<InputGroup className="mb-2 mr-sm-2" style={{width:'265px'}}>
												<FormControl type="text" aria-labelledby="searchText" id="search_keyword" onChange={this.handleChange} onKeyDown={this.onKeyEnter} value={this.state.search_keyword}  />
												<InputGroup.Append>
												<InputGroup.Text><img src="/search.png" alt="Search"  onClick={this.handleSearch} width="29" alt="search"></img></InputGroup.Text>
												</InputGroup.Append>
												e.g. International, Military, Fine Arts, Disabilities etc.
											</InputGroup>
										</Form.Group>

										<h5><label id="deadline" for="Search deadline">Scholarship Deadline</label></h5>
										<Form.Group controlId="exampleForm.ControlSelect1"> 
											<DateRangePicker  startDate={this.state.startRange} minDate={moment()} endDate={this.state.endRange} onApply={this.handleDateRangeEvent}
											onCancel={this.handleDateRangeBlank} >
											<Form.Control type="text" aria-labelledby="deadline" id="date_range" value={this.state.dateRange} /> 
											</DateRangePicker>
										</Form.Group>
										{/* 
										<h5>Minimum Award Amount</h5>
										<Form.Group>
											<div className='slider custom-labels'>
												<Slider
												min={0}
												max={50000}
												value={horizontal}
												labels={true}
												format={formatkg}
												handleLabel={false}
												onChange={this.handleChangeHorizontal}
												/>
												<div className='value'>{formatkg(horizontal)}</div>
											</div>
										</Form.Group>
										*/}										
									</Form>					
								</div>
								<p style={{padding:'0% 4%'}}>
								<img src="/info.png" alt="information" />
										<span style={{paddingLeft:'5PX'}}></span>The saved scholarships page is a great place to keep scholarships that you plan to apply for later as well as scholarships that are cyclical. Cyclical means you can apply each year. This is a great place to keep track of things and you can save as many scholarships as you'd like.
									</p>
							</Col>
							<Col lg={9}>
								<div className="schloarship-list">
								<h1 style={{'color':'#212529'}}>Saved Scholarships</h1>
                  <hr/>
									<Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })} >
										<TabList>
											<Tab>Grid View</Tab>
											<Tab>List View</Tab>
										</TabList>
										{this.state.msg!==''?(<div className="alert alert-success"><strong>{this.state.msg}</strong></div>):(null)}
										<TabPanel> 
											<Row>
											{this.state.loaderDiv?
											(<div style={{textAlign:"center"}} ref="loaderdiv"><PageLoader/></div>)
											:
											scholershipSet
											}
											
											{/* (this.state.isPaid != 'Y') &&
											<Container>
												<div className="create-account-section text-center">
													<p style={{marginBottom:'20px', fontSize: '18px'}}>There are thousands of scholarships waiting just for you. Upgrade here to start finding money today!</p>
													<Button variant="default" onClick={this.redirectPayment} >Upgrade</Button>
												</div>
											</Container>
											*/}
											</Row>
										</TabPanel>
										<TabPanel> 
											<Row>
												<Container>
													{ this.state.scholership.length >0 &&
													<Row>
														<Col lg={6} className="schGridHead" >Scholarship name</Col>
														<Col className="schGridHead">Award</Col>
														<Col className="schGridHead">Deadline</Col>
														<Col className="schGridHead">Apply</Col>
													</Row>
													}													
													{scholershipSetList}
												</Container>
												
												{/* (this.state.isPaid != 'Y') &&
												<Container>
													<div className="create-account-section text-center">
														<p style={{marginBottom:'20px', fontSize: '18px'}}>There are thousands of scholarships waiting just for you. Upgrade here to start finding money today!</p>
														<Button variant="default" onClick={this.redirectPayment}>Upgrade</Button>
													</div>
												</Container>
												*/}												
											</Row>
										</TabPanel>
									</Tabs>
								</div>
							</Col>
						</Row>
					</Container>
					</main>
				<InnerFooter/>
			</div>
		);
	}
}
export default SavedScholarships;