/* _________________________________________________
  |                                                 |
  |             Forget Password                     |
  |_________________________________________________|
                                                                */

import React, { Component } from 'react';
import InnerHeader from './layout/InnerHeader';
import InnerFooter from './layout/InnerFooter';
import { Redirect, browserHistory,router } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import '../login.css';
import createBrowserHistory from 'history/createBrowserHistory';
import 'bootstrap/dist/css/bootstrap.css';
import {PageLoader,SmallPageLoader} from './loaders/PageLoader'; // Page Loader
import {Container, Row, Col, Navbar, Nav, Tab, Tabs, Button, InputGroup, FormControl, Form, Modal, Popover, Tooltip} from "react-bootstrap";
import {Link} from "react-router-dom";
import WOW from "wowjs";
import {postApiData,getApiData} from '../services/Api';

// default Base Urls
import config from 'react-global-configuration';

const history = createBrowserHistory();

class Forget extends Component {
  
  constructor(props){

    super(props);
    
    this.state={
      redirect: false,
      loggedin:false,
      error :"",
      success : "",
      isLogin: localStorage.getItem('userId'),
      isLead: localStorage.getItem('lead_id'),
      isLeadEmail: localStorage.getItem('lead_email'),
      name: this.props.match.params.name,
      type: this.props.match.params.type,
      // local storage Checks
      loggedin:false,
      isLogin: localStorage.getItem('userId'),
    }
    
  }

  /**
   * On Click validate login.
   *
   * @return status
  */
  handleClick(event,username){

    event.preventDefault();

    // Validation check
    const form = event.currentTarget;
    if (form.checkValidity() === false) {event.stopPropagation();}
    this.setState({ validated: true });

    // Set Var
    var self = this;
    let currentComponent = this;
    let where =[{url:"/recover-password"}]; // Service param
    var formData = new FormData(); // Set post data
    formData.append('email',username);
    formData.append("front_uri",config.get('baseFrontUrl')+"/accountrecovery/");

    currentComponent.setState({ loginloaderDiv: true });
    // Post parameters on service URL...
    postApiData(where,formData,true).then(response => {
      if(response.status === 200 && response.data.success==true)
	  {
        self.setState({ success: response.data.msg });
        self.setState({ error: '' });
        self.setState({ loginloaderDiv: false });
      }else {
        self.setState({ error: response.data.msg });
        self.setState({ success: '' });
        self.setState({ loginloaderDiv: false });
      }
    })
    .catch(function (error) {
      self.setState({ error: "Please enter your valid Email address." }); // Set redirect status
      self.setState({ loginloaderDiv: false });
    });

  }
 
  async openNav() {
  document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
  document.getElementById("myNav").style.width = "0%";
  }
  
  /* Handle Blank Spaces */
  handleChange = event => {
    let eleVal = event.target.value.trim();
    if(eleVal==""){document.getElementById(event.target.id).value = eleVal;}
    this.setState({[event.target.id]: eleVal});
  }

  componentDidMount(){
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }

  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {

    if(this.state.loggedin || this.state.isLogin){
      return <Redirect to='/login'/>;
    }

    const handleHide = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    const { validated } = this.state;

    return (
      <div style={{background:'url(/login-bg.jpg) no-repeat', backgroundSize:'cover', paddingBottom:'1px'}}>
      <InnerHeader/>
      <div  style={{minHeight:'500px',paddingTop:'100px'}}>
        <div className="login-section">
          <Tabs>
            <Tab eventKey="login" title="Let's get you into your account">
            {this.state.success!==''?(<div className="alert alert-success"><strong>{this.state.success}</strong></div>):
            <Form method="post" id="login" name="login" validated={validated} onSubmit={(event) => this.handleClick(event,this.state.username)}>
              {this.state.error!==''?(<div className="alert alert-danger"><strong>{this.state.error}</strong></div>):(null)}
             
                <Form.Group controlId="username"> 
                <Form.Label id='forgetEmail'>Email<sup>*</sup></Form.Label>           
                  <Form.Control type="email" aria-labelledby="forgetEmail" placeholder="Enter Your Email Address" className={this.state.username?'remClass':''}  id="username" autocomplete="off" value={this.state.username} required onChange={this.handleChange} />                
                </Form.Group>
                <div style={{'padding-bottom':'9%'}}>
                <div style={{'float':'left'}}>
                <Button variant="primary" type="submit" id="b1" >
                  Continue
                </Button>
                </div>
                <div>
                {this.state.loginloaderDiv?(<div style={{textAlign:"center",'float':'left'}} ref="loaderdiv">
                <SmallPageLoader/>  
                </div>):""}
                </div>
                </div>
            </Form>}
            </Tab>
           </Tabs>
        </div>
      </div>
      <InnerFooter/>
      </div>
    );
  }
}

const style = {
 margin: 15,
};

export default Forget;