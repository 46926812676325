/*
|--------------------------------------------------------------------------
| User Edit Activity
|--------------------------------------------------------------------------
|
| To complete user registration.
| 
*/

import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import { Redirect }from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import { LayoutProvider } from 'react-page-layout';
import { Page, Section } from 'react-page-layout';
import axios from 'axios';
import PublicLayout from './layout/PublicLayout';
import 'bootstrap/dist/css/bootstrap.css';
const layouts = {'public': PublicLayout};

const User=[]

class EditUser extends Component {
  
  constructor(props){
  
    super(props);
  
    this.state={
      first_name:'',
      last_name:'',
      email:'',
      state_of_residence:'',
      age:'',
      sex:'',
      hair_color:'',
      eye_color:'',
      race_or_nationality:'',
      current_school_attending:'',
      current_grade:'',
      GPA:'',
      college_or_university_attending:'',
      college_major:'',
      religion:'',
      disease_or_illness:'',
      family_disease_or_illness:'',
      hobbies:'',
      User:[],
      States:[],
      Plans:[],
      isLogin: localStorage.getItem('userId'),
      updated: false
    }

    this.state.idval=this.props.match.params.id;
  }
  
  /**
   * On Click validate and register user.
   *
   * @return error/success
  */
  handleClick(event){

    // Set Var
    var apiBaseUrl = window.env.API_URL; // Service URL
    let thisComponent = this;

    // Set post data
    var payload=
    {
      "data": {
              "first_name": this.state.first_name,
              "last_name":this.state.last_name,
              "state_of_residence":this.state.state_of_residence,
              "age":this.state.age,
              "sex":this.state.sex,
              "hair_color":this.state.hair_color,
              "eye_color":this.state.eye_color,
              "race_or_nationality":this.state.race_or_nationality,
              "current_school_attending":this.state.current_school_attending,
              "current_grade":this.state.current_grade,
              "GPA":this.state.GPA,
              "college_or_university_attending":this.state.college_or_university_attending,
              "college_major":this.state.college_major,
              "religion":this.state.religion,
              "disease_or_illness":this.state.disease_or_illness,
              "family_disease_or_illness":this.state.family_disease_or_illness,
              "hobbies":this.state.hobbies,
              "membership_type":this.state.membership_type,
              },
              
      "editId": localStorage.getItem('userId')
    }

    // Post parameters on service URL
    axios.post(apiBaseUrl+'/user-update', payload).then(function (response) {
      if(response.data.status === 200){
      alert(response.data.message);
      thisComponent.setState({updated:true});
      }else{
      alert('Something Went wrong, please try after sometime');
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  /**
   * On load.
   *
   * Set Dom values
  */
  componentWillMount(){

    // Set var
    var apiBaseUrl = window.env.API_URL; // Service URL
    let currentComponent = this;
    let user_id = localStorage.getItem('userId');
    var payload={"id":user_id} // Post item

    // Set post header
    let postHeader = {
      headers: {
        Origin: 'scholership',
        Accept : 'application/json'
      }
    }

    // Post parameters on service URL to get user details
    axios.post(apiBaseUrl+'get-user', payload, postHeader).then(function (response) {
      if(response.data === 204){
        alert("No record Found");
      }else{
        currentComponent.setState({User:response.data.result.users});
        currentComponent.setState({States:response.data.result.states});
        currentComponent.setState({Plans:response.data.result.plans});
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  /**
   * Display login UI.
   *
   * @return view
  */
  render() {

    // Auth redirection
    if(!this.state.isLogin)
    return(<Redirect to='/login'  />); 

    if(localStorage.getItem('paid') == 'N'){
      return <Redirect to='/payment'/>;
    }

    // Dynamic state selection using conditional .map
    let stateOptionItems =  
    <select  onChange={(event) => this.setState({state_of_residence:event.target.value})} >
      <option key="" value="">--Select--</option>
      {
        this.state.States.map(States => 
          {
            return this.state.User.state_of_residence == States.id ?
            <option key={States.id}  selected value={States.id}>{States.state_name}</option>
            :
            <option key={States.id}   value={States.id}>{States.state_name}</option>
          }
        )
      }
    </select>;

    // Dynamic plan selection using conditional .map
    let planOptions =  
    <select  onChange={(event) => this.setState({membership_type:event.target.value})} >
      <option key="" value="">--Select--</option>
      {
        this.state.Plans.map(membership => 
          {
            return this.state.User.membership_type == membership.id ?
            <option key={membership.id}  selected value={membership.id}>{membership.membership_name}</option>
            :
            <option key={membership.id}   value={membership.id}>{membership.membership_name}</option>
          }
        )
      }
    </select>;

    // Return View
    return (
      <LayoutProvider layouts={layouts}>
        <Page layout="public">
            <Section slot="main">
              <div>
              <MuiThemeProvider>
                <div>
                  <AppBar title="Edit User"/>           
                        <div key={this.state.User.id}>
                          <TextField
                          hintText="First Name"
                          floatingLabelText="First Name"
                          id="first_name"
                          onChange={(event,newValue) => this.setState({first_name:newValue})}
                          defaultValue={this.state.User.first_name}
                          />
                          <br/>
                          <TextField
                          hintText="Last name"
                          floatingLabelText="Last name"
                          id="last_name"
                          onChange={(event,newValue) => this.setState({last_name:newValue})}
                          defaultValue={this.state.User.last_name}
                          />
                          <br/>
                          <TextField
                          hintText="Email"
                          type="text"
                          floatingLabelText="Email"
                          id="email"
                          onChange={(event,newValue) => this.setState({email:newValue})}
                          defaultValue={this.state.User.email}
                          />
                          <br/>
                          {stateOptionItems}
                          <br/>
                          <TextField
                          hintText="Age"
                          type="text"
                          floatingLabelText="Age"
                          id="age"
                          onChange={(event,newValue) => this.setState({age:newValue})}
                          defaultValue={this.state.User.age}
                          />
                          <br/>
                          <select defaultValue={this.state.User.sex} onChange={(event) => this.setState({sex:event.target.value})} >
                          <option key="" value="">--Select--</option>
                          <option key="M" value="M">Male</option>
                          <option key="F" value="F">Female</option>
                          </select>
                          <br/>
                          <TextField
                          hintText="Hair color"
                          type="text"
                          floatingLabelText="Hair color"
                          id="hair_color"
                          onChange={(event,newValue) => this.setState({hair_color:newValue})}
                          defaultValue={this.state.User.hair_color}
                          />
                          <br/>
                          <select defaultValue={this.state.User.eye_color} onChange={(event) => this.setState({eye_color:event.target.value})} >
                            <option value="">Select...</option>
                            <option key="amber" value="amber">Amber</option>
                            <option key="blue" value="blue">Blue</option>
                            <option key="brown" value="brown">Brown</option>
                            <option key="gray" value="gray">Gray</option>
                            <option key="green" value="green">Green</option>
                            <option key="amhazelber" value="hazel">Hazel</option>
                            <option key="red and violet" value="red and violet"> Red and violet</option>
                          </select>
                          <br/>
                          <TextField
                          hintText="Nationality"
                          type="text"
                          floatingLabelText="Nationality"
                          onChange={(event,newValue) => this.setState({race_or_nationality:newValue})}
                          defaultValue={this.state.User.race_or_nationality}
                          />
                          <br/>
                          <TextField
                          hintText="Current school attending"
                          type="text"
                          floatingLabelText="Current school attending"
                          id="eye_colocurrent_school_attending"
                          onChange={(event,newValue) => this.setState({current_school_attending:newValue})}
                          defaultValue={this.state.User.current_school_attending}
                          />
                          <br/>
                          <TextField
                          hintText="Current grade"
                          type="text"
                          floatingLabelText="Current grade"
                          id="current_grade"
                          onChange={(event,newValue) => this.setState({current_grade:newValue})}
                          defaultValue={this.state.User.current_grade}
                          />
                          <br/>
                          <TextField
                          hintText="GPA"
                          type="text"
                          floatingLabelText="GPA"
                          id="GPA"
                          onChange={(event,newValue) => this.setState({GPA:newValue})}
                          defaultValue={this.state.User.GPA}
                          />
                          <br/>
                          <TextField
                          hintText="College/University_attending"
                          type="text"
                          floatingLabelText="College/University_attending"
                          onChange={(event,newValue) => this.setState({college_or_university_attending:newValue})}
                          defaultValue={this.state.User.college_or_university_attending}
                          />
                          <br/>
                          <TextField
                          hintText="College major"
                          type="text"
                          floatingLabelText="College major"
                          onChange={(event,newValue) => this.setState({college_major:newValue})}
                          defaultValue={this.state.User.college_major}
                          />
                          <br/>
                          <TextField
                          hintText="Religion"
                          type="text"
                          floatingLabelText="Religion"
                          onChange={(event,newValue) => this.setState({religion:newValue})}
                          defaultValue={this.state.User.religion}
                          />
                          <br/>
                          <TextField
                          hintText="Disease/Ilness"
                          type="text"
                          floatingLabelText="Disease/Ilness"
                          onChange={(event,newValue) => this.setState({disease_or_illness:newValue})}
                          defaultValue={this.state.User.disease_or_illness}
                          />
                          <br/>
                          <TextField
                          hintText="Family Disease/Illness"
                          type="text"
                          floatingLabelText="Family Disease/Illness"
                          onChange={(event,newValue) => this.setState({family_disease_or_illness:newValue})}
                          defaultValue={this.state.User.family_disease_or_illness}
                          />
                          <br/>
                          <TextField
                          hintText="Hobbies"
                          type="text"
                          floatingLabelText="Hobbies"
                          onChange={(event,newValue) => this.setState({hobbies:newValue})}
                          defaultValue={this.state.User.hobbies}
                          />
                          <br/>
                          {planOptions}
                          <br/>
                          <input
                          type = "hidden"
                          value={this.state.User.id}
                          id="ids" />
                      </div>
                  
                    <br/>
                    <RaisedButton label="Submit" primary={true} style={style} onClick={(event) => this.handleClick(event)}/>
                  </div>
                </MuiThemeProvider>
              </div>
          </Section>
      </Page>

      </LayoutProvider>
    );
  }
}
const style = {
  margin: 10,
};
export default EditUser;