import React, { Component } from 'react';
import {reactLocalStorage} from 'reactjs-localstorage';
import {Row, Col} from "react-bootstrap";
import Modal from 'react-responsive-modal';
import {Redirect, Link} from "react-router-dom";
import WOW from "wowjs";

// Service lib
import {postApiData} from '../../services/Api';

class ScholarshipPromo extends Component {

	constructor(props){
		super(props);
		this.state={
		userId:reactLocalStorage.get('userId'),
		password:'',
		loggedin:reactLocalStorage.get('loggedin'),
		profileStatus:reactLocalStorage.get('profile_status'),
		open: false,
		redirect : false
		}
	}

	componentDidMount(){
	new WOW.WOW().init();
	}
    
	async openNav() {
		document.getElementById("myNav").style.width = "300px";
	}

	async  closeNav() {
		document.getElementById("myNav").style.width = "0%";
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};
 
	onCloseModal = () => {
		this.setState({ open: false });
	}
  
	handleSchoolingStandard(schoolingStandard) 
	{
		let where =[{url:"/update-standard"}]; // Service param
		var formData = new FormData();
		formData.append("standard", schoolingStandard);

		// Post parameters on service URL...
		postApiData(where,formData).then(response => {
			if(response.status === 200 && response.data.success==true)
			{
				this.setSchoolingStandard(schoolingStandard).then(res=>{					
					this.setState({redirect : true});
				});
			}
			else{
				console.log(response.data.msg);
			}
		})
		.catch(function (error) {
			console.log(error);
		});
		this.setState({ open: false });
  	}
  
	setSchoolingStandard(standard){
	  return new Promise(resolve => {
		localStorage.setItem('schooling_standard', standard);
		resolve(true);
	  });
	}
  
	render() {
		const { open } = this.state;
		
		if(this.state.redirect!='')
		{
			return <Redirect to='/personalinfo' />;
		}
		
		return (
		<div>
			<div className="bannerSecton main-banner">
				<Row>
					<Col lg={7} className="d-block d-lg-none wow slideInLeft">
						<div className="banner-img"></div>
					</Col>
					<Col lg={{ span: 4, offset:1 }} className="wow slideInLeft">
						<div className="info-widget" >
						{ this.state.userId ? <h2>You're Almost there!</h2> : <h2>{this.state.userId?'Start':'Sign Up'} Today</h2> }
                           
                            <p style={{marginBottom:'30px'}}>Start your customized match process today to see all of the matches just waiting for you!</p>
                            { this.state.userId ? 
                                this.state.profileStatus==1 ?
                                    <a className="btn btn-default" style={{"borderColor": "#fff"}}  href="/matched-scholarships">Take me to my Profile</a>
                                :
                                    <a className="btn btn-default" style={{"borderColor": "#fff"}}  onClick={this.onOpenModal}>Take me to my Profile</a>
                              : 
                                <a className="btn btn-default" style={{"borderColor": "#fff"}}  href="/login">Let's Go!</a>
                            }
						</div>
					</Col> 
					<Col lg={7} className="d-none d-lg-block wow slideInRight">
						<div className="banner-img" style={{background:'url(scholarships-banner.jpg) no-repeat', backgroundSize:'cover'}}></div>
					</Col>
				</Row>
				<Modal open={open} onClose={this.onCloseModal} center>
					<div className="modal-box">
						<h2 style={{fontSize:'1.9em'}}>Tell us more so we can help find scholarships <br/> that are the perfect match for you...</h2>
						{ this.state.userId ? 
							<div className="clearfix">
								<a href="#" onClick={() => this.handleSchoolingStandard(1)} className="btn btn-default float-left" >I’m in High School</a>
								<a href="#" onClick={() => this.handleSchoolingStandard(2)} className="btn btn-default float-right" >I’m in college or graduate school</a>
							</div> 
						  :
							<div className="clearfix">
								<a href="/login" className="btn btn-default float-left" >I'am in High School</a>
								<a href="/login" className="btn btn-default float-right" >I'am in college or graduate school</a>
							</div> 
						}
					</div>
				</Modal>
			</div> 
		</div>
		);
	}
}

export default ScholarshipPromo;