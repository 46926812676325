import React, { Component } from 'react';
import {reactLocalStorage} from 'reactjs-localstorage';

import {Container, Navbar, Nav, Form, FormControl} from "react-bootstrap";
import {Link} from "react-router-dom";
import '../../nav.css';

import WOW from "wowjs";

class ScholarshipHeader extends Component {

  constructor(props){
    super(props);
    this.state={
      user_id:reactLocalStorage.get('userId'),
      password:'',
      loggedin:reactLocalStorage.get('loggedin'),
	  	profileStatus:reactLocalStorage.get('profile_status'),
      first_name:reactLocalStorage.get('firstName'),
	  tabIndex:-1,
    }
	this.escFunction = this.escFunction.bind(this);
  }
  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
	  document.getElementById("myNav").style.width = "0%";
    }
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }



  componentDidMount(){
	document.addEventListener("keydown", this.escFunction, false);
	window.addEventListener('scroll', () => {
       let activeClass = 'normal';
       if(window.scrollY === 0){
           activeClass = 'top';
       }
       this.setState({ activeClass });
    });
   new WOW.WOW().init();

  }
//   onKeyEnter = (e) => {
// 	if(e.keyCode === 13) {
// 		this.setState({
// 			currentPage : 1
// 		}, () => {
			
// 			this.getScholerships();
// 		})
// 	}
// }
   myFunction() {
    var y = document.getElementById("searchFilter");
    if (y.style.display === "block") {
      y.style.display = "none";
    } else {
      y.style.display = "block";
    }
  }
      
  async openNav() {
    document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }

  render() {
    return (
		<div>

	    <header className={`top-bar ${this.state.activeClass}`}>
	            <Navbar expand="xl"  selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })} >
	            <Navbar.Brand href="/"><img src="/logo.png" alt="Imagine Shcolarship Logo" height="100px"/>{/*<img src="../assets/img/logo.svg"/>*/}</Navbar.Brand>
	            <Navbar.Toggle aria-controls="basic-navbar-nav7" />
	            <Navbar.Collapse id="basic-navbar-nav7">
	                <Nav >
									{	this.state.user_id ?
										this.state.profileStatus==1 ? 
										<>
											<Nav.Link href="/profile">Profile</Nav.Link> 
											<Nav.Link href="/matched-scholarships" >Matched Scholarships</Nav.Link> 
											<Nav.Link href="/saved-scholarships" >Saved Scholarships</Nav.Link>
											<Nav.Link href="/applied-scholarships" >Applied Scholarships</Nav.Link>
											<Nav.Link href="/interships">Internships</Nav.Link>
										</>
										: 						
										<Nav.Link href="/scholarships" >Scholarships</Nav.Link>
									:
									<>
										<Nav.Link href="/scholarships" >Scholarships</Nav.Link>
									</>
								} 

								{	this.state.user_id ?
										<>
										<Nav.Link href="https://www.imaginecollegecoaching.com/baylor-video-and-resources-library" target="_blank">Video Resources</Nav.Link> 
										<Nav.Link href="/logout" >Logout</Nav.Link> 
										</>
									:
										<Nav.Link href="/login" >Login </Nav.Link>
								}  
	              </Nav>               
	            </Navbar.Collapse>
	            <div className="ml-auto search-section" style={{minWidth:'99px'}}>
	            <Nav >
	          
	                <Nav.Link onFocus={this.openNav} onClick={this.openNav}><img alt="Menu" src="../assets/img/right-nav.svg"/></Nav.Link>
	            </Nav>    
	            </div>
	            </Navbar>
	           
	    </header>

	    <div id="searchFilter" style={{display:'none'}}>
	            <Form>
				<label id="searchLabel" for="Search"> Search</label><FormControl aria-labelledby="searchLabel" type="text" placeholder="Search" />          
	            </Form>
	    </div>


	    <div id="myNav" class="topnav" > 
	        <a href="javascript:void(0)" class="closebtn" onClick={this.closeNav}>&times;</a>
	        <div class="nav-content">
	          <a href="https://www.baylor.edu/" target="_blank">Home</a>
	          <a href="https://www.imaginecollegecoaching.com/baylor-contact-page" target="_blank">Contact</a>
	          <a href="https://www.imaginecollegecoaching.com/frequently-asked-questions"  target="_blank">FAQ</a>
	           {(this.state.user_id) ?
					this.state.profileStatus==1 ?
					<span>
						<a href="/matched-scholarships">MATCHED SCHOLARSHIPS</a>
						<a href="/saved-scholarships">SAVED SCHOLARSHIPS</a>
						<a href="/applied-scholarships">APPLIED SCHOLARSHIPS</a>
						<a href="/interships">INTERNSHIPS</a> 
						{/* <a href="/membership">MANAGE SUBSCRIPTION</a> */}
						<a href="/profile">Profile</a>
					</span>
					:
					<span>
						<a href="/scholarships">GET STARTED</a>
					</span>
				  :
					<span>
						<a href="/scholarships">GET STARTED</a>
					</span>
	            }
				
	            {(this.state.user_id) ?
					<a onBlur={this.closeNav} href="/logout">LOGOUT</a>
				  :
					<span>
						
						<a  onBlur={this.closeNav} href="/login">LOGIN / SIGNUP</a>
					</span>
					
	            }
	        </div>
	    </div>

    </div>

    );
  }
}

export default ScholarshipHeader;