import React, { Component } from 'react';
import { Slot } from 'react-page-layout';
import { Redirect }from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';

class Logout extends Component {
    constructor(props){
  super(props);
  this.state={
    loggedin:false
   }
   reactLocalStorage.set('firstName', '');
   reactLocalStorage.set('userId', '');
   reactLocalStorage.set('email', '');
   reactLocalStorage.set('firstName', '');
   reactLocalStorage.set('lastName', '');
   reactLocalStorage.set('paid', '');
   reactLocalStorage.set('lead_id', '');
   reactLocalStorage.set('lead_email', '');
   reactLocalStorage.set('plan', '');
   reactLocalStorage.set('loggedin',false);
   reactLocalStorage.set('profile_status', '');
   reactLocalStorage.set('schooling_standard', '');
   reactLocalStorage.set('token', '');
   reactLocalStorage.clear();
   localStorage.clear();
 }
    render() {

        if(!this.state.loggedin)
           return(<Redirect to='/login'  />);

            }
}
export default Logout;