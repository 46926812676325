import React, { Component } from 'react';
import { Slot } from 'react-page-layout';
import {Link} from "react-router-dom";
import {reactLocalStorage} from 'reactjs-localstorage';
import {Container, Row, Col, Navbar, Nav, NavDropdown, Button, InputGroup, FormControl, Form} from "react-bootstrap";
import WOW from "wowjs";

class PublicLayout extends Component {

  constructor(props){
    super(props);
    this.state={
      user_id:reactLocalStorage.get('userId'),
      password:'',
      loggedin:reactLocalStorage.get('loggedin'),
      first_name:reactLocalStorage.get('firstName'),
    }
  }

  componentDidMount(){
   new WOW.WOW().init();
  }
    
  async openNav() {
    document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }

  render() {

  if(this.state.loggedin==='true')
  {
  return (
      <React.Fragment>
      <div style={{background:'url(/login-bg.png) no-repeat', backgroundSize:'cover'}}>
      <div id="myNav" class="topnav" > 
            <a href="javascript:void(0)" class="closebtn" onClick={this.closeNav}>&times;</a>
            <div class="nav-content">
              <a href="/">Home</a>
              <a href="http://www.imaginecollegecoaching.com/about-us-1" target="_blank">About</a>
			  <a href="http://www.imaginecollegecoaching.com/imagine-blog" target="_blank">Blog</a>
              {/*<a href="http://www.imaginecollegecoaching.com/our-services-2" target="_blank">Services</a>*/}
              <a href="http://www.imaginecollegecoaching.com/contact-form-1">Contact</a>
              <a href="/logout">LOGOUT</a>
              <a href="registration">GET STARTED</a>
              <a href="/group-registration">Group Registration</a>
            </div>
      </div>
      <header className="wow slideInLeft" style={{background:'none'}}>
        <Container>
          <Navbar collapseOnSelect expand="lg" >
              <Navbar.Brand href="/" className="logo">Imagine Scholarships</Navbar.Brand>            
                  <Nav className="mr-auto login-nav d-none d-lg-block">
                  <Nav.Link href="/logout">LOGOUT</Nav.Link>
                  <Nav.Link >Welcome {this.state.first_name}</Nav.Link>                
                  </Nav>
                  <Nav onClick={this.openNav} class="nav-icon">              
                    <div className="line"></div>
                    <div className="line small"></div>                      
                </Nav>
          </Navbar>
        </Container>
      </header>
      </div>
  <Slot name="main" />


  <footer className="wow fadeIn">
      <h4>Imagine Scholarships</h4>
      <Container>
        <Row>
          <Col md={3}>
              <h5>Navigation</h5>
              <ul>
                  <li><Link to="http://www.imaginecollegecoaching.com/about-us-1" target="_blank">About Us</Link></li>
                  <li><Link to>News</Link></li>
                  <li><Link to>Support</Link></li>
                  <li><Link to>Products</Link></li>
              </ul>
          </Col>
          <Col md={3}>
              <h5>ABOUT US</h5>
              <ul>
                  <li><Link to="http://www.imaginecollegecoaching.com/about-us-1" target="_blank">Who we are</Link></li>
                  <li><a to="http://www.imaginecollegecoaching.com/new-page-3" target="_blank">Our team</a></li>
                  <li><Link to>Careers</Link></li>
                  <li><Link to>Investors</Link></li>
              </ul>
          </Col>
          <Col md={3}>
              <h5>Support</h5>
              <ul>
                  <li><Link to>Documentation</Link></li>
                  <li><Link to>Community</Link></li>
                  <li><Link to>Careers</Link></li>
                  <li><Link to>Get in Touch</Link></li>
              </ul>
          </Col>
          <Col md={3}>
              <h5>Subscribe to our newsletter</h5>
              <p>Receive our latest news and promotions in your inbox!</p>
                <Form>
                  <InputGroup>
                    <FormControl
                      placeholder="Your email address"
                      aria-label="Your email address"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                      <Button variant="outline-secondary"></Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>  
          </Col>
      </Row>
      <ul className="social-widget">
      <li><Link to ><svg width="26" height="24" viewBox="0 0 26 24" className="icon" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="icon" fill-rule="evenodd" clip-rule="evenodd" d="M10.3849 19C16.4225 19 19.7249 13.6132 19.7249 8.94228C19.7249 8.78894 19.7249 8.63646 19.7153 8.48484C20.3577 7.98518 20.9121 7.36492 21.3529 6.65506C20.7545 6.94107 20.1185 7.12887 19.4681 7.21158C20.1529 6.76964 20.6657 6.07529 20.9113 5.25603C20.2665 5.66781 19.5617 5.95813 18.8265 6.11406C17.5833 4.6909 15.5041 4.62198 14.1817 5.96071C13.3297 6.82391 12.9673 8.11096 13.2321 9.33856C10.5929 9.19555 8.13371 7.85337 6.46651 5.64541C5.59531 7.26068 6.04091 9.3265 7.48331 10.3637C6.96091 10.3473 6.44971 10.1957 5.99291 9.92178V9.96657C5.99371 11.649 7.09531 13.098 8.62651 13.4314C8.14331 13.5736 7.63611 13.5942 7.14491 13.4917C7.57451 14.9321 8.80731 15.9185 10.2113 15.9469C9.04891 16.9307 7.61291 17.4649 6.13451 17.4631C5.87371 17.4623 5.61291 17.4459 5.35291 17.4123C6.85451 18.4495 8.60091 19 10.3849 18.9974" fill="#222222"/>
      </svg>
      </Link></li>
      <li><Link to ><svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="icon" fill-rule="evenodd" clip-rule="evenodd" d="M21.9412 19H18.8642V13.3967C18.8642 11.8596 18.2127 11.0022 17.0442 11.0022C15.7727 11.0022 15.0181 11.8956 15.0181 13.3967V19H11.9412V8.59243H15.0181V9.76288C15.0181 9.76288 15.9835 8 18.1589 8C20.335 8 21.9412 9.3818 21.9412 12.2415C21.9412 15.1004 21.9412 19 21.9412 19ZM7.44147 6C6.61268 6 5.94116 5.32819 5.94116 4.4997C5.94116 3.67181 6.61268 3 7.44147 3C8.26965 3 8.94116 3.67181 8.94116 4.4997C8.94178 5.32819 8.26965 6 7.44147 6ZM5.94116 19H8.94116V9H5.94116V19Z" fill="#222222"/>
      </svg>
      </Link></li>
      <li><Link to ><svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="icon"  fill-rule="evenodd" clip-rule="evenodd" d="M20.4045 10.6027H13.6773C13.6773 11.3023 13.6773 12.7015 13.673 13.4011H17.5713C17.4219 14.1007 16.8922 15.0802 16.1439 15.5734C16.1432 15.5727 16.1425 15.5776 16.141 15.5769C15.1461 16.2338 13.8331 16.3829 12.8582 16.187C11.3301 15.8833 10.1208 14.7752 9.62973 13.3675C9.63259 13.3654 9.63474 13.3459 9.63688 13.3445C9.32954 12.4714 9.32954 11.3023 9.63688 10.6027H9.63617C10.0321 9.31683 11.2779 8.14359 12.8082 7.82247C14.039 7.56152 15.4277 7.84416 16.4491 8.79982C16.5849 8.6669 18.3289 6.96405 18.4597 6.82553C14.9703 3.66541 9.38315 4.77708 7.29252 8.85789H7.29181C7.29181 8.85789 7.29252 8.85789 7.28823 8.86558C6.254 10.87 6.29689 13.2318 7.29538 15.1404C7.29252 15.1425 7.29038 15.1438 7.28823 15.1459C8.1931 16.902 9.83987 18.2487 11.824 18.7615C13.9318 19.3142 16.6142 18.9364 18.4111 17.3112C18.4118 17.3119 18.4125 17.3126 18.4132 17.3133C19.9356 15.9421 20.8834 13.6061 20.4045 10.6027Z" fill="#222222"/>
      </svg>
      </Link></li>
      </ul> 
      </Container>
  </footer>


  </React.Fragment>
        
    );
  }
  else
  {
    return (
    <React.Fragment>
    <div style={{background:'url(/login-bg.png) no-repeat', backgroundSize:'cover', paddingBottom:'1px'}}>
    <div id="myNav" class="topnav" > 
          <a href="javascript:void(0)" class="closebtn" onClick={this.closeNav}>&times;</a>
          <div class="nav-content">
          <a href="/">Home</a>
          <a href="http://www.imaginecollegecoaching.com/about-us-1" target="_blank">About</a>
		  <a href="http://www.imaginecollegecoaching.com/imagine-blog" target="_blank">Blog</a>
          {/*<a href="http://www.imaginecollegecoaching.com/our-services-2" target="_blank">Servicesass</a>*/}
          <a href="http://www.imaginecollegecoaching.com/contact-form-1" target="_blank">Contact</a>
          <a href="login">LOGIN</a>
          <a href="registration">GET STARTED</a>
          </div>
    </div>
    <header className="wow slideInLeft" style={{background:'none'}}>
      <Container>
        <Navbar collapseOnSelect expand="lg" >
            <Navbar.Brand href="/" className="logo">Imagine Scholarships</Navbar.Brand>
            
          
            <Nav className="mr-auto login-nav d-none d-lg-block">
                <Nav.Link href="#features">LOGIN</Nav.Link>
                <Nav.Link href="/scholarships">GET STARTED</Nav.Link>                
              </Nav>
                <Nav onClick={this.openNav} class="nav-icon">              
                  <div className="line"></div>
                  <div className="line small"></div>                      
              </Nav>
        </Navbar>
      </Container>
    </header> 

   
      <Slot name="main" />
      </div>
      <footer className="wow fadeIn">
      <h4>Imagine Scholarships</h4>
      <Container>
        <Row>
          <Col md={3}>
              <h5>Navigation</h5>
              <ul>
                  <li><Link to>About Us</Link></li>
                  <li><Link to>News</Link></li>
                  <li><Link to>Support</Link></li>
                  <li><Link to>Products</Link></li>
              </ul>
          </Col>
          <Col md={3}>
              <h5>ABOUT US</h5>
              <ul>
                  <li><Link to>Who we are</Link></li>
                  <li><Link to>Our team</Link></li>
                  <li><Link to>Careers</Link></li>
                  <li><Link to>Investors</Link></li>
              </ul>
          </Col>
          <Col md={3}>
              <h5>Support</h5>
              <ul>
                  <li><Link to>Documentation</Link></li>
                  <li><Link to>Community</Link></li>
                  <li><Link to>Careers</Link></li>
                  <li><Link to>Get in Touch</Link></li>
              </ul>
          </Col>
          <Col md={3}>
              <h5>Subscribe to our newsletter</h5>
              <p>Receive our latest news and promotions in your inbox!</p>
                <Form>
                  <InputGroup>
                    <FormControl
                      placeholder="Your email address"
                      aria-label="Your email address"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                      <Button variant="outline-secondary"></Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>  
          </Col>
      </Row>
      <ul className="social-widget">
      <li><Link to ><svg width="26" height="24" viewBox="0 0 26 24" className="icon" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="icon" fill-rule="evenodd" clip-rule="evenodd" d="M10.3849 19C16.4225 19 19.7249 13.6132 19.7249 8.94228C19.7249 8.78894 19.7249 8.63646 19.7153 8.48484C20.3577 7.98518 20.9121 7.36492 21.3529 6.65506C20.7545 6.94107 20.1185 7.12887 19.4681 7.21158C20.1529 6.76964 20.6657 6.07529 20.9113 5.25603C20.2665 5.66781 19.5617 5.95813 18.8265 6.11406C17.5833 4.6909 15.5041 4.62198 14.1817 5.96071C13.3297 6.82391 12.9673 8.11096 13.2321 9.33856C10.5929 9.19555 8.13371 7.85337 6.46651 5.64541C5.59531 7.26068 6.04091 9.3265 7.48331 10.3637C6.96091 10.3473 6.44971 10.1957 5.99291 9.92178V9.96657C5.99371 11.649 7.09531 13.098 8.62651 13.4314C8.14331 13.5736 7.63611 13.5942 7.14491 13.4917C7.57451 14.9321 8.80731 15.9185 10.2113 15.9469C9.04891 16.9307 7.61291 17.4649 6.13451 17.4631C5.87371 17.4623 5.61291 17.4459 5.35291 17.4123C6.85451 18.4495 8.60091 19 10.3849 18.9974" fill="#222222"/>
      </svg>
      </Link></li>
      <li><Link to ><svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="icon" fill-rule="evenodd" clip-rule="evenodd" d="M21.9412 19H18.8642V13.3967C18.8642 11.8596 18.2127 11.0022 17.0442 11.0022C15.7727 11.0022 15.0181 11.8956 15.0181 13.3967V19H11.9412V8.59243H15.0181V9.76288C15.0181 9.76288 15.9835 8 18.1589 8C20.335 8 21.9412 9.3818 21.9412 12.2415C21.9412 15.1004 21.9412 19 21.9412 19ZM7.44147 6C6.61268 6 5.94116 5.32819 5.94116 4.4997C5.94116 3.67181 6.61268 3 7.44147 3C8.26965 3 8.94116 3.67181 8.94116 4.4997C8.94178 5.32819 8.26965 6 7.44147 6ZM5.94116 19H8.94116V9H5.94116V19Z" fill="#222222"/>
      </svg>
      </Link></li>
      <li><Link to ><svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="icon"  fill-rule="evenodd" clip-rule="evenodd" d="M20.4045 10.6027H13.6773C13.6773 11.3023 13.6773 12.7015 13.673 13.4011H17.5713C17.4219 14.1007 16.8922 15.0802 16.1439 15.5734C16.1432 15.5727 16.1425 15.5776 16.141 15.5769C15.1461 16.2338 13.8331 16.3829 12.8582 16.187C11.3301 15.8833 10.1208 14.7752 9.62973 13.3675C9.63259 13.3654 9.63474 13.3459 9.63688 13.3445C9.32954 12.4714 9.32954 11.3023 9.63688 10.6027H9.63617C10.0321 9.31683 11.2779 8.14359 12.8082 7.82247C14.039 7.56152 15.4277 7.84416 16.4491 8.79982C16.5849 8.6669 18.3289 6.96405 18.4597 6.82553C14.9703 3.66541 9.38315 4.77708 7.29252 8.85789H7.29181C7.29181 8.85789 7.29252 8.85789 7.28823 8.86558C6.254 10.87 6.29689 13.2318 7.29538 15.1404C7.29252 15.1425 7.29038 15.1438 7.28823 15.1459C8.1931 16.902 9.83987 18.2487 11.824 18.7615C13.9318 19.3142 16.6142 18.9364 18.4111 17.3112C18.4118 17.3119 18.4125 17.3126 18.4132 17.3133C19.9356 15.9421 20.8834 13.6061 20.4045 10.6027Z" fill="#222222"/>
      </svg>
      </Link></li>
      </ul> 
      </Container>
  </footer>


  </React.Fragment>


    );
  }
  }
  }
  export default PublicLayout;