/*
  __________________________________
 |                                  |
 |      Feedback Page     |
 |__________________________________|
                                      */
// Initial Lib
import React, { Component, useState  } from 'react';
import { Redirect, Link} from 'react-router-dom';

// Include Component
import ScholarshipHeader from './layout/scholarshipHeader';
import InnerFooter from './layout/InnerFooter';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Pagination, Tab, Collapse} from "react-bootstrap";


// Service lib
import {postApiData,getApiData} from '../services/Api';

class Feedback extends Component {
    
	constructor(props){
		super(props);
		this.state={
		  open: false,
		  encStr: this.props.match.params.id,
		  msgError: '',
		  msgSuccess: '',
		  validated: false,
		}  
	}
	
	/* Handle Blank Spaces */
	handleChange = (event) => {
		this.setState({[event.target.id]: event.target.value});
	}
  
	/**
    * On submit send feedback
    */
	handleSubmit = (event) => {

		event.preventDefault();

		// Validation check
		const form = event.currentTarget;
		if (form.checkValidity() === false) {event.stopPropagation();}
		this.setState({ validated: true });
		
		// Set Var
		var self = this;
		let where =[{url:"/student-plan-feedback"}]; // Service param
		var formData = new FormData(event.target); // Set post data
		formData.append('encStr', this.state.encStr);
		
		// Post parameters on service URL...
		postApiData(where,formData,true).then(response => {
		  if(response.status === 200 && response.data.success==true)
		  {
			self.setState({ msgSuccess: response.data.msg, msgError: '' });
		  }else{
			self.setState({ msgError: response.data.msg, msgSuccess: '' });
		  }
		  document.getElementById("frmFeedback").reset();
		  this.setState({ validated: false });
		})
		.catch(function (error) {
		  console.log(error);
		});
	}
	
	async openNav() {
		document.getElementById("myNav").style.width = "300px";
	}

	async  closeNav() {
		document.getElementById("myNav").style.width = "0%";
	}
  
	render() {  
		
		const { validated } = this.state;
		return (
			<div>
				<ScholarshipHeader/>
				<div className="bannerSecton main-banner">
					<Container >
						<Row>
							<Col lg={{ span: 8, offset: 2 }}  className="wow slideInLeft groupReg-Form">
								<div className="info-widget" style={{'marginTop':'80px', textAlign:'left'}}>
									{this.state.msgSuccess!==''?(<div className="alert alert-success"><strong>{this.state.msgSuccess}</strong></div>):(null)}
									{this.state.msgError!==''?(<div className="alert alert-danger"><strong>{this.state.msgError}</strong></div>):(null)}
									<h2 style={{fontSize:'36px'}}> How can we make imagine scholarship work for you?</h2>
									<p>Please leave your feedback here:</p>
								</div>
								<Form method="post" id="frmFeedback" name="frmFeedback" validated={validated} onSubmit={this.handleSubmit}>
									<Form.Group> 
									  {['radio'].map(type => (
										  <div key={`feedback_type-${type}`} className="mb-3">
										  <Form.Check
											custom
											name="feedback"
											value="Scholarship Services are no longer needed"
											onClick={function(){this.setState({open:false})}.bind(this)}
											label="Scholarship Services are no longer needed"
											type={type}
											id={`feedback_${type}_1`}
											required
											onChange={this.handleChange}
										  />
										  <Form.Check
											custom
											onClick={function(){this.setState({open:false})}.bind(this)}
											name="feedback"
											value="Not happy with product"
											label="Not happy with product"
											type={type}
											id={`feedback_${type}_2`}
											required
											onChange={this.handleChange}
										  />
										   <Form.Check
											onClick={function(){this.setState({open:(this.state.open==false ? true : false) })}.bind(this)}
											custom                             
											name="feedback"
											value="Other"
											label="Other"
											type={type}
											id={`feedback_${type}_3`}
											required
											onChange={this.handleChange}
										  />
									   
										</div>
									  ))}  
									</Form.Group> 
									<Form.Group className={this.state.open? "panel-collapse": "panel-collapse panel-close"}>  
										<Form.Control as="textarea" name="comments" id="comments" rows="2" style={{width: '50%', height:'100px'}} maxLength="255" onChange={this.handleChange} required={this.state.open} />
									</Form.Group>
									<Button variant="default" type="submit" id="submit" style={{'marginTop':'15px'}}>Submit</Button> 
								</Form>
							</Col>
						</Row>
					</Container>
				</div> 
				<InnerFooter/>
			</div>
		);
    }
}
export default Feedback;