/* _______________________
  |                       |
  |     Serive FIle       |
  |   Get & Post Calls    |
  |                       |
  |_______________________|
                            */
import axios from 'axios';
import config from 'react-global-configuration';

function getAuthToken() {
    return 'Bearer ' + localStorage.getItem('token');
}

export function getApiData(where) {
    
    var url = config.get('baseUrl') + where[0].url;
    if(where[0].urlParms){
        url=url+where[0].urlParms;
    }
    var bearer = getAuthToken();
    var headers={
        'Accept': 'application/json',
        'Authorization': bearer,
        'Access-Control-Allow-Origin':'*',
    };

    if(where[0].where){
        headers.filter = JSON.stringify(where[0].where);
    }

    return axios({
        url: url,
        method: 'get',
        headers: headers
    });
}

export function postApiData(where, formData) {
    
    var url = config.get('baseUrl') + where[0].url;
    
    if(where[0].urlParms){
        url=url+"/"+where[0].urlParms;
    }
	
	var bearer = getAuthToken();
    return axios({
        url: url,
        method: 'post',
       
        headers: {
            'Accept': 'application/json',
            'Authorization': bearer
        },
        data: formData
    });
}