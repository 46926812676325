/* _________________________________
  |                                 |
  |        User Profile             |
  |     User Update section         |
  |                                 |
  |_________________________________|
                                       */
// Initial Lib
import React, { Component } from 'react';

import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Include Component
import ScholarshipHeader from '../layout/scholarshipHeader';
import InnerFooter from '../layout/InnerFooter';
import DashSideNav from './DashSideNav';

// Design Lib
import {PageLoader,SmallPageLoader} from '../loaders/PageLoader'; // Page Loader
import ShowMoreText from 'react-show-more-text'; // Show more text
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Image, OverlayTrigger, Tooltip} from "react-bootstrap";
import { Redirect, browserHistory,router, Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import WOW from "wowjs";

// Service lib
import {postApiData,getApiData} from '../../services/Api';

// default Base Urls
import config from 'react-global-configuration';

const history = createBrowserHistory();

class UserProfile extends Component {
    
  constructor(props){
    super(props);
    this.state={
      profileData: [],
      states: [],
	  clubs: [],
	  citizenships : [],
	  religions : [],
	  grades : [],
	  majors : [],
	  interestList: [],
	  user_majors : [],
      currentPath : this.props.match.path,
      validatedOne: false,
      study_abroad: '',
      degree_type : '',
	  registered_as : '',	  
      races : [],
      user_races : [],  
      // Local Storage
      loggedin:reactLocalStorage.get('loggedin'),
      userId : localStorage.getItem('userId'),
	  schoolingStandard : '',
      submitBtnText: 'Find My Matches',
      errorMsg : '', 
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.phoneNumber = this.phoneNumber.bind(this);
    this.floatNumber = this.floatNumber.bind(this);
  }
  
  /*Float number validation*/
  floatNumber = (e) => {    
    if(isNaN(e.target.value)){
      e.preventDefault();
      document.getElementById(e.target.id).value = "";
      return false;
    }else{
      document.getElementById(e.target.id).value = e.target.value;
      this.setState({[e.target.id]: e.target.value});
    }
  }

  /*set the value of input on change */
  handleChange = (event) => {
    this.setState({[event.target.id]: event.target.value.trim()});	
  }  
  
  /*set the value of input on change */
  handleDegreeType = (event) => {
    this.setState({degree_type: event.target.value});
  }  
  
  /*set the value of input on change */
  handleStudyAbroad = (event) => {
    this.setState({study_abroad: event.target.value});
  }
  
  /*set the value of input on change */
  handleRegisteredAs = (event) => {
    this.setState({registered_as: event.target.value});
  }
  
  /*Phone number validation*/
  phoneNumber = (e) => {    
    var phone = e.target.value; // get values
    var key = e.charCode || e.keyCode || 0; // get Key code

    if (key !== 8 && key !== 9) { 
        if (phone.length === 3) {
          phone = phone + '-';
        }
        if (phone.length === 7) {
          phone = phone + '-';
        }
    }

    if((key == 8 || key == 9 || key == 46 || (key >= 48 && key <= 57))){ // If valid keys
    document.getElementById(e.target.id).value = phone;
    this.setState({[e.target.id]: phone});
    }else{
    e.preventDefault();
    }

  }

  /**
   * On Submit Form.
   *
   * @return status
  */
  handleSubmit(event) {
	  
    // Stop default action
    event.preventDefault();
	
    var submitElm = document.getElementById('submit');
    submitElm.disabled = true;
    this.setState({ submitBtnText: "Processing..." });	

    // Validation check
    const form = event.currentTarget;	
    if (form.checkValidity() === false) {event.stopPropagation();}
    this.setState({ validatedOne: true }); // Set validated
	
	
    // Set Var
    var self = this;
	let where =[{url:"/user-update"}]; // Set post data
    const data = new FormData(event.target); // Form data
	
    // Post parameters on service url
    postApiData(where,data,true).then(response => {
		if(response.status == 200 && response.data.success==true)
		{	
			self.setState({ redirect: true });
			if(this.detectmob()){
			  window.scrollTo(1300, 1300); // Page Set to form start On Mobile
			}else{
			  window.scrollTo(850, 850); // Page Set to form start
			}        
		}
		else
		{
			self.setState({ errorMsg: response.data.msg });
			submitElm.disabled = false;
		}
    })
    .catch(function (error) {
		submitElm.disabled = false;
		self.setState({ errorMsg : error });
    });
  }

  /**
   * Set FORM DROP DOWN values.
   *
   * @Set values
  */
  setFormElements() {
    // Set post data
    let where =[{url:"/lead-form-options"}]; // Service param
    var self = this;

    // Post parameters on service URL
    getApiData(where).then(response => {
		self.setState({ states: response.data.states,
					  races: response.data.races,
					  clubs: response.data.clubs,
					  citizenships: response.data.citizenships,
					  religions: response.data.religions,
					  majors: response.data.majors,
					  interestList : response.data.interests,
		}); // Set redirect status		
			
		if(self.state.schoolingStandard==2)
		{
			self.setState({ grades: response.data.grades.graduate }); 
		}
		else
		{
			self.setState({ grades: response.data.grades.high_school }); 
		}
    })
    .catch(function (error) {
    });
  }

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount()
  {
	var self = this;
	let where =[{url: '/get-user'}]; // Service param
	var form = new FormData();

	// Post parameters on service URL
	postApiData(where,form).then(response => {
		if(response.status == 200 && response.data.success==true)
		{

			self.setState({ profileData: response.data.users,
					degree_type : response.data.users.degree_type, 
					study_abroad : response.data.users.study_abroad,
					user_races: response.data.user_races,
					user_majors: response.data.user_majors,
					registered_as: response.data.users.registered_as,
					schoolingStandard : response.data.users.schooling_standard,
			}, ()=>{this.setFormElements()});
		}
		else
			this.setFormElements();
	})
	.catch(function (error) {
		console.log("In catch : "+error);
	});
	new WOW.WOW().init();
  }

  /**
   * Display login UI.
   *
   * @return Response
  */
  openRaceModal = () => {
    this.setState({ racePopup: true });
  };

  closeRaceModal = () => {
    this.setState({ racePopup: false });
  }
  
  openMajorModal = () => {
    this.setState({ majorPopup: true });
  };

  closeMajorModal = () => {
    this.setState({ majorPopup: false });
  }

  handleAddRaceElm = param => event => {
    const elmId = event.target.value;
    const found = this.state.user_races.some(elm => elm.id == elmId);
    const index = this.state.user_races.length + 1;
    if(!found)
    {
      this.state.user_races.push({id: elmId, race_name: param[0] });       
    } 
    else
    {
      this.state.user_races.splice( this.state.user_races.findIndex(item => item.id == elmId), 1);
    }

    /* sort races */
    var new_races = this.state.user_races.sort(function(a, b) { 
      var x = a.race_name; var y = b.race_name;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });

    this.setState({user_races: new_races});
  }
  
  handleAddMajorElm = param => event => {
    const elmId = event.target.value;
    const found = this.state.user_majors.some(elm => elm.id == elmId);
    const index = this.state.user_majors.length + 1;
    if(!found)
    {
      this.state.user_majors.push({id: elmId, major_name: param[0] });       
    } 
    else
    {
      this.state.user_majors.splice( this.state.user_majors.findIndex(item => item.id == elmId), 1);
    }

    /* sort majors */
    var new_majors = this.state.user_majors.sort(function(a, b) { 
      var x = a.major_name; var y = b.major_name;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });

    this.setState({user_majors: new_majors});
  }

  openRacePopup = (event) => {    
    event.preventDefault();    
    this.openRaceModal();
  }
  
  openMajorPopup = (event) => {    
    event.preventDefault();    
    this.openMajorModal();
  }

  handleSchoolingStandared = (e) => {
  	if(e.target.value !== '') {
  		this.setState({
  			schoolingStandard : e.target.value
  		},() => {
  			this.setFormElements()
  		})	
  	}
  	

  }
  
  render() {
	// if profile not completed then redirect schooling standard
	if(reactLocalStorage.get('profile_status')!=1)
	{
	return <Redirect to='/scholarships'/>;
	}

	if(this.state.redirect)
	{
	return <Redirect to='/matched-scholarships'/>;
	}

	const { validatedOne } = this.state;

	const { racePopup } = this.state;
	
	const { majorPopup } = this.state;

	// Set State options
	let stateOptions = [this.state.states.map((item,i) => {
	return <option value={item.id} selected={item.id==this.state.profileData.state}>{item.state_name}</option>;
	})];

	// Set Club options
	let clubOptions = [this.state.clubs.map((item,i) => {
	return <option value={item.id} selected={item.id==this.state.profileData.club_memberships}>{item.club_name}</option>;
	})];
	  
	  // Set Citizenship options
	let citizenshipOptions = [this.state.citizenships.map((item,i) => {
	return <option value={item} selected={item==this.state.profileData.citizenship_status}>{item}</option>;
	})];

	// Set Religion options
	let religionOptions = [this.state.religions.map((item,i) => {
	return <option value={item} selected={item==this.state.profileData.religion}>{item}</option>;
	})];

	// Set Grade / Education Level options (high school and graduate level)
	let gradeOptions = [this.state.grades.map((item,i) => {
	return <option value={item} selected={item==this.state.profileData.grade}>{item}</option>;
	})];
	
	// Set Interests options 
	let interestOptions = [this.state.interestList.map((item,i) => {
	return <option value={item} selected={item==this.state.profileData.interests}>{item}</option>;
	})];
      
	// Create Check boxes for Race Popup
	const self = this;
	const createPopupRaceList = [this.state.races.map((item,i) => {
	return (
			  ['checkbox'].map(type => (
				  <div key={`race-list-${type}`}>
					<Form.Check 
					  custom
					  type={type}
					  name = "race_list[]"
					  id={`race-list-${type}-${i+1}`}
					  label={item.race_name}
					  checked={this.state.user_races.some(elm => elm.id == item.id)}
					  value={item.id}
					  onClick={self.handleAddRaceElm([item.race_name])}
					/>              
				  </div>
			))
		  )
	})];

	// Create Check boxes for Race Container
	const createRaceElms = [this.state.user_races.map((item,i) => {
	return (
			  ['checkbox'].map(type => (
				  <div key={`race-${type}`} onClick={self.openRacePopup}>
					<Form.Check 
					  custom
					  type={type}
					  name ="race[]"
					  value={item.id}
					  id={`race-${type}-${i+1}`}
					  label={item.race_name}
					  defaultChecked={`true`}
					/>              
				  </div>
			))
		  )
	})];
	
	// Create Check boxes for Major / Field of Study Popup
	const createPopupMajorList = [this.state.majors.map((item,i) => {
	return (
			  ['checkbox'].map(type => (
				  <div key={`major-list-${type}`}>
					<Form.Check 
					  custom
					  type={type}
					  name = "major_list[]"
					  id={`major-list-${type}-${i+1}`}
					  label={item.major_name}
					  checked={this.state.user_majors.some(elm => elm.id == item.id)}
					  value={item.id}
					  onClick={self.handleAddMajorElm([item.major_name])}
					/>              
				  </div>
			))
		  )
	})];
	
	// Create Check boxes for Major / Field Container
	const createMajorElms = [this.state.user_majors.map((item,i) => {
	return (
			  ['checkbox'].map(type => (
				  <div key={`major-${type}`} onClick={self.openMajorPopup}>
					<Form.Check 
					  custom
					  type={type}
					  name ="major[]"
					  value={item.id}
					  id={`major-${type}-${i+1}`}
					  label={item.major_name}
					  defaultChecked={`true`}
					/>              
				  </div>
			))
		  )
	})];
   
	return (
		<div>
		  <ScholarshipHeader/>
		  <main role="main">
		  <Container fluid className="schloarships-section admin-dashboard">
			<Row>
			  <Col lg={3} >
				<DashSideNav path={this.state.currentPath} />
			  </Col>

			  <Col lg={9}>
				  <div className="schloarship-list">
								{this.state.errorMsg!==''?(<div className="alert alert-danger"><strong>{this.state.errorMsg}</strong></div>):(null)}
					  <h1 style={{'color':'#212529'}}>User Profile</h1>
					  <hr/>
					  <div className="form-wrap"> 

					 <Row>
			

			  </Row>
						<Row>
							<Col lg={12}>                   
							  <Form action="" validated={validatedOne} method="post" id="frmUpdateProfile" autocomplete="off" onSubmit={this.handleSubmit}>
								<Row><Col md={4}></Col>

								{/* <Col md={3}>
									<Form.Group >
										<Form.Label>Schooling Standard<sup>*</sup></Form.Label>&nbsp;
										
										<Form.Control as="select" name="schooling_standard" id="schooling_standard" defaultValue={this.state.schoolingStandard ? this.state.schoolingStandard : this.state.profileData.schoolingStandard} onChange={this.handleChange} onChange={this.handleSchoolingStandared} >
										<option value="1" selected={this.state.schoolingStandard == '1'} >High School</option>
										<option value="2" selected={this.state.schoolingStandard == '2'}>College/Graduate</option>
										</Form.Control>
									</Form.Group>
								  </Col>
								*/}

								  <Form.Group>
											<Form.Label>Schooling Standard <sup>*</sup></Form.Label>&nbsp;
											
											{['radio'].map(type => (
											  <div key={`custom-inline-${type}`} name="schooling_standard" id="schooling_standard" 
											  onClick={this.handleSchoolingStandared} className="mb-3">
												<Form.Check
												tabIndex="-1"
												custom
												value="1"
												type={type}
												name="schooling_standard"
												label={`High School`}
												id={`custom-inline-${type}-19`}
												checked={this.state.schoolingStandard==1}
												onClick={this.handleSchoolingStandared}
												/>
												<Form.Check
												custom
												tabIndex="1"
												inline    
												value="2"    
												type={type}
												name="schooling_standard"
												label={`College/Graduate`}
												id={`custom-inline-${type}-20`}
												checked={this.state.schoolingStandard==2}
												onClick={this.handleSchoolingStandared}
												/>
												
											  </div>
											  ))}  
											</Form.Group>



							  	
								  </Row>

								  
								<Row>
								  <Col md={6}>
									<Form.Group>
									  <Form.Label id='firstName'>First Name<sup>*</sup></Form.Label>
									  <Form.Control type="text" aria-labelledby="firstName" name="first_name" id="first_name" defaultValue={this.state.first_name ? this.state.first_name : this.state.profileData.first_name} onChange={this.handleChange} required />
									</Form.Group>
								  </Col>                    
								  <Col md={6}>
									<Form.Group >
									  <Form.Label id='lastName'>Last Name<sup>*</sup> </Form.Label>
									  <Form.Control type="text"  aria-labelledby="lastName" name="last_name" id="last_name" defaultValue={this.state.last_name ? this.state.last_name : this.state.profileData.last_name} onChange={this.handleChange} required/>
									</Form.Group>
								  </Col>
								</Row>
								<Row>
								  <Col md={6}>
									<Form.Group >
									  <Form.Label id='email'>Email<sup>*</sup></Form.Label>
									  <Form.Control type="email"  aria-labelledby="email" name="email" id="email" defaultValue={this.state.email ? this.state.email : this.state.profileData.email } onChange={this.handleChange} readOnly required/>
									</Form.Group>
								  </Col>
								  <Col md={6}>
									<Form.Group id='parentEmail1'>
									  <Form.Label>Parent Email #1<sup>*</sup></Form.Label>
									  <Form.Control type="email"  aria-labelledby="parentEmail1" name="parentEmail1" id="parentEmail1" defaultValue={this.state.parentEmail1 ? this.state.parentEmail1 : this.state.profileData.parentEmail1 } onChange={this.handleChange}  required/>
									</Form.Group>
								  </Col>  

								  <Col md={6}>
									<Form.Group id='parentEmail2'>
									  <Form.Label>Parent Email #2<sup>*</sup></Form.Label>
									  <Form.Control type="email"  aria-labelledby="parentEmail2" name="parentEmail2" id="parentEmail2" defaultValue={this.state.parentEmail2 ? this.state.parentEmail2 : this.state.profileData.parentEmail2 } onChange={this.handleChange}  />
									</Form.Group>
								  </Col>  

								  <Col md={6}>
									<Form.Group >
										<Form.Label >You are a<sup>*</sup></Form.Label>&nbsp;
										<span>
											{['right'].map(placement => (
											<OverlayTrigger
											  key={placement}
											  placement={placement}
											  overlay={
											  <Tooltip id={`tooltip-${placement}`}>
												If you opt 'Student' you have to fill your own information. If you opt 'Parent' then please fill out information based on student.
											  </Tooltip>
											  }
											>
											  <Button variant="secondary" className="info-btn">?</Button>
											</OverlayTrigger>
											))}
										</span>						
										{['radio'].map(type => (
										<div key={`custom-inline-${type}`} className="mb-3">
										  <Form.Check
											tabIndex="-1"
											custom
											inline     
											value="Student"   
											type={type}
											name="registered_as"
											label={`Student`}
											id={`custom-inline-${type}-1`}
											checked={this.state.registered_as=='Student'}
											onChange={this.handleRegisteredAs}
											required
										  />
										  <Form.Check
										  tabIndex="-1"
											custom
											type={type}
											value="Parent"
											name="registered_as"
											label={`Parent`}
											id={`custom-inline-${type}-2`}
											checked={this.state.registered_as=='Parent'}
											onChange={this.handleRegisteredAs}
											required
										  />
										</div>
										  ))}  
									</Form.Group>  
								  </Col>
								</Row>
								<Row>
								  <Col md={6}>
									  <Form.Group >
										<Form.Label id='city'>City / Town<sup>*</sup> </Form.Label>&nbsp;
										<span>
										  {['right'].map(placement => (
										  <OverlayTrigger
										 
											key={placement}
											placement={placement}
											overlay={
											<Tooltip id={`tooltip-${placement}`}>
											  Eligibility for many scholarships in our database is based upon the applicant's city or town. Please supply your city or town for accurate results.
											</Tooltip>
											}
										  >
											<Button variant="secondary" className="info-btn">?</Button>
										  </OverlayTrigger>
										  ))}
										</span>
										<Form.Control  aria-labelledby="city" type="text" name="town" id="town" defaultValue={this.state.town ? this.state.town : this.state.profileData.town} onChange={this.handleChange} required maxLength="255" />
									  </Form.Group>
								  </Col>                            
								  <Col md={6}>
									<Form.Group >
										<Form.Label id="selectState" for="Secect State">State<sup>*</sup> </Form.Label>&nbsp;
										<span>
										  {['right'].map(placement => (
										  <OverlayTrigger
											key={placement}
											placement={placement}
											overlay={
											<Tooltip id={`tooltip-${placement}`}>
											  Eligibility for many scholarships in our database is based upon the applicant's state. Please supply your state for accurate results.
											</Tooltip>
											}
										  >
											<Button variant="secondary" className="info-btn">?</Button>
										  </OverlayTrigger>
										  ))}
										</span>
										
										<Form.Control  aria-labelledby="selectState" as="select" name="state" id="state" onChange={this.handleChange} required>
										  <option value="">----Select----</option>
										  {stateOptions}
										</Form.Control>
									</Form.Group>
								  </Col>                            
								</Row>
								<Row>
								  <Col md={6}>
									<Form.Group >
									  <Form.Label id='zipCode'>Zip Code<sup>*</sup></Form.Label>
									  <Form.Control aria-labelledby="zipCode" type="text" name="zip_code" id="zip_code" maxLength="5" defaultValue={this.state.zip_code ? this.state.zip_code : this.state.profileData.zip_code } onChange={this.handleChange} required />
									</Form.Group>
								  </Col>
								  <Col md={6}>
									<Form.Group >
										<Form.Label id="selectcountry" for="select country">Country Living in<sup>*</sup></Form.Label>&nbsp;
										<span>
										  {['right'].map(placement => (
										  <OverlayTrigger
											key={placement}
											placement={placement}
											overlay={
											<Tooltip id={`tooltip-${placement}`}>
											  Eligibility for many scholarships in our database is based upon the applicant's country. Please supply your country for accurate results.
											</Tooltip>
											}
										  >
											<Button variant="secondary" className="info-btn">?</Button>
										  </OverlayTrigger>
										  ))}
										</span>
										<Form.Control  aria-labelledby="selectcountry" as="select" name="country" id="country" onChange={this.handleChange} required>
										<option value="">----Select----</option>
										<option value="USA" selected={this.state.profileData.country == 'USA'}>USA</option>
										<option value="Other" selected={this.state.profileData.country == 'Other'}>Other</option>
										</Form.Control>
									</Form.Group>
								  </Col>
								</Row>
								<Row>                         
								  <Col md={6}>
									<Form.Group >
									<Form.Label id="gender" for='select gender'>Sex</Form.Label>
									  <Form.Control  aria-labelledby="gender" as="select" name="sex" id="sex" onChange={this.handleChange}>
										<option value="" >----Select----</option>
										<option value="M" selected={this.state.profileData.sex == 'M'}>Male</option>
										<option value="F" selected={this.state.profileData.sex == 'F'}>Female</option>
									  </Form.Control>
									</Form.Group>
								  </Col>
								  <Col md={6}>
									<Form.Group >
									  <Form.Label id="selectCitizenShip" for="select citizenship">Citizenship Status<sup>*</sup></Form.Label>&nbsp;
									  <span>
										{['right'].map(placement => (
										<OverlayTrigger
										  key={placement}
										  placement={placement}
										  overlay={
										  <Tooltip id={`tooltip-${placement}`}>
											Eligibility for many scholarships in our database is based upon the applicant's citizenship. Please supply your citizenship status for accurate results.
										  </Tooltip>
										  }
										>
										  <Button variant="secondary" className="info-btn">?</Button>
										</OverlayTrigger>
										))}
									  </span>
									  <Form.Control  aria-labelledby="selectCitizenShip" as="select" name="citizenship_status" id="citizenship_status" onChange={this.handleChange} required >
										<option value="">----Select----</option>
										{citizenshipOptions}
									  </Form.Control>
									</Form.Group>
								  </Col>                            
								</Row>
								<Row>
								  <Col md={6}>
									<Form.Group >
									  <Form.Label id='religion' for="select religion">Religion</Form.Label>
									  <Form.Control  aria-labelledby="religion" as="select" name="religion" id="religion" onChange={this.handleChange}>
										<option value="">----Select----</option>
										{religionOptions}
									  </Form.Control>
									</Form.Group>
									
								  </Col>
								  <Col md={6}>
									<Form.Group className="race-panel">
									  <Form.Label>Race</Form.Label>&nbsp;
									  <span>
										{['right'].map(placement => (
										<OverlayTrigger
										  key={placement}
										  placement={placement}
										  overlay={
										  <Tooltip id={`tooltip-${placement}`}>
											Eligibility for many scholarships in our database is based upon the applicant's race.  Please supply your race for accurate results.
										  </Tooltip>
										  }
										>
										  <Button variant="secondary" className="info-btn">?</Button>
										</OverlayTrigger>
										))}
									  </span>	
									  <br />	
									  <div className="checklist">{ this.state.user_races.length > 0 ?
										createRaceElms
										:
										<span>
										<a className="btn btn-default" id="racess" style={{"borderColor": "#010167"}}  onClick={this.openRaceModal}>Select</a>
										<span id="errorRace" style={{color: 'red'}}></span>
										</span>
									  }									  
									  </div>
									</Form.Group>
								  </Col>
								</Row>

								{ /* Check for student's schooling standard of High School Student */
								  this.state.schoolingStandard==1 ?  
									<div>                       
									  <Row>  
										<Col md={6}>
										  <Form.Group >
											<Form.Label>Current Grade<sup>*</sup></Form.Label>
											<Form.Control as="select" name="grade" id="grade" onChange={this.handleChange} required>
												<option value="">----Select----</option>
												{gradeOptions}
											</Form.Control>
										  </Form.Group>
										</Col>
										<Col md={6}>
										  <Form.Group >
											<Form.Label>What school are you currently attending?</Form.Label>
											<Form.Control type="text" name="school_attending" id="school_attending" onChange={this.handleChange} defaultValue={this.state.school_attending ? this.state.school_attending : this.state.profileData.school_attending }/>
										  </Form.Group>
										</Col>
									  </Row>
									  
									  <Row>  
										<Col md={6}>
										  <Form.Group >
											<Form.Label>GPA</Form.Label>
											<Form.Control type="text" name="gpa" id="gpa" onChange={this.handleChange} defaultValue={this.state.gpa ? this.state.gpa : this.state.profileData.gpa }/>
										  </Form.Group>
										</Col>
										<Col md={6}>
										  <Form.Group >
											<Form.Label>Total SAT Score</Form.Label>
											<Form.Control type="text" name="sat_score" id="sat_score" onChange={this.handleChange} defaultValue={this.state.sat_score ? this.state.sat_score : this.state.profileData.sat_score }/>
										  </Form.Group>
										</Col>
									  </Row>
									  
									  <Row>  
										<Col md={6}>
										  <Form.Group >
											<Form.Label>Total ACT Score</Form.Label>
											<Form.Control type="text" name="act_score" id="act_score" onChange={this.handleChange} defaultValue={this.state.act_score ? this.state.act_score : this.state.profileData.act_score }/>
										  </Form.Group>
										</Col>
										<Col md={6}>
										  <Form.Label>Are you first gen college student?</Form.Label>&nbsp;
										  <span>
											{['right'].map(placement => (
											<OverlayTrigger
											  key={placement}
											  placement={placement}
											  overlay={
											  <Tooltip id={`tooltip-${placement}`}>
												If neither of your parents graduated from college, you may qualify for special programs and scholarships.
											  </Tooltip>
											  }
											>
											  <Button variant="secondary" className="info-btn">?</Button>
											</OverlayTrigger>
											))}
										  </span>
										  <Form.Control as="select" name="is_first_gen_student" id="is_first_gen_student" onChange={this.handleChange}>
											<option value="">----Select----</option>
											<option value="Yes" selected={this.state.profileData.is_first_gen_student == 'Yes'}>Yes</option>
											<option value="No" selected={this.state.profileData.is_first_gen_student == 'No'}>No</option>
										  </Form.Control>
										</Col>
									  </Row>
									  <Row>  
										<Col md={6}>
										  <Form.Label>College Type</Form.Label>&nbsp;
										  <span>
											{['right'].map(placement => (
											<OverlayTrigger
											  key={placement}
											  placement={placement}
											  overlay={
											  <Tooltip id={`tooltip-${placement}`}>
												Select the type of your college.
											  </Tooltip>
											  }
											>
											  <Button variant="secondary" className="info-btn">?</Button>
											</OverlayTrigger>
											))}
										  </span>
										  <Form.Control as="select" name="college_type" id="college_type" onChange={this.handleChange}>
											<option value="">----Select----</option>
											<option value="Online" selected={this.state.profileData.college_type == 'Online'}>Online</option>
											<option value="Campus" selected={this.state.profileData.college_type == 'Campus'}>Campus</option>
										  </Form.Control>
										</Col>
									  </Row>
									  <div className="clear">&nbsp;</div>
									  <Row>  
										<Col md={6}>
										  <Form.Label>What do you want to study in college?</Form.Label>&nbsp;
										  <span>
											{['right'].map(placement => (
											<OverlayTrigger
											  key={placement}
											  placement={placement}
											  overlay={
											  <Tooltip id={`tooltip-${placement}`}>
												Enter the subjects you want to study in college. Please enter only one subject in an input box.
											  </Tooltip>
											  }
											>
											  <Button variant="secondary" className="info-btn">?</Button>
											</OverlayTrigger>
											))}
										  </span>
										  <Form.Control type="text" name="study_subject1" id="study_subject1" onChange={this.handleChange} defaultValue={this.state.study_subject1 ? this.state.study_subject1 : this.state.profileData.study_subject1 }/>
										</Col>
										<Col md={6}>
										  <Form.Label>&nbsp;</Form.Label>
										  <Form.Control type="text" name="study_subject2" id="study_subject2" onChange={this.handleChange} defaultValue={this.state.study_subject2 ? this.state.study_subject2 : this.state.profileData.study_subject2 } />
										</Col>
									  </Row>
									  <Row>  
										<Col md={6}>
										  <Form.Label>&nbsp;</Form.Label>
										  <Form.Control type="text" name="study_subject3" id="study_subject3" onChange={this.handleChange} defaultValue={this.state.study_subject3 ? this.state.study_subject3 : this.state.profileData.study_subject3 } />
										</Col>
									  </Row>
									  <div className="clear">&nbsp;</div>
									  <Row>  
										<Col md={6}>
										  <Form.Label>Colleges that interest you?</Form.Label>&nbsp;
										  <span>
											{['right'].map(placement => (
											<OverlayTrigger
											  key={placement}
											  placement={placement}
											  overlay={
											  <Tooltip id={`tooltip-${placement}`}>
												Enter the name of the college that interest you. Please enter only one college in an input box.
											  </Tooltip>
											  }
											>
											  <Button variant="secondary" className="info-btn">?</Button>
											</OverlayTrigger>
											))}
										  </span>
										  <Form.Control type="text" name="college_of_interest1" id="college_of_interest1" onChange={this.handleChange} defaultValue={this.state.college_of_interest1 ? this.state.college_of_interest1 : this.state.profileData.college_of_interest1 }/>
										</Col>
										<Col md={6}>
										  <Form.Label>&nbsp;</Form.Label>
										  <Form.Control type="text" name="college_of_interest2" id="college_of_interest2" onChange={this.handleChange} defaultValue={this.state.college_of_interest2 ? this.state.college_of_interest2 : this.state.profileData.college_of_interest2 } />
										</Col>
									  </Row>
									  <Row>  
										<Col md={6}>
										  <Form.Label>&nbsp;</Form.Label>
										  <Form.Control type="text" name="college_of_interest3" id="college_of_interest3" onChange={this.handleChange} defaultValue={this.state.college_of_interest3 ? this.state.college_of_interest3 : this.state.profileData.college_of_interest3 } />
										</Col>
									  </Row>
									  <div className="clear">&nbsp;</div>
									  <Row>  
										<Col md={12}>
										  <Form.Group>
											<Form.Label>Type of Degree</Form.Label>&nbsp;
											<span>
											  {['right'].map(placement => (
											  <OverlayTrigger
												key={placement}
												placement={placement}
												overlay={
												<Tooltip id={`tooltip-${placement}`}>
												  Select the type of degree you are currently seeking.
												</Tooltip>
												}
											  >
												<Button variant="secondary" className="info-btn">?</Button>
											  </OverlayTrigger>
											  ))}
											</span>
											{['radio'].map(type => (
											  <div key={`custom-inline-${type}`}                                             className="mb-3">
												<Form.Check
												tabIndex="-1"
												custom
												value="Associates"
												type={type}
												name="degree_type"
												label={`Associates`}
												id={`custom-inline-${type}-10`} 
												checked={this.state.degree_type=='Associates'} 
												onChange={this.handleDegreeType}
												/>
												<Form.Check
												custom
												tabIndex="-1"
												inline    
												value="Bachelors"    
												type={type}
												name="degree_type"
												label={`Bachelors`}
												id={`custom-inline-${type}-11`}
												checked={this.state.degree_type=='Bachelors'}
												onChange={this.handleDegreeType}
												/>
												<Form.Check
												tabIndex="-1"
												custom
												inline   
												value="Masters"     
												type={type}
												name="degree_type"
												label={`Masters `}
												id={`custom-inline-${type}-12`} 
												checked={this.state.degree_type=='Masters'}
												onChange={this.handleDegreeType}
												/>
												<Form.Check
												tabIndex="-1"
												custom
												inline        
												type={type}
												value="Doctorate"
												name="degree_type"
												label={`Doctorate `}
												id={`custom-inline-${type}-13`} 
												checked={this.state.degree_type=='Doctorate'}
												onChange={this.handleDegreeType}
												/>
												<Form.Check
												tabIndex="-1"
												custom
												inline        
												type={type}
												value="Juris Doctorate"
												name="degree_type"
												label={`Juris Doctorate`}
												id={`custom-inline-${type}-14`} 
												checked={this.state.degree_type=='Juris Doctorate'}
												onChange={this.handleDegreeType}
												/>
											  </div>
											  ))}  
											</Form.Group>
										  </Col>
										</Row>
										<Row>
										  <Col md={12}>
											<Form.Group>
											  <Form.Label>Are you interested in studying abroad?</Form.Label>&nbsp;
											  <span>
												{['right'].map(placement => (
												<OverlayTrigger
												  key={placement}
												  placement={placement}
												  overlay={
												  <Tooltip id={`tooltip-${placement}`}>
													Select 'Yes' if you are interested in studying abroad.
												  </Tooltip>
												  }
												>
												  <Button variant="secondary" className="info-btn">?</Button>
												</OverlayTrigger>
												))}
											  </span>
											  {['radio'].map(type => (
											  <div key={`custom-inline-${type}`} className="mb-3">
											  <Form.Check
											  tabIndex="-1"
											  custom
											  value="Yes"
											  type={type}
											  name="study_abroad"
											  label={`Yes `}
											  id={`custom-inline-${type}-15`} 
											  defaultChecked={this.state.study_abroad=='Yes'}
											  onChange={this.handleStudyAbroad}
											  />
											  <Form.Check
											  tabIndex="-1"
											  custom
											  inline     
											  value="No"   
											  type={type}
											  name="study_abroad"
											  label={`No`}
											  id={`custom-inline-${type}-16`}
											  defaultChecked={this.state.study_abroad=='No'}
											  onChange={this.handleStudyAbroad}
											  />
											  </div>
											  ))}  
											</Form.Group>
										  </Col>
										</Row>
										<Row>  
										  <Col md={6}>                                    
											<Form.Group >
											  <Form.Label>Hobbies</Form.Label>
											  <Form.Control type="text" name="hobbies" id="hobbies" onChange={this.handleChange} defaultValue={this.state.hobbies ? this.state.hobbies : this.state.profileData.hobbies}/>
											</Form.Group>
										  </Col>
										  <Col md={6}>                                    
											<Form.Group >
											  <Form.Label>Interests</Form.Label>
											  <Form.Control as="select" name="interests" id="interests" onChange={this.handleChange}>
												<option value="" >----Select----</option>
												{interestOptions}
											  </Form.Control>
											</Form.Group>
										  </Col>
										</Row>
										<Row>  
										  <Col md={6}>                                    
											<Form.Group >
											  <Form.Label>Do you have any unique features or talents?</Form.Label>&nbsp;
											  <span>
												{['right'].map(placement => (
												<OverlayTrigger
												  key={placement}
												  placement={placement}
												  overlay={
												  <Tooltip id={`tooltip-${placement}`}>
													Tell us something that interests you and a talent that you have. You never know what kind of scholarships are already available for your unique gifts and talents.
												  </Tooltip>
												  }
												>
												  <Button variant="secondary" className="info-btn">?</Button>
												</OverlayTrigger>
												))}
											  </span>											  
											  <Form.Control type="text" name="unique_talents" id="unique_talents" onChange={this.handleChange} defaultValue={this.state.unique_talents ? this.state.unique_talents : this.state.profileData.unique_talents} />
											</Form.Group>
										  </Col>
										  <Col md={6}>
											<Form.Group >
											  <Form.Label>Organizations or Clubs you’re members of</Form.Label>
											  <Form.Control as="select" name="club_memberships" id="club_memberships" tabIndex="-1" onChange={this.handleChange} defaultValue={this.state.club_memberships ? this.state.club_memberships : this.state.profileData.club_memberships} required>
												<option>----Select----</option>
												{clubOptions}
											  </Form.Control>
											</Form.Group>
										  </Col>
										</Row>

										<Row>
										  <Col md={6}>
											<Form.Group >
											  <Form.Label>Is anyone in your immediate family part of the military?</Form.Label>&nbsp;
											  <span>
												{['right'].map(placement => (
												<OverlayTrigger
												  key={placement}
												  placement={placement}
												  overlay={
												  <Tooltip id={`tooltip-${placement}`}>
													Select 'Yes' if anyone in your immediate family is serving in Military.
												  </Tooltip>
												  }
												>
												  <Button variant="secondary" className="info-btn">?</Button>
												</OverlayTrigger>
												))}
											  </span>
												{['radio'].map(type => (
											  <div key={`custom-inline-${type}`} className="mb-3">
												<Form.Check
												tabIndex="-1"
												  custom
												  inline     
												  value="Yes"   
												  type={type}
												  name="military_affiliation"
												  label={`Yes`}
												  id={`custom-inline-${type}-17`}
												  defaultChecked={this.state.profileData.military_affiliation=='Yes'}
												  onChange={this.handleChange} 
												/>
												<Form.Check
												tabIndex="-1"
													custom
													type={type}
													value="No"
													name="military_affiliation"
													label={`No`}
													id={`custom-inline-${type}-18`}
													defaultChecked={this.state.profileData.military_affiliation=='No'}
													onChange={this.handleChange} 
												/>
											  </div>
												))}  
											</Form.Group>
										</Col>
										<Col md={6}>
										  <Form.Group >
											<Form.Label id="addition" >Additional Information</Form.Label>&nbsp;
											<span>
											  {['right'].map(placement => (
											  <OverlayTrigger
											  aria-labelledby="firstName"
												key={placement}
												placement={placement}
												overlay={
												<Tooltip id={`tooltip-${placement}`}>
												  Is there anything else you’d like for us to know as we search for your scholarships? 
												</Tooltip>
												}
											  >
												<Button variant="secondary" className="info-btn">?</Button>
											  </OverlayTrigger>
											  ))}
											</span>
											<Form.Control type="text" name="custom_word_search" id="custom_word_search" defaultValue={this.state.custom_word_search ? this.state.custom_word_search : this.state.profileData.custom_word_search } onChange={this.handleChange}/>
										  </Form.Group>
										</Col>
									  </Row>
									</div>    
								  : /* Check for student's schooling standard of Graduate or College Student */
									<div> 
									  <Row>  
										<Col md={6}>
										  <Form.Group >
											<Form.Label id="currentedu" for="select current education level">Current Education Level<sup>*</sup></Form.Label>&nbsp;
											<span>
											  {['right'].map(placement => (
											  <OverlayTrigger
												key={placement}
												placement={placement}
												overlay={
												<Tooltip id={`tooltip-${placement}`}>
												  Eligibility for many scholarships in our database is based upon the applicant's current education level.  Please supply your current education level for accurate results.
												</Tooltip>
												}
											  >
												<Button variant="secondary" className="info-btn">?</Button>
											  </OverlayTrigger>
											  ))}
											</span>
											<Form.Control  aria-labelledby="currentedu" as="select" name="grade" id="grade" onChange={this.handleChange} required>
											  <option value="">----Select----</option>
											  {gradeOptions}
											</Form.Control>
										  </Form.Group>
										</Col>
										<Col md={6}>                                    
										  <Form.Group >
											<Form.Label id='attending'>School you are attending</Form.Label>&nbsp;
											<span>
											  {['right'].map(placement => (
											  <OverlayTrigger
											  
												key={placement}
												placement={placement}
												overlay={
												<Tooltip id={`tooltip-${placement}`}>
												  Enter the name of the school you are attending.
												</Tooltip>
												}
											  >
												<Button variant="secondary" className="info-btn">?</Button>
											  </OverlayTrigger>
											  ))}
											</span>
											<Form.Control aria-labelledby="attending" type="text" name="school_attending" id="school_attending" onChange={this.handleChange} defaultValue={this.state.school_attending ? this.state.school_attending : this.state.profileData.school_attending}/>
										  </Form.Group>
										</Col>
									  </Row>
									  <Row>  
										<Col md={12}>
										  <Form.Group>
											<Form.Label>Degree Seeking</Form.Label>&nbsp;
											<span>
											  {['right'].map(placement => (
											  <OverlayTrigger
												key={placement}
												placement={placement}
												overlay={
												<Tooltip id={`tooltip-${placement}`}>
												  Select the type of degree you are currently seeking.
												</Tooltip>
												}
											  >
												<Button variant="secondary" className="info-btn">?</Button>
											  </OverlayTrigger>
											  ))}
											</span>
											{['radio'].map(type => (
											  <div key={`custom-inline-${type}`} name="degree_type" id="degree_type" 
											  onClick={this.handleDegreeType} className="mb-3">
												<Form.Check
												tabIndex="-1"
												custom
												value="Associates"
												type={type}
												name="degree_type"
												label={`Associates`}
												id={`custom-inline-${type}-10`}
												checked={this.state.degree_type=='Associates'}
												onClick={this.handleDegreeType}
												/>
												<Form.Check
												custom
												tabIndex="-1"
												inline    
												value="Bachelors"    
												type={type}
												name="degree_type"
												label={`Bachelors`}
												id={`custom-inline-${type}-11`}
												checked={this.state.degree_type=='Bachelors'}
												onClick={this.handleDegreeType}
												/>
												<Form.Check
												tabIndex="-1"
												custom
												inline   
												value="Masters"     
												type={type}
												name="degree_type"
												label={`Masters`}
												id={`custom-inline-${type}-12`}
												checked={this.state.degree_type=='Masters'}
												onClick={this.handleDegreeType}
												/>
												<Form.Check
												tabIndex="-1"
												custom
												inline        
												type={type}
												value="Professional"
												name="degree_type"
												label={`Professional`}
												id={`custom-inline-${type}-13`}
												checked={this.state.degree_type=='Professional'}
												onClick={this.handleDegreeType}
												/>
												<Form.Check
												tabIndex="-1"
												custom
												inline        
												type={type}
												value="Doctorate"
												name="degree_type"
												label={`Doctorate`}
												id={`custom-inline-${type}-14`}
												checked={this.state.degree_type=='Doctorate'}
												onClick={this.handleDegreeType}
												/>
												<Form.Check
												tabIndex="-1"
												custom
												inline        
												type={type}
												value="Professional Certification"
												name="degree_type"
												label={`Professional Certification`}
												id={`custom-inline-${type}-15`}												
												checked={this.state.degree_type=='Professional Certification'}
												onClick={this.handleDegreeType}
												/>
											  </div>
											  ))}  
											</Form.Group>
										  </Col>
										</Row>
										<Row>
										  <Col md={12}>
											<Form.Group>
											  <Form.Label>Are you interested in studying abroad?</Form.Label>&nbsp;
											  <span>
												{['right'].map(placement => (
												<OverlayTrigger
												  key={placement}
												  placement={placement}
												  overlay={
												  <Tooltip id={`tooltip-${placement}`}>
													Select 'Yes' if you are interested in studying abroad.
												  </Tooltip>
												  }
												>
												  <Button variant="secondary" className="info-btn">?</Button>
												</OverlayTrigger>
												))}
											  </span>
											  {['radio'].map(type => (
											  <div key={`custom-inline-${type}`} className="mb-3">
											  <Form.Check
											  tabIndex="-1"
											  custom
											  value="Yes"
											  type={type}
											  name="study_abroad"
											  label={`Yes `}
											  id={`custom-inline-${type}-16`}
											  checked={this.state.study_abroad == 'Yes'}
											  onClick={this.handleStudyAbroad}
											  />
											  <Form.Check
											  tabIndex="-1"
											  custom
											  inline     
											  value="No"   
											  type={type}
											  name="study_abroad"
											  label={`No`}
											  id={`custom-inline-${type}-17`}
											  checked={this.state.study_abroad == 'No'}
											  onClick={this.handleStudyAbroad}
											  />
											  </div>
											  ))}  
											</Form.Group>
										  </Col>
										</Row>
										<Row>  
										  <Col md={6}>                                    
											<Form.Group >
											  <Form.Label id='hobbies'>Hobbies</Form.Label>
											  <Form.Control aria-labelledby="hobbies" type="text" name="hobbies" id="hobbies" onChange={this.handleChange}
												defaultValue={this.state.hobbies ? this.state.hobbies: this.state.profileData.hobbies}/>
											</Form.Group>
										  </Col>
										  <Col md={6}>                                    
											<Form.Group >
											  <Form.Label id='intrest' for='select intrest'>Interests</Form.Label>
											  <Form.Control  aria-labelledby="intrest" as="select" name="interests" id="interests" onChange={this.handleChange}>
												<option value="" >----Select----</option>
												{interestOptions}
											  </Form.Control>
											</Form.Group>
										  </Col>
										</Row>
										<Row>  
										  <Col md={6}>                                    
											<Form.Group >
											  <Form.Label id='unique'>Unique features or talents</Form.Label>&nbsp;
												<span>
													{['right'].map(placement => (
													<OverlayTrigger
												
													  key={placement}
													  placement={placement}
													  overlay={
													  <Tooltip id={`tooltip-${placement}`}>
														Tell us something that interests you and a talent that you have. You never know what kind of scholarships are already available for your unique gifts and talents.
													  </Tooltip>
													  }
													>
													  <Button variant="secondary" className="info-btn">?</Button>
													</OverlayTrigger>
													))}
												</span>
											  <Form.Control 	aria-labelledby="unique" type="text" name="unique_talents" id="unique_talents" onChange={this.handleChange} defaultValue={this.state.unique_talents ? this.state.unique_talents : this.state.profileData.unique_talents}/>
											</Form.Group>
										  </Col>
										  <Col md={6}>                                    
											<Form.Group >
											  <Form.Label id='military'>Military affiliation</Form.Label>&nbsp;
											  <span>
												{['right'].map(placement => (
												<OverlayTrigger
												
												  key={placement}
												  placement={placement}
												  overlay={
												  <Tooltip id={`tooltip-${placement}`}>
													Enter if you are affiliated with military.
												  </Tooltip>
												  }
												>
												  <Button variant="secondary" className="info-btn">?</Button>
												</OverlayTrigger>
												))}
											  </span>
											  <Form.Control aria-labelledby="military" type="text" name="military_affiliation" id="military_affiliation" onChange={this.handleChange} defaultValue={this.state.military_affiliation ? this.state.military_affiliation : this.state.profileData.military_affiliation} />
											</Form.Group>
										  </Col>
										</Row>
										<Row>  
										  <Col md={6}>
											<Form.Group className="race-panel">
											  <Form.Label>Current Major</Form.Label>&nbsp;
											  <span>
												{['right'].map(placement => (
												<OverlayTrigger
												  key={placement}
												  placement={placement}
												  overlay={
												  <Tooltip id={`tooltip-${placement}`}>
													Eligibility for many scholarships in our database is based upon the applicant's major / field of study. Please supply your major / field of study for accurate results.
												  </Tooltip>
												  }
												>
												  <Button variant="secondary" className="info-btn">?</Button>
												</OverlayTrigger>
												))}
											  </span>	
											  <br />	
											  <div className="checklist">
											  { this.state.user_majors.length > 0 ?
												createMajorElms
												:
												<span>
													<a className="btn" style={{"borderColor": "#010167"}}  onClick={this.openMajorModal}>Select</a>
													<span id="errorMajor" style={{color: 'red'}}></span>
												</span>
											  }									  
											  </div>
											</Form.Group>
										  </Col>
										  <Col md={6}>                                    
											<Form.Group >
											  <Form.Label id='transfer' for='transfer student option'>Transfer Student?</Form.Label>
											  <Form.Control  aria-labelledby="transfer" as="select" name="transfer_student" id="transfer_student" onChange={this.handleChange}>
												<option value="">----Select----</option>
												<option value="Yes" selected={this.state.profileData.transfer_student=='Yes'}>Yes</option>
												<option value="No" selected={this.state.profileData.transfer_student=='No'}>No</option>
											  </Form.Control>
											</Form.Group>
										  </Col>
										</Row>
										<Row>
											<Col md={6}>
											  <Form.Group >
												<Form.Label id='addtendinfInfo'>Additional Information</Form.Label>&nbsp;
												<span>
												  {['right'].map(placement => (
												  <OverlayTrigger
												 
													key={placement}
													placement={placement}
													overlay={
													<Tooltip id={`tooltip-${placement}`}>
													  Is there anything else you’d like for us to know as we search for your scholarships? 
													</Tooltip>
													}
												  >
													<Button variant="secondary" className="info-btn">?</Button>
												  </OverlayTrigger>
												  ))}
												</span>
												<Form.Control  aria-labelledby="addtendinfInfo" type="text" name="custom_word_search" id="custom_word_search" defaultValue={this.state.custom_word_search ? this.state.custom_word_search : this.state.profileData.custom_word_search } onChange={this.handleChange}/>
											  </Form.Group>
											</Col>
										</Row>
									</div>
								  }                                
								<div class="form-group" style={{'text-align': 'center'}}>
								  <Button variant="primary" type="submit" id="submit" >{this.state.submitBtnText}</Button>
								</div>
								
								{ /* Race Popup */ }
								<Modal open={racePopup} onClose={this.closeRaceModal} center>
								  <div className="modal-box select-box">
									<h2>Select all that apply</h2>
									  <div className="popup-body">              
										<Form>
										  {createPopupRaceList}
										  <br />										  
										  <Button variant="default" type="button" onClick={this.closeRaceModal}>Done</Button>
									  </Form>   
									</div>
								  </div>
								</Modal>
								
								{ /* Major Popup */ }
								<Modal open={majorPopup} onClose={this.closeMajorModal} center>
								  <div className="modal-box select-box">
									<h2>Select all that apply</h2>
									  <div className="popup-body">              
										<Form>
											{createPopupMajorList}
											<br />										  
											<Button variant="default" type="button" onClick={this.closeMajorModal}>Done</Button>
									  </Form>   
									</div>
								  </div>
								</Modal>
							</Form>
						  </Col>
						</Row>   
					  </div>
				  </div>
			  </Col>
			</Row>
		  </Container>
		  </main>
		  <InnerFooter/>
		</div>
	);
  }
}
export default UserProfile;