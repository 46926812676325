import React from 'react';
import {Switch, Router, Route} from 'react-router-dom';
import './App.css';
import createBrowserHistory from 'history/createBrowserHistory';
import config from 'react-global-configuration';
import home from './components/Home';
import login from './components/Login';
import edituser from './components/EditUser';
import logout from './components/Logout';
import MatchedScholarships from './components/MatchedScholarships';
import SavedScholarships from './components/SavedScholarships';
import Payment from './components/payment';
import Personalinfo from './components/Personalinfo';
import Success from './components/success';
import UserDashboard from './components/dashboard/UserDashboard';
import UserAppliedScholarships from './components/dashboard/UserAppliedScholarships';
import UserProfile from './components/dashboard/UserProfile';
import Forget from './components/forget';
import AccountRecovery from './components/accountrecovery';
import GroupBilldesk from './components/GroupBilldesk';
// import groupRegistration from './components/groupRegistration';
import Pricing from './components/Pricing';
import SchoolingStandard from './components/SchoolingStandard';
import Termandconditions from './components/Termandconditions';
import Membership from './components/dashboard/Membership';
import Feedback from './components/Feedback';
import 'bootstrap/dist/css/bootstrap.css';
import Interships from './components/Interships';


config.set({
	baseUrl: 'http://3.83.204.152:81/api',
    baseFrontUrl:'http://3.83.204.152/',
    imgUrl: 'http://3.83.204.152:81/'
  });
/*if (window.location.hostname === 'localhost') 
{
  config.set({       
          //baseUrl:'http://localhost/imagine/scholarship_backend_new/api',
          //baseFrontUrl:'http://localhost:3000',
          //imgUrl: 'http://localhost/imagine/scholarship_backend_new/'
		  baseUrl:'http://localhost/baylor_api/api',
          baseFrontUrl:'http://localhost:3000',
          imgUrl: 'http://localhost/baylor_api/'
  });
}
else if(window.location.hostname === 'http://3.83.204.152/')
{
 config.set({
	baseUrl: 'http://3.83.204.152:81/api',
    baseFrontUrl:'http://3.83.204.152/',
    imgUrl: 'http://3.83.204.152:81/'
  });
}else{
	config.set({
	baseUrl: 'https://api.baylorscholarships.com:8443/api',
    baseFrontUrl:'https://baylor.imaginescholarships.com/',
    imgUrl: 'https://api.baylorscholarships.com:8443/'
  });
}*/

const history = createBrowserHistory();

const App = () =>
<Router history={history}>
  <div>
	  <Switch>
		  <Route exact path="/" component={home}/>
		  <Route exact path="/login" component={login}/>
		  <Route exact path="/login/:id" component={login}/>
		  <Route exact path="/payment" component={Payment}/>
		  <Route exact path="/success/:name/:type" component={Success}/>
		  <Route exact path="/editprofile/" component={edituser}/>
		  <Route exact path="/logout" component={logout}/>
		  <Route exact path="/matched-scholarships" component={MatchedScholarships}/>
		  <Route exact path="/saved-scholarships" component={SavedScholarships}/>
		  <Route exact path="/dashboard" component={UserDashboard}/>
		  <Route exact path="/applied-scholarships" component={UserAppliedScholarships}/>  
		  <Route exact path="/profile" component={UserProfile}/>
		  <Route exact path="/forget-password" component={Forget}/>
		  <Route exact path="/accountrecovery/:id" component={AccountRecovery}/>  
		  <Route exact path="/group-activation-billdesk/:id" component={GroupBilldesk}/>  
		  <Route exact path="/pricing" component={Pricing}/>
		  <Route exact path="/scholarships" component={SchoolingStandard}/>
		  <Route exact path="/personalinfo" component={Personalinfo}/> 
		  <Route exact path="/terms-and-conditions" component={Termandconditions}/>  
		  <Route exact path="/membership" component={Membership}/>
		  <Route exact path="/student-plan-feedback/:id" component={Feedback}/> 
		  <Route exact path="/interships" component={Interships}/>

	  </Switch>
  </div>
</Router>

export default App;
