/* _____________________________________________________________
  |                                                             |
  |                   Payment Activity                          |
  |   Stripe paymet gateway related input and it's execution.   |
  |                                                             |
  |_____________________________________________________________|
                                                                */
// Basic
import React, { Component } from 'react';
import { Redirect, browserHistory,router } from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';
import {PageLoader,SmallPageLoader} from './loaders/PageLoader'; // Page Loader

// Include Component
import InnerHeader from './layout/InnerHeader';
import InnerFooter from './layout/InnerFooter';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Tab, Tabs, Button, InputGroup, FormControl, Form, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import WOW from "wowjs";

// Service lib
import {postApiData,getApiData} from '../services/Api';

const history = createBrowserHistory();

class Payment extends Component {
  
  constructor(props){

    super(props);
    console.log(this.props)
    this.state={
      validated: false,
      isPayment:false,
      isPaymentError:false,
      membershipPlans: [],
      plan: "",
      plan_id: "",
      // local Storage Var
      isLogin: localStorage.getItem('userId'),
      isPaid: localStorage.getItem('paid'),
      redirectUri: localStorage.getItem('redirectUri'),
      group_id : localStorage.getItem('group_id'),
      submitText : 'Pay',
      // redirect Auth
      authRedirect : this.props.match.path
    }
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 
  /**
   * On Click validate login.
   *
   * @return status
  */
  handleSubmit(event) {

    event.preventDefault();

    // Validation check
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    // Set Var
    var self = this;
    var submitElm = document.getElementById('submit');
    submitElm.disabled = true;
    this.setState({ submitText: 'Processing...' });
    this.setState({ validated: true });
    
    const data = new FormData(event.target); // Form Data
    data.append('user_id',this.state.isLogin);
    
    if(this.state.plan){
      data.append('plan_id', this.state.plan.split('~=>')[0]);
    }

    // Set post data
    let where =[{url:"/addmoney/student-activation-payment"}]; // Service param

    // Post parameters on service URL...
    postApiData(where,data,true).then(res => {
      console.log(res);
      submitElm.disabled = false;
      self.setState({ submitText: 'Pay' });

      if(res.data.status == '200'){
       reactLocalStorage.set('paid', "Y");
       self.setState({ isPayment : true });
       setTimeout(
        function(){
          // Redirect URI
          if(self.state.redirectUri){
            reactLocalStorage.set('redirectUri', "");
            window.location.href = self.state.redirectUri;
          }else{
            window.location.href = "/matched-scholarships";
          }
        },
        5000);
      }else{
        self.setState({ isPaymentError : res.data.result});
      }
    })
    
    .catch(function (error) {
      console.log(error);
      submitElm.disabled = false;
      self.setState({ submitText: 'Pay' });
    });
  }

  /**
   * On Load Initialize.
   * 
  */
  componentDidMount(){
    new WOW.WOW().init();
    window.scrollTo(0, 0);

    
    // Set Var
    var self = this;
    // Set post data
    let where =[{url:"/student-membership-plans/"+this.state.group_id}]; // Service param

    // Post parameters on service URL...
    getApiData(where).then(response => {
		if(response.status==200 && response.data.success==true)
		{
			self.setState({ membershipPlans : response.data.plans});
		}		
		this.setState({ plan: localStorage.getItem('plan')});
    })
    .catch(function (error) {
      this.setState({ plan: localStorage.getItem('plan')});
    });
  }

  /* Handle Blank Spaces */
  handleChange = event => {
    let eleVal = event.target.value.trim();
    this.setState({[event.target.id]: eleVal});
  }

  /* Handle Blank Spaces */
  handlePlanChange = event => {
    let eleVal = event.target.id.trim();
    reactLocalStorage.set('plan', eleVal);
    this.setState({ plan: eleVal});
  }

  /* Reset Plan */
  resetPlan = event =>{
    reactLocalStorage.set('plan', '');
    this.setState({plan: ''});
    window.scrollTo(0, 0);
  }
  
  async openNav() {
  document.getElementById("myNav").style.width = "300px";
  }

  async  closeNav() {
  document.getElementById("myNav").style.width = "0%";
  }
  
  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {
	
    // Redirection
    if (this.state.redirect) {
      return <Redirect to='/scholarships-listing'/>;
    }
      
    // Auth redirection
    if(!this.state.isLogin){
      return(<Redirect to='/login'  />); 
    }

    var term = "";
    // Set Number of Students Drop Down
    let membershipPlansSet = this.state.membershipPlans.map((item,i) => {
      term = (item.term=='m')?' / month ':' / year ';	  
      return 	<Col lg='4'>
                  <Card>
                  <Card.Body>
                    <Card.Title className="text-muted text-uppercase text-center">{item.membership_name}</Card.Title>
                    <h6 className="card-price text-center">${item.membership_cost.toFixed(2)}<span class="period">{term}</span></h6>
                    <hr/>
                    {(item.membership_desc)?
                      <ul className="fa-ul">
                      <li>{item.membership_desc}</li>
                      </ul>:""
                    }
                    <a href="javascript:void(0)" className="btn btn-block btn-primary text-uppercase"  id={item.id + '~=>' + item.membership_cost.toFixed(2) + term} onClick={this.handlePlanChange}>Continue</a>
                  </Card.Body>
                  </Card>
                </Col>; 
    });

    const handleHide = () => this.setState({ show: false });
    const handleShow = () => this.setState({ show: true });
    const { validated } = this.state;
    return (
   
      <div style={{background:'url(/login-bg.png) no-repeat', backgroundSize:'cover', paddingBottom:'1px'}}>
        <InnerHeader/>
        
          { (this.state.plan && this.state.isPaid=='N') ?
            <div style={{minHeight:'500px'}}>
                <div className="login-section" >
                {(!this.state.isPayment)?
                <Tabs>
                    <Tab eventKey="login" title="Payment">
                      <Form class="form-horizontal" id="payment-form" method="post" validated={validated} onSubmit={(event) => this.handleSubmit(event)}>
                        <Form.Group controlId="card" className="card">
                          <Form.Label>Card Number<sup>*</sup></Form.Label>       
                          <Form.Control type="text" placeholder="Card number" id="card_no" name="card_no" autocomplete="off" maxlength="16" required onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId="cvv" className="cvv">
                          <Form.Label>CVV<sup>*</sup></Form.Label>       
                          <Form.Control type="text" placeholder='ex. 311' id="cvvNumber" name="cvvNumber" autocomplete="off" maxlength="4" required onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId="common" className="expiration">
                          <Form.Label>Expiration<sup>*</sup></Form.Label> 
                          <div className="row clearfix">
                          <div className="col-sm-6">      
                            <Form.Control type="text" placeholder='MM' id="ccExpiryMonth" name="ccExpiryMonth" autocomplete="off" maxlength="2" required onChange={this.handleChange} />
                          </div>
                          <div className="col-sm-6"> 
                            <Form.Control type="text" placeholder='YYYY' id="ccExpiryYear" name="ccExpiryYear" autocomplete="off" maxlength="4" required onChange={this.handleChange} />
                          </div>
                          </div>
                        </Form.Group>
                        <Form.Group controlId="cvv" className="cvv">
                          <Form.Label>Amount ($)</Form.Label>       
                          <Form.Control type="text" id="amount" name="amount" autocomplete="off" value={(this.state.plan)?this.state.plan.split('~=>')[1]:0} readonly required onChange={this.handleChange} />
                          <div style={{'textAlign':'right'}}>
                            <a href="javascript:void(0)" onClick={() => this.resetPlan()} style={{'color':'#928383'}}>Change Plan</a>
                          </div>
                        </Form.Group>
                        
                        <div class='form-row'>
                          <div class='col-md-12 form-group'>
                          <button class='form-control btn btn-primary submit-button' type='submit' id="submit" >{this.state.submitText} »</button>
                          </div>
                        </div>
                        <div class='form-row'>
                          {this.state.isPaymentError?(<div className="alert alert-danger"><strong>{this.state.isPaymentError}</strong></div>):(null)}
                          
                        </div>
                      </Form>
                    </Tab>
                  </Tabs>:<div style={{'margin': '0 auto','fontVariant': 'revert','color': 'green','margin':'44px 10px','fontSize':'15px'}}>Successful, You will be redirect in 5 seconds or <Link to='/matched-scholarships'>
                  Click Here</Link> to go to scholarship listing page.</div>}
                </div>
            </div>
          :
            <section class="pricing py-5">
              <h2 style={{'color': '#fff','textAlign': 'center','padding': '12px'}} >Select Membership Plan</h2>
              <Container>
                <Row>
                <Col lg='4'>
                  <Card className="Freewidget">
                  <Card.Body>
                    <Card.Title className="text-muted text-uppercase text-center">Basic</Card.Title>
                    <h6 className="card-price text-center">Free</h6>
                    <hr/>
                    <ul className="fa-ul">
                      <li>Scholarship-matching not included</li>
                    </ul>
                    <a href="javascript:void(0)" className="btn btn-block btn-secondary text-uppercase btn-mute disabled">Current Plan</a>
                  </Card.Body>
                  </Card>
                </Col> 
                {membershipPlansSet}
                </Row>
              </Container>
            </section>
          }
        <InnerFooter/>
      </div>
  
    );
  }
}

const style = {
 margin: 15,
};

export default Payment;