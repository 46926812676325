/* _________________________________
  |                                 |
  |      Dashboard Nav Element      |
  |_________________________________|
                                       */
// Initial Lib
import React, { Component } from 'react';

import {reactLocalStorage} from 'reactjs-localstorage';
import createBrowserHistory from 'history/createBrowserHistory';

// Design Lib
import 'bootstrap/dist/css/bootstrap.css';
import {Container, Row, Col, Navbar, Nav, Button, InputGroup, FormControl, Form, Card} from "react-bootstrap";
import { Redirect, browserHistory,router, Link } from 'react-router-dom';
import WOW from "wowjs";

// Service lib
import {postApiData,getApiData} from '../../services/Api';

// default Base Urls
import config from 'react-global-configuration';

const history = createBrowserHistory();

class DashSideNav extends Component {
    
  constructor(props){
    super(props);
    this.state={
      currentPath : this.props.path
    }
  }
  
  /**
   * Display login UI.
   *
   * @return Response
  */
  render() {
    return (
      <div style={{paddingTop:'50px'}}>
        <div className="sideNav">
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav2" />
            <Navbar.Collapse id="basic-navbar-nav2">
              <Nav defaultActiveKey="/home" className="flex-column">
				<Nav.Link href="/profile" className={(this.state.currentPath=='/profile')?'active':''}><img alt="Click For Update Profile" src="/update.png"/>Update Profile</Nav.Link>
                <Nav.Link href="/applied-scholarships" className={(this.state.currentPath=='/applied-scholarships')?'active':''}><img alt="Applied Scholarships" src="/plan.png"/>Applied For Scholarships</Nav.Link>
				{/* <Nav.Link href="/membership" className={(this.state.currentPath=='/membership')?'active':''}><img src="/plan.png"/>Manage Subscription</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <p style={{padding:'10%  '}}>
           <img src="/info.png" alt="information" />
           <span style={{paddingLeft:'5PX'}}></span>This section is to keep track of the scholarships you've applied for. In order to officially apply, you need to navigate to the page of the scholarship and submit any needed documents and requirements. Don't forget to document if you received the scholarship or not. You can do this by clicking on "have you applied ?" on each scholarship.
           </p>
        </div>
      </div>
    );
  }
}

export default DashSideNav;