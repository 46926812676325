import Loader from 'react-loader-spinner';
import React from 'react';

export  class PageLoader extends React.Component {
 //other logic
   render() {
	return(
	<div>	<div className="loaderwrap"></div>
	<div className="loader" style={{textAlign:'center'}}>
	<p>Searching scholarships based on your profile.
	This might take some time, please be patient.</p>
	 <Loader 
		type="Oval"
		color="#00BFFF"
		height="50"	
		width="50"
	 /> 
	 </div>  </div>
	);
   }
}

export class SmallPageLoader extends React.Component {
	//other logic
	  render() {
	  return(
	  <div>	<div className="loaderwrap"></div>
	  <div className="loader">
		<Loader 
		  type="Oval"
		  color="#00BFFF"
		  height="39"	
		  width="50"
		/> 
		</div>  </div>
	  );
	  }
  }